import { useEffect, useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { motion } from "framer-motion"
import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import axios from 'axios'
import moment from "moment/moment"
import Filter from "pages/supplier/filter"
import nProgress from "nprogress"
import { Pagination } from "components"
import { lang } from "config"

const Supplier = ({ title }) => {
    const [suppliers, setSuppliers] = useState({ data: [] })
    const [searchParams, setSearchParams] = useSearchParams()

    const [trigger, setTrigger] = useState(false)

    const navigate = useNavigate()

    const page = searchParams.get('page')
    const search = searchParams.get('search')
    const name = searchParams.get('name')
    const from = searchParams.get('from')
    const to = searchParams.get('to')

    useEffect(() => {
        if (search === "") {
            navigate(``, { replace: true })
        }

        nProgress.start()

        const fetch = async () => {
            await axios.get(`${process.env.REACT_APP_BACKEND_URL}/supplier`, {
                params: {
                    page,
                    search,
                    name,
                    from,
                    to
                }
            }).then((res) => {
                setSuppliers(res.data)
            }).then(() => {
                nProgress.done()
            })
        }
        fetch()
    }, [searchParams, trigger])

    const handleSuccess = () => {
        setTrigger(!trigger)
    }

    const updateFilter = (value) => {
        setSearchParams(value)
    }

    const resetFilter = () => {
        setSearchParams({})
    }

    return (
        <AppLayout title={title}>
            <div className="p-4 space-y-16 lg:p-8">
                <div>
                    <button className="cursor-default">
                        <motion.h1 layout transition={{ duration: .5, type: "tween" }} layoutId={title} className="text-3xl font-medium">
                            {title}
                        </motion.h1>
                    </button>
                </div>
                <Transition>
                    <div className="space-y-6">
                        <div className="flex items-center justify-between text-xs">
                            <div className="flex items-center sm:space-x-2">
                                <div className="relative hidden sm:block">
                                    <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <circle cx={10} cy={10} r={7}></circle>
                                            <line x1={21} y1={21} x2={15} y2={15}></line>
                                        </svg>
                                    </div>
                                    <input onChange={(e) => setSearchParams({ search: e.target.value })} value={searchParams.get('search')} type="text" placeholder="Search supplier" autoComplete="off" className="w-64 py-3 pl-8 pr-4 text-xs transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200" />
                                </div>
                                <Filter onSubmit={updateFilter} onReset={resetFilter} data={Object.fromEntries(Object.entries({ name, from, to }).filter(([_, v]) => v != null))} />
                            </div>
                            {/* <div>
                                <Link to="create" state={{ back: title, transition: 'slide' }} className="inline-flex items-center px-4 py-3 space-x-2 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <line x1={12} y1={5} x2={12} y2={19}></line>
                                        <line x1={5} y1={12} x2={19} y2={12}></line>
                                    </svg>
                                    <span>Create New</span>
                                </Link>
                            </div> */}
                        </div>
                        <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                            <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                <thead className="bg-neutral-50 rounded-t-3xl">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.supplier}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.address}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.phone}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.created_at}</th>
                                        <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-neutral-200">
                                    {/* When there are no list available */}
                                    {suppliers.data.length === 0 && (
                                        <tr className="text-center">
                                            <td colSpan="6" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.no_data}
                                            </td>
                                        </tr>
                                    )}

                                    {/* When there are no list available on searching */}
                                    {/* <tr className="text-center">
                                        <td colSpan="6" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                            {lang.no_result}
                                        </td>
                                    </tr> */}
                                    {suppliers.data.length > 0 && suppliers.data.map(row => (
                                        <tr key={row.id}>
                                            <td className="px-6 py-4 text-xs font-medium text-neutral-500 whitespace-nowrap">
                                                <div class="flex items-center">
                                                    <img class="object-cover w-10 h-10 rounded-full" src={`https://ui-avatars.com/api/?name=${row.name}`} alt={`${row.name} Profile Photo`} />
                                                    <div class="ml-4">
                                                        <div className="flex flex-col">
                                                            <span className="font-medium text-neutral-900">{row.name}</span>
                                                            <span>{row.supplier_code}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {row.address ?? '-'}
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {row.phone ?? '-'}
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {moment(row.created_at).format('MMMM D, YYYY')}
                                            </td>
                                            <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                <div class="inline-flex items-center space-x-2">
                                                    {/* <Link to={`/supplier/${row.id}`} state={{ back: title, transition: 'slide' }} class="inline-flex items-center p-1 text-white transition bg-neutral-800 rounded-full active:hover:scale-90">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <circle cx={12} cy={12} r={2}></circle>
                                                            <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                                                        </svg>
                                                    </Link> */}

                                                    {/* <Link to={`/supplier/${row.id}/edit`} state={{ back: title, transition: 'slide' }} class="inline-flex items-center p-1 text-white transition bg-neutral-800 rounded-full active:hover:scale-90">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                                                            <line x1="13.5" y1="6.5" x2="17.5" y2="10.5"></line>
                                                        </svg>
                                                    </Link>

                                                    <Delete data={row} onSuccess={handleSuccess} /> */}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination links={suppliers?.links} from={suppliers?.from} to={suppliers?.to} total={suppliers?.total} />
                    </div>
                </Transition>
            </div>
        </AppLayout>
    )
}

export default Supplier