import Checkbox from "components/forms/checkbox"
import ErrorMessage from "components/forms/error-message"
import Input from "components/forms/input"
import InputAmount from "components/forms/input-amount"
import InputDate from "components/forms/input-date"
import Label from "components/forms/label"
import Radio from "components/forms/radio"
import { lang } from "config"
import { useAuth } from "contexts/auth-context"
import moment from "moment"
import { useEffect, useState } from "react"
import useCurrencies from "repositories/currency"
import useCustomers from "repositories/customer"
import useSuppliers from "repositories/supplier"
import useTaxes from "repositories/tax"
import BillList from "./bill-list"
import SelectBill from "./select-bill"
import { Link } from "react-router-dom"
import SupplierIcon from "components/icons/supplier"
import { UsersIcon, XIcon } from "components/icons"
import _ from "lodash"

const Form = ({ data, errors, onSubmit }) => {
    const { currentBranch } = useAuth()

    const { data: supplierSelection, isLoadingSupplier } = useSuppliers({
        paginate: false
    })
    const { data: customerSelection, isLoadingCustomer } = useCustomers({
        paginate: false
    })
    const { data: pphSelection, isLoadingPph } = useTaxes({
        type: "PPH"
    })
    const { data: currencySelection, isLoadingCurrency } = useCurrencies({})

    // Hidden
    const [branch, setBranch] = useState(data?.branch_id)
    const [transactionType, setTransactionType] = useState(data?.transaction_type)
    const [type, setType] = useState(data?.type.code)
    const [bill, setBill] = useState(data?.details ? _.map(data?.details, 'bill') : [])
    const [date, setDate] = useState(data?.invoice_date ?? moment().format('YYYY-MM-DD HH:mm:ss'))
    const [dueDate, setDueDate] = useState(data?.due_date ?? moment().add(1, 'month').format('YYYY-MM-DD HH:mm:ss'))
    const [customer, setCustomer] = useState(data?.payor_or_payee_code)
    const [supplier, setSupplier] = useState(data?.payor_or_payee_code)
    const [currency, setCurrency] = useState(data?.currency.toUpperCase() ?? "IDR")
    const [rate, setRate] = useState(data?.rate ?? 1)
    const [note, setNote] = useState(data?.note)

    const [dpp, setDpp] = useState(data?.dpp)
    const [discount, setDiscount] = useState(data?.discount)
    const [rebate, setRebate] = useState(data?.rebate ?? 0)
    const [advancePayment, setAdvancePayment] = useState(data?.advance_payment)
    const [ppn, setPpn] = useState(data?.ppn ?? 0)
    const [ppnPercentage, setPpnPercentage] = useState(data?.ppn_percentage ?? 0)
    const [pph, setPph] = useState(data?.pph ?? null)
    const [pphPercentage, setPphPercentage] = useState(data?.pph_percentage ?? null)
    const [taxInvoiceNumber, setTaxInvoiceNumber] = useState(data?.tax_invoice_number)

    const [invoiceDetail, setInvoiceDetail] = useState(undefined)

    const [grandTotal, setGrandTotal] = useState(data?.grand_total ?? null)

    // Input state
    const [showPphInput, setShowPphInput] = useState(data?.pph_percentage ? (data?.pph_percentage !== 0 ? true : false) : false)

    useEffect(() => {
        if (!data) {
            var invoiceDetail = []
            var dpp = 0
            var discount = 0
            var ppn = 0
            var pph = 0
            var ppnPercentage = 0

            var advancePayment = 0
            var grandTotal = 0

            bill.forEach(row => {
                invoiceDetail.push({ bill_number: row.bill_number, price: row.dpp, discount: row.discount, ppn: row.ppn, subtotal: row.grand_total })

                if (row.transaction_type === 0) {
                    dpp += parseFloat(row.dpp)
                } else {
                    dpp -= parseFloat(row.dpp)
                }

                if (row.discount) {
                    if (row.transaction_type === 0) {
                        discount += parseFloat(row.discount)
                    } else {
                        discount -= parseFloat(row.discount)
                    }
                }

                if (row.ppn) {
                    if (row.transaction_type === 0) {
                        ppn += parseFloat(row.ppn)
                        // ppnPercentage += parseFloat(row.ppn_percentage)
                    } else {
                        ppn -= parseFloat(row.ppn)
                        // ppnPercentage -= parseFloat(row.ppn_percentage)
                    }
                }

                if (row.transaction_type === 0) {
                    advancePayment += parseFloat(row.advance_payment)
                } else {
                    advancePayment -= parseFloat(row.advance_payment)
                }

                if (row.transaction_type === 0) {
                    grandTotal += parseFloat(row.grand_total)
                } else {
                    grandTotal -= parseFloat(row.grand_total)
                }
            })

            pph = parseFloat(dpp * pphPercentage) / 100

            // setPpn((dpp * ppnPercentage) / 100)

            // if (ppn >= 0) {
            //     grandTotal += parseFloat((dpp * ppnPercentage) / 100)
            // }

            if (pph !== 0) {
                grandTotal += parseFloat(pph)
            }

            if (rebate >= 0) {
                if (grandTotal < 0) {
                    grandTotal += parseFloat(rebate)
                } else {
                    grandTotal -= parseFloat(rebate)
                }
            }

            if (bill.find((row) => row.type.code === 'po') && bill.find((row) => row.type.code === 'rt-po')) {
                setType('po')
            } else if (bill.find((row) => row.type.code === 'so') && bill.find((row) => row.type.code === 'rt-so')) {
                setType('so')
            } else {
                setType(bill[0]?.type.code)
            }

            setBranch(currentBranch?.id ?? 1)

            if (grandTotal < 0) {
                setTransactionType(1)
            } else {
                setTransactionType(0)
            }

            setInvoiceDetail(invoiceDetail)
            setDpp(Math.abs(dpp))
            setDiscount(Math.abs(discount))

            if (bill[0]?.ppn_percentage) {
                setPpn(Math.abs(ppn))
                setPpnPercentage(bill[0]?.ppn_percentage)
            }

            setPph(Math.abs(pph))
            setAdvancePayment(Math.abs(advancePayment))
            setGrandTotal(Math.abs(grandTotal))

            if (bill.find((row) => row.type.code === 'po') || bill.find((row) => row.type.code === 'rt-po')) {
                setSupplier(bill[0]?.payor_or_payee_code)
            } else {
                setCustomer(bill[0]?.payor_or_payee_code)
            }

            if (bill.length !== 0) {
                setCurrency(bill[0]?.currency.toUpperCase())
                setRate(bill[0]?.rate)
            }
        }
    }, [bill, rebate, ppnPercentage, pphPercentage])

    const togglePphInput = () => {
        setShowPphInput(!showPphInput)
        setPphPercentage(null)
    }

    const handleSelect = (value) => {
        setBill(value)
    }

    const deselectCurrent = (value) => {
        setBill(bill.filter((row) => row.bill_number !== value.bill_number))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        onSubmit({
            type,
            payor_or_payee_code: (type === 'po' || type === 'rt-po') ? supplier : customer,
            currency,
            rate,
            dpp,
            ppn,
            ppn_percentage: ppnPercentage,
            pph: showPphInput ? pph : null,
            pph_percentage: showPphInput ? pphPercentage : null,
            tax_invoice_number: taxInvoiceNumber,
            rebate,
            discount,
            advance_payment: advancePayment,
            grand_total: grandTotal,
            note,
            invoice_date: date,
            due_date: dueDate,
            invoice_detail: invoiceDetail,
            transaction_type: transactionType,
            branch_id: branch
        })
    }

    return (
        <div className="space-y-16">
            <div className="space-y-4">
                <div className={`${errors.transaction_type ? 'border-red-200' : 'border-neutral-200'} overflow-x-auto border rounded-xl`}>
                    <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                        <thead className="border-b bg-neutral-50 rounded-t-3xl border-neutral-200">
                            <tr>
                                <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">No. Bill</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.grand_total}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.branch}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.customer_or_supplier}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.type}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_type}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.bill_date}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.customer_or_supplier_code}</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-neutral-200">
                            {/* When there are no list available */}
                            {bill.length === 0 && (
                                <tr className="text-center">
                                    <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {lang.no_bill_selected}
                                    </td>
                                </tr>
                            )}

                            {/* When there are no list available on searching */}
                            {/* <tr className="text-center">
                                        <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                            {lang.no_result}
                                        </td>
                                    </tr> */}
                            {bill.length > 0 && bill.map(row => (
                                <tr key={row.bill_number}>
                                    <td className="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                        <div className="inline-flex items-center space-x-2">
                                            {/* <Link onClick={() => setTransition('slide')} to={`/invoice/${row.invoice_number}`} state={{ back: "Pay", from: location.pathname, transition: 'slide' }} className="inline-flex items-center p-1 text-white transition rounded-full bg-neutral-800 active:hover:scale-90">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <circle cx={12} cy={12} r={2}></circle>
                                                    <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                                                </svg>
                                            </Link> */}

                                            <button onClick={() => deselectCurrent(row)} class="inline-flex items-center p-1 text-white transition bg-red-800 rounded-full active:hover:scale-90">
                                                <XIcon className={`w-6 h-6`} />
                                            </button>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                        {row.bill_number}
                                    </td>
                                    <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                        {row.currency && Intl.NumberFormat('id-Id', { style: 'currency', currency: row.currency }).format(row.grand_total)}
                                    </td>
                                    <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                        {row.branch?.name}
                                    </td>
                                    <td class="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                        <div className="flex items-center space-x-2">
                                            <span>
                                                {(row.type?.code === 'po' || row.type?.code === 'rt-po' || row.type?.code === 'rt-adv') ? (
                                                    <SupplierIcon className="w-4 h-4" stroke={1.5} />
                                                ) : (
                                                    <UsersIcon className="w-4 h-4" stroke={1.5} />
                                                )}
                                            </span>
                                            <span>
                                                {(row.type?.code === 'po' || row.type?.code === 'rt-po' || row.type?.code === 'rt-adv') ? row.supplier?.name : row.customer?.name}
                                            </span>
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {row.type?.name}
                                    </td>
                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {row.transaction_type === 0 ? lang.income : lang.expense}
                                    </td>
                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {moment(row.invoice_date).format('MMMM D, YYYY')}
                                    </td>
                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {row.payor_or_payee_code}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div>
                    <SelectBill selected={bill} onSelect={handleSelect} error={errors.invoice_detail} />
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="mt-10 sm:mt-0">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                                <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                            </div>
                        </div>
                        <div className="mt-5 md:col-span-2 md:mt-0">
                            <div className="overflow-hidden border sm:rounded-xl">
                                <div className="px-4 py-5 bg-white sm:p-6">
                                    <div className="grid grid-cols-6 gap-4">
                                        {/* <div className="col-span-6">
                                            <label for="bill" className="block text-xs text-neutral-700">{lang.bills}</label>
                                            {data ? (
                                                <BillList data={data.details} largeButton />
                                            ) : (
                                                <SelectBill selected={bill} onSelect={handleSelect} error={errors.invoice_detail} />
                                            )}
                                            <ErrorMessage error={errors.invoice_detail} />
                                        </div> */}

                                        <div className="col-span-6 sm:col-span-3">
                                            <label for="date" className="block text-xs text-neutral-700">{lang.invoice_date}</label>
                                            <InputDate dateFormat="dd/MM/yyyy, HH:mm" showTimeSelect id="date" disabled selected={Date.parse(date)} value={Date.parse(date)} error={errors.invoice_date} />
                                            <ErrorMessage error={errors.invoice_date} />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label for="due_date" className="block text-xs text-neutral-700">{lang.due_date}</label>
                                            <InputDate dateFormat="dd/MM/yyyy, HH:mm" showTimeSelect id="due_date" onChange={(value) => setDueDate(moment(value).format('Y-MM-DDTHH:mm'))} minDate={Date.parse(date)} selected={Date.parse(dueDate)} value={Date.parse(dueDate)} error={errors.due_date} />
                                            <ErrorMessage error={errors.due_date} />
                                        </div>

                                        {(!type || (type === 'po' || type === 'rt-po')) && (
                                            <div className="col-span-6 lg:col-span-3">
                                                <label for="supplier" className="block text-xs text-neutral-700">{lang.supplier}</label>
                                                <select disabled={bill.length !== 0 || data} value={supplier} name="supplier" id="supplier" className={`${errors.payor_or_payee_code ? 'border-red-200' : 'border-neutral-200'} ${bill.length !== 0 || data ? 'bg-neutral-100 opacity-70' : ''} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                                    <option value={null} selected disabled>-- {lang.choose} {lang.supplier} --</option>
                                                    {supplierSelection?.length !== 0 && supplierSelection?.map(row => (
                                                        <option value={row.supplier_code}>{row.name}</option>
                                                    ))}
                                                </select>
                                                <ErrorMessage error={errors.payor_or_payee_code} />
                                            </div>
                                        )}

                                        {(!type || (type !== 'po' && type !== 'rt-po')) && (
                                            <div className="col-span-6 lg:col-span-3">
                                                <label for="customer" className="block text-xs text-neutral-700">{lang.customer}</label>
                                                <select disabled={bill.length !== 0 || data} value={customer} name="customer" id="customer" className={`${errors.payor_or_payee_code ? 'border-red-200' : 'border-neutral-200'} ${bill.length !== 0 || data ? 'bg-neutral-100 opacity-70' : ''} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                                    <option value={null} selected disabled>-- {lang.choose} {lang.customer} --</option>
                                                    {customerSelection?.length !== 0 && customerSelection?.map(row => (
                                                        <option value={row.customer_code}>{row.name}</option>
                                                    ))}
                                                </select>
                                                <ErrorMessage error={errors.payor_or_payee_code} />
                                            </div>
                                        )}

                                        {type && (
                                            <div className="col-span-3"></div>
                                        )}

                                        <div className="col-span-6 sm:col-span-3">
                                            <label for="currency" className="block text-xs text-neutral-700">{lang.currency}</label>
                                            <select disabled value={currency} name="currency" id="currency" className={`${errors.currency ? 'border-red-200' : 'border-neutral-200'} bg-neutral-100 opacity-70 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                                <option value={null} selected disabled>-- {lang.select} {lang.currency} --</option>
                                                {currencySelection?.length !== 0 && currencySelection?.map(row => (
                                                    <option value={row.code}>{row.code}</option>
                                                ))}
                                            </select>
                                            <ErrorMessage error={errors.currency} />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label for="rate" className="block text-xs text-neutral-700">{lang.exchange_rate}</label>
                                            <InputAmount disabled value={parseFloat(rate)} error={errors.rate} id="rate" />
                                            <ErrorMessage error={errors.rate} />
                                        </div>

                                        <div className="col-span-6">
                                            <label for="note" className="block text-xs text-neutral-700">{lang.note}</label>
                                            <textarea onChange={e => setNote(e.target.value)} value={note} name="note" id="note" rows="5" className={`${errors.note ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}></textarea>
                                            <ErrorMessage error={errors.note} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hidden sm:block" aria-hidden="true">
                    <div className="py-5">
                        <div className="border-t border-gray-200" />
                    </div>
                </div>

                <div className="mt-10 sm:mt-0">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.tax_and_amount}</h3>
                                <p className="mt-1 text-sm text-gray-600">{lang.more_detailed_form_about_tax_and_more}</p>
                            </div>
                        </div>
                        <div className="mt-5 md:col-span-2 md:mt-0">
                            <div className="overflow-hidden border sm:rounded-xl">
                                <div className="px-4 py-5 space-y-6 bg-white sm:p-6">
                                    <fieldset>
                                        <legend className="sr-only">Taxes</legend>
                                        <div className="text-base font-medium text-gray-900" aria-hidden="true">
                                            {lang.taxes}
                                        </div>
                                        <div className="mt-4 space-y-4">
                                            {type !== 'ppn' && type !== 'pph-23' && (
                                                <div className="flex items-start">
                                                    <div className="flex items-center h-5">
                                                        <Checkbox disabled={data} id="toggle_pph_input" checked={showPphInput} onChange={() => togglePphInput()} />
                                                    </div>
                                                    <div className="ml-3 text-sm">
                                                        <label htmlFor="toggle_pph_input" className="font-medium text-gray-700">
                                                            {lang.pph}
                                                        </label>
                                                        <p className="text-gray-500">{lang.check_if_theres_pph}</p>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="grid grid-cols-6 gap-4">
                                                {/* {showPphInput && (
                                                <div className="col-span-6 sm:col-span-3">
                                                    <Label htmlFor="pph_percentage" value="PPH (%)" />
                                                    <Input disabled={data} onChange={e => setPphPercentage(e.target.value)} value={pphPercentage} error={errors.pph_percentage} id="pph_percentage" type="number" />
                                                    <ErrorMessage error={errors.pph_percentage} />
                                                </div>
                                            )} */}
                                                {showPphInput && (
                                                    <div className="col-span-6">
                                                        <Radio disabled={data || !showPphInput} onChange={(value) => setPphPercentage(value)} value={pphPercentage} keyValue="percentage" title="label" selection={pphSelection} />
                                                    </div>
                                                )}
                                                <div className="col-span-6 sm:col-span-3">
                                                    <label for="tax_invoice_number" className="block text-xs text-neutral-700">{lang.tax_invoice_number}</label>
                                                    <Input onChange={e => setTaxInvoiceNumber(e.target.value)} value={taxInvoiceNumber} error={errors.tax_invoice_number} id="tax_invoice_number" />
                                                    <ErrorMessage error={errors.tax_invoice_number} />
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset className="space-y-4">
                                        <legend className="text-base font-medium text-gray-900 contents">{lang.amounts}</legend>
                                        {/* <div className="grid grid-cols-6 gap-4">
                                        <div className="col-span-6 sm:col-span-3">
                                            <Label htmlFor="rebate" value="Rebate" />
                                            <InputAmount disabled={data} min={0} onChange={(value) => setRebate(value)} value={parseFloat(rebate)} error={errors.rebate} id="rebate" />
                                            <ErrorMessage error={errors.rebate} />
                                        </div>
                                    </div> */}
                                        <div>
                                            <p className="text-sm text-gray-800">{lang.dpp}</p>
                                            <p className="text-xs text-gray-500">{currency && Intl.NumberFormat('id-Id', { style: 'currency', currency }).format(dpp)}</p>
                                        </div>
                                        {(discount !== 0 && discount !== null) && (
                                            <div>
                                                <p className="text-sm text-gray-800">{lang.discount}</p>
                                                <p className="text-xs text-gray-500">{currency && Intl.NumberFormat('id-Id', { style: 'currency', currency }).format(discount)}</p>
                                            </div>
                                        )}
                                        {/* <div>
                                        <p className="text-sm text-gray-800">Rebate</p>
                                        <p className="text-xs text-gray-500">{currency && Intl.NumberFormat('id-Id', { style: 'currency', currency }).format(rebate)}</p>
                                    </div> */}
                                        {(ppn !== 0 && ppn !== null) && (
                                            <div>
                                                <p className="text-sm text-gray-800">{lang.ppn} ({ppnPercentage}%)</p>
                                                <p className="text-xs text-gray-500">{currency && Intl.NumberFormat('id-Id', { style: 'currency', currency }).format(ppn)}</p>
                                            </div>
                                        )}
                                        {pphPercentage > 0 && (
                                            <div>
                                                <p className="text-sm text-gray-800">PPH ({pphPercentage}%)</p>
                                                <p className="text-xs text-gray-500">{currency && Intl.NumberFormat('id-Id', { style: 'currency', currency }).format(pph)}</p>
                                            </div>
                                        )}
                                        {advancePayment > 0 && (
                                            <div>
                                                <div className="text-sm text-gray-800">{lang.advance_payment}</div>
                                                <div className="text-xs text-gray-500">{currency && Intl.NumberFormat('id-Id', { style: 'currency', currency }).format(advancePayment)}</div>
                                            </div>
                                        )}
                                        <div>
                                            <p className="text-sm text-gray-800">{lang.grand_total}</p>
                                            <p className="text-xs text-gray-500">{currency && Intl.NumberFormat('id-Id', { style: 'currency', currency }).format(grandTotal)}</p>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-8 text-xs text-right">
                    <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                        <span>{data ? lang.update : lang.create}</span>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default Form