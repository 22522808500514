import axios from "axios"
import useSWR from "swr"

const fetcher = async (url, params) => {
    return await axios.get(url, { params }).then(res => res.data)
}

const useCurrencies = (params) => {
    const { data, error, mutate } = useSWR([`${process.env.REACT_APP_ACCOUNTING_API_URL}/currency`, params], fetcher)

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate
    }
}

export default useCurrencies