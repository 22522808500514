import { EyeIcon, EyeOffIcon, NoteIcon } from "components/icons"
import Tooltip from "components/tooltip"
import { useState } from "react"


const NoteTooltip = ({ data, position = "left" }) => {
    const [reference, setReference] = useState()
    const [isOpen, setIsOpen] = useState(false)

    return (
        <>
            <button disabled={!data} ref={setReference} onClick={() => setIsOpen(!isOpen)} className={`${data ? 'bg-neutral-600' : 'bg-neutral-400'} flex justify-center w-full py-1 text-white transition rounded-full active:hover:scale-90`}>
                {isOpen ? (
                    <EyeIcon className="w-4 h-4" stroke={1.5} />
                ) : (
                    <EyeOffIcon className="w-4 h-4" stroke={1.5} />
                )}
            </button>
            <Tooltip onClose={() => setIsOpen(false)} position={position} isOpen={isOpen} reference={reference}>
                <span className="flex max-w-lg py-2 pl-2 pr-4 mr-2 space-x-2 text-xs rounded-2xl bg-neutral-100">
                    <NoteIcon className="w-4 h-4" stroke={1.5} />
                    <div className="flex flex-wrap w-full whitespace-normal">
                        {data}
                    </div>
                </span>
            </Tooltip>
        </>
    )
}

export default NoteTooltip