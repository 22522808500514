import { useState } from 'react'
import { Modal } from 'components'
import 'react-datepicker/dist/react-datepicker.css'
import { lang } from "config"
import { AsteriskIcon, LogicAndIcon } from 'components/icons'
import Delete from 'pages/e-statement/logic/delete'
import useLogics from 'repositories/logic'
import { Create, Edit } from 'pages/e-statement/logic'

const Content = ({ onClose }) => {
    const { data, isLoading } = useLogics({})

    return (
        <div className="mt-8 space-y-8">
            <div className="space-y-6">
                <div className="flex items-center justify-end text-xs">
                    <div className="flex items-center space-x-4">
                        <div>
                            <Create />
                        </div>
                    </div>
                </div>
                <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                    <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                        <thead className="bg-neutral-50 rounded-t-3xl">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.key}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.model}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.type}</th>
                                <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-neutral-200">
                            {/* When loading */}
                            {isLoading && (
                                <tr className="text-center">
                                    <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {lang.loading_data}
                                    </td>
                                </tr>
                            )}

                            {/* When there are no list available */}
                            {data?.data.length === 0 && !isLoading && (
                                <tr className="text-center">
                                    <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {lang.no_data}
                                    </td>
                                </tr>
                            )}

                            {/* When there are no list available on searching */}
                            {data?.data.length > 0 && data.data.map((row) => (
                                <tr>
                                    <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                        <div className="flex items-center space-x-1">
                                            {row.key.map((data) => (
                                                <>
                                                    {data === '*' ? (
                                                        <span className="inline-flex items-center justify-center p-1 text-white rounded-full aspect-square bg-neutral-500">
                                                            <AsteriskIcon className="w-3 h-3" />
                                                        </span>
                                                    ) : (
                                                        <span className="px-1">{data}</span>
                                                    )}
                                                </>
                                            ))}
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {row.model}
                                    </td>
                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {row.type}
                                    </td>
                                    <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                        <div className="flex items-center space-x-2">
                                            {/* <Edit data={row} /> */}
                                            <Delete data={row} />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="flex items-center space-x-2 text-xs">
                <button onClick={onClose} type="button" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.close}</span>
                </button>
            </div>
        </div >
    )
}

const List = () => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    return (
        <>
            <button onClick={openModal} className="inline-flex items-center px-4 py-3 space-x-2 transition border border-neutral-200 bg-neutral-50 rounded-xl active:hover:scale-90">
                <LogicAndIcon className="w-4 h-4" />
            </button>
            <Modal isOpen={isOpen} onClose={closeModal} title={`${lang.logic_list}`} content={<Content onClose={closeModal} />} />
        </>
    )
}

export default List