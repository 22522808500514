import { useEffect, useLayoutEffect, useState } from "react"
import { useLoaderData, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { motion } from "framer-motion"
import {
    DollarIcon,
    CalendarPlusIcon,
    CalendarIcon,
    ArrowBarToDownIcon,
    ArrowBarUpIcon,
    ArrowBackUpIcon,
    BankIcon
} from "components/icons"

import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import Divider from "components/divider"

import moment from "moment/moment"
import { lang } from "config"
import InformationTooltip from "./information-tooltip"
import { Pagination } from "components"
import { usePage } from "contexts/page-context"
import Label from "components/forms/label"

const Detail = () => {
    const { configuration } = usePage()

    const number = useParams().number
    const navigate = useNavigate()
    const location = useLocation()

    const [prevRouteState, setPrevRouteState] = useState({})

    const data = useLoaderData()

    const [searchParams, setSearchParams] = useSearchParams()

    const page = searchParams.get('page')
    const status = searchParams.get('status')

    const statusSelection = [
        {
            id: 1,
            label: 'Matched',
            code: 'matched'
        },
        {
            id: 2,
            label: 'Outstanding',
            code: 'outstanding'
        }
    ]

    useEffect(() => {
        if (status === '') {
            navigate(``, { replace: true })
        }
    }, [status])

    useLayoutEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }
        getPrevRouteState()
    }, [])

    return (
        <AppLayout title={number}>
            <div className="p-4 space-y-16 lg:p-8">
                <div>
                    {prevRouteState.back && (
                        <button className="transition hover:opacity-50" onClick={() => navigate(-1)}>
                            <motion.h3 layout transition={{ duration: .5, type: "tween" }} layoutId={prevRouteState.back} className="text-sm">{prevRouteState.back}</motion.h3>
                        </button>
                    )}
                    <Transition type="slide" reversed>
                        <h1 className="text-3xl font-medium">
                            {number}
                        </h1>
                    </Transition>
                </div>
                <Transition type="slide" reversed>
                    <div className="space-y-6">
                        <div className="flex items-center justify-between p-6 border border-neutral-200 rounded-xl">
                            <div className="flex space-x-2">
                                <div>
                                    <BankIcon className="w-4 h-4 text-neutral-500" stroke={1.5} />
                                </div>
                                <div>
                                    <div className="text-xs text-neutral-500">
                                        {lang.bank}
                                    </div>
                                    <div className="text-2xl">
                                        {data.bank}
                                    </div>
                                </div>
                            </div>

                            <div className="flex space-x-2">
                                <div>
                                    <CalendarIcon className="w-4 h-4 text-neutral-500" stroke={1.5} />
                                </div>
                                <div>
                                    <div className="text-xs text-neutral-500">
                                        {lang.from}
                                    </div>
                                    <div className="text-2xl">
                                        {moment(data.from).format('MMMM DD, YYYY')}
                                    </div>
                                </div>
                            </div>

                            <div className="flex space-x-2">
                                <div>
                                    <CalendarIcon className="w-4 h-4 text-neutral-500" stroke={1.5} />
                                </div>
                                <div>
                                    <div className="text-xs text-neutral-500">
                                        {lang.to}
                                    </div>
                                    <div className="text-2xl">
                                        {moment(data.to).format('MMMM DD, YYYY')}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:grid lg:grid-cols-6 lg:gap-6">
                            <div className="mt-5 lg:col-span-4 lg:mt-0">
                                <div className="overflow-hidden border rounded-xl">
                                    <div className="px-4 py-5 bg-white lg:p-6">
                                        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                                            <div>
                                                <Label htmlFor="status" value={lang.status} />
                                                <select onChange={(e) => setSearchParams({ status: e.target.value })} value={status} name="status" id="status" className={`${false ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                                    <option value={null} selected></option>
                                                    {statusSelection.map((row) => (
                                                        <option value={row.code}>{row.label}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-4 space-y-4">
                                <div className={`${false ? 'border-red-200' : 'border-neutral-200'} overflow-x-auto border rounded-xl`}>
                                    <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                        <thead className="bg-neutral-50 rounded-t-3xl">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.number}</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.reference_number}</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_type}</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.amount}</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_date}</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.information}</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.status}</th>
                                                {/* <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th> */}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-neutral-200">
                                            {/* When there are no list available */}
                                            {data.details?.data.length === 0 && (
                                                <tr className="text-center">
                                                    <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {lang.no_data}
                                                    </td>
                                                </tr>
                                            )}
                                            
                                            {data.details?.data?.length > 0 && data.details?.data?.map((row) => (
                                                <tr key={row.number}>
                                                    <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                        {row.number}
                                                    </td>
                                                    <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                        {row.match_results.length > 0 ? (
                                                            row.match_results.map((data, index) => <>{`${data.transaction_number}${index === (row.match_results.length - 1) ? '' : ', '}`}</>)
                                                        ) : (<>-</>)}
                                                    </td>
                                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {row.transaction_type === 'cr' ? lang.credit : lang.debit}
                                                    </td>
                                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {Intl.NumberFormat('id-Id', { style: 'currency', currency: 'idr' }).format(row.amount)}
                                                    </td>
                                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {moment(row.transaction_date).format('MMMM D, YYYY')}
                                                    </td>
                                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        <InformationTooltip data={row.information} position="left" />
                                                    </td>
                                                    <td className="px-6 py-4 text-xs capitalize whitespace-nowrap">
                                                        <span className={`${configuration('status').find((data) => data.code === row.status)?.class ?? configuration('status').find((data) => data.code === 'default')?.class} inline-flex px-2 text-xs font-semibold leading-5 rounded-full`}>
                                                            {configuration('status').find((data) => data.code === row.status)?.label ?? configuration('status').find((data) => data.code === 'default')?.label}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination links={data.details?.links} from={data.details?.from} to={data.details?.to} total={data.details?.total} />
                            </div>

                            <div className="col-span-2 p-6 space-y-6 text-xs border h-max rounded-xl border-neutral-200">
                                {/* Amount */}
                                <Divider content={`${lang.amount}`} className="font-medium" />
                                <div className="flex space-x-2">
                                    <div>
                                        <ArrowBackUpIcon className="w-4 h-4" stroke={1.5} />
                                    </div>
                                    <div className="space-y-2">
                                        <div className="font-medium">{lang.from}</div>
                                        <div>{Intl.NumberFormat('id-Id', { style: 'currency', currency: 'IDR' }).format(data.begin)}</div>
                                    </div>
                                </div>
                                <div className="flex space-x-2">
                                    <div>
                                        <ArrowBarUpIcon className="w-4 h-4" stroke={1.5} />
                                    </div>
                                    <div className="space-y-2">
                                        <div className="font-medium">{lang.debit}</div>
                                        <div>{Intl.NumberFormat('id-Id', { style: 'currency', currency: 'IDR' }).format(data.debit)}</div>
                                    </div>
                                </div>
                                <div className="flex space-x-2">
                                    <div>
                                        <ArrowBarToDownIcon className="w-4 h-4" stroke={1.5} />
                                    </div>
                                    <div className="space-y-2">
                                        <div className="font-medium">{lang.credit}</div>
                                        <div>{Intl.NumberFormat('id-Id', { style: 'currency', currency: 'IDR' }).format(data.credit)}</div>
                                    </div>
                                </div>
                                <hr />
                                <div className="flex space-x-2">
                                    <div>
                                        <DollarIcon className="w-4 h-4" stroke={1.5} />
                                    </div>
                                    <div className="space-y-2">
                                        <div className="font-medium">{lang.to}</div>
                                        <div>{Intl.NumberFormat('id-Id', { style: 'currency', currency: 'IDR' }).format(data.end)}</div>
                                    </div>
                                </div>

                                {/* Timestamp */}
                                <Divider content="Timestamp" className="font-medium" />
                                <div className="flex space-x-2">
                                    <div>
                                        <CalendarPlusIcon className="w-4 h-4" stroke={1.5} />
                                    </div>
                                    <div className="space-y-2">
                                        <div className="font-medium">{lang.created_at}</div>
                                        <div>{moment(data.created_at).format('MMMM DD, YYYY')}</div>
                                    </div>
                                </div>
                                <div className="flex space-x-2">
                                    <div>
                                        <CalendarIcon className="w-4 h-4" stroke={1.5} />
                                    </div>
                                    <div className="space-y-2">
                                        <div className="font-medium">{lang.updated_at}</div>
                                        <div>{moment(data.updated_at).format('MMMM DD, YYYY')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </AppLayout>
    )
}

export default Detail