import axios from "axios"
import useSWR from "swr"

const fetcher = async (url, params) => {
    return await axios.get(url, { params }).then(res => res.data)
}

const useReports = (type, params) => {
    const map = [
        {
            type: 'cash-flow',
            url: 'cash-flow'
        },
        {
            type: 'petty-cash',
            url: 'petty-cash'
        },
        {
            type: 'bill',
            url: 'bill'
        },
        {
            type: 'outstanding-supplier',
            url: 'outstanding-supplier'
        },
        {
            type: 'ledger-supplier',
            url: 'ledger-supplier'
        },
        {
            type: 'outstanding-karyawan',
            url: 'outstanding-karyawan'
        },
        {
            type: 'ledger-karyawan',
            url: 'ledger-karyawan'
        },
        {
            type: 'biaya-outlet',
            url: 'biaya-outlet'
        },
        {
            type: 'biaya-outlet-detail',
            url: 'biaya-outlet/detail'
        },
        {
            type: 'biaya-global',
            url: 'biaya-global'
        },
        {
            type: 'biaya-global-detail',
            url: 'biaya-global/detail'
        },
        {
            type: 'outstanding-konsumen',
            url: 'outstanding-konsumen'
        },
        {
            type: 'ledger-konsumen',
            url: 'ledger-konsumen'
        }
    ]

    const path = map.find((row) => row.type === type).url

    const { data, error, mutate, isValidating } = useSWR([`${process.env.REACT_APP_REPORT_API_URL}/${path}`, params], fetcher, {
        revalidateOnReconnect: false,
        revalidateOnFocus: false,
        revalidateOnMount: true,
        revalidateIfStale: false
    })

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
        isValidating
    }
}

export default useReports