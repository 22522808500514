const LogicAndIcon = ({ className, stroke }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width={24} height={24} viewBox="0 0 24 24" stroke-width={stroke ?? 1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M22 12h-5"></path>
            <path d="M2 9h5"></path>
            <path d="M2 15h5"></path>
            <path d="M9 5c6 0 8 3.5 8 7s-2 7 -8 7h-2v-14h2z"></path>
        </svg>
    )
}

export default LogicAndIcon