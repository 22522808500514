import { Helmet } from "react-helmet"

import Sidebar from "components/partials/sidebar"
import Topbar from "components/partials/topbar"
import { useEffect, useState } from "react"
import { useLocation, useBeforeUnload } from "react-router-dom"
import { usePage } from "contexts/page-context"
import { useMatch } from "react-router-dom"
import nProgress from "nprogress"
import { motion, useAnimationControls } from "framer-motion"
import useBackButton from "hooks/back-button"
import toast from "react-hot-toast"
import axios from "axios"
import { removeConfig, setConfig } from "helpers/config-helper"
import useBackListener from "hooks/back-listener"

const AppLayout = ({ children, title }) => {
    const page = useAnimationControls()

    const [isSidebarOpen, setIsSidebarOpen] = useState(false)

    // const updateConfig = () => {
    //     toast.success("Back Button Pressed.")

    //     axios.get(`${process.env.REACT_APP_BACKEND_URL}/configuration`)
    //         .then((response) => {
    //             setConfig(response.data)
    //         })
    //         .catch((error) => {
    //             removeConfig()
    //         })
    // }

    // const isBackPressed = useBackListener(updateConfig)

    // toast.success(isBackPressed)

    useEffect(() => {
        if (isSidebarOpen) {
            page.start({
                x: '16rem'
            })
        } else {
            page.start({
                x: 0
            })
        }
    }, [isSidebarOpen])

    return (
        <>
            <Helmet>
                <title>{`${title} – ${process.env.REACT_APP_NAME}`}</title>
            </Helmet>
            <div className="flex overflow-hidden">
                <Sidebar isSidebarOpen={isSidebarOpen} />
                <motion.div onClick={isSidebarOpen ? () => setIsSidebarOpen(false) : () => { }} animate={page} transition={{ type: "spring", damping: 20 }} className="w-full max-h-screen min-h-screen overflow-x-hidden overflow-y-auto bg-white border-8 inset-2 border-neutral-100 rounded-2xl">
                    <Topbar title={title} isSidebarOpen={isSidebarOpen} toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />
                    {children}
                </motion.div>
            </div>
        </>
    )
}

export default AppLayout