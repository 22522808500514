import { useEffect, useState } from 'react'
import { Modal } from 'components'
import { lang } from "config"
import { EyeIcon, EyeOffIcon, UsersIcon } from 'components/icons'
import moment from 'moment'
import SupplierIcon from 'components/icons/supplier'

const Content = ({ data = [], closeModal }) => {
    const [list, setList] = useState(data)

    useEffect(() => {
        if (data.length) {
            setList(data)
        } else {
            setList([])
        }
    }, [data])

    return (
        <div className="mt-8 space-y-8">
            <div className="space-y-6">
                <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                    <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                        <thead className="bg-neutral-50 rounded-t-3xl">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.bill_number}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.grand_total}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.branch}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.customer_or_supplier}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.type}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_type}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.bill_date}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.customer_or_supplier_code}</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-neutral-200">
                            {list.length === 0 && (
                                <tr className="text-center">
                                    <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {lang.no_data}
                                    </td>
                                </tr>
                            )}
                            {list.length !== 0 && list?.map((row) => (
                                <tr key={row}>
                                    <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                        {row.bill_number}
                                    </td>
                                    <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                        {row.currency && Intl.NumberFormat('id-Id', { style: 'currency', currency: row.currency }).format(row.grand_total)}
                                    </td>
                                    <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                        {row.branch?.name}
                                    </td>
                                    <td class="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                        <div className="flex items-center space-x-2">
                                            <span>
                                                {(row.type?.code === 'po' || row.type?.code === 'rt-po' || row.type?.code === 'rt-adv') ? (
                                                    <SupplierIcon className="w-4 h-4" stroke={1.5} />
                                                ) : (
                                                    <UsersIcon className="w-4 h-4" stroke={1.5} />
                                                )}
                                            </span>
                                            <span>
                                                {(row.type?.code === 'po' || row.type?.code === 'rt-po' || row.type?.code === 'rt-adv') ? row.supplier?.name : row.customer?.name}
                                            </span>
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {row.type?.name}
                                    </td>
                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {row.transaction_type === 0 ? lang.income : lang.expense}
                                    </td>
                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {moment(row.bill_date).format('MMMM D, YYYY')}
                                    </td>
                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {row.payor_or_payee_code}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="text-xs">
                <button onClick={closeModal} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.close}</span>
                </button>
            </div>
        </div>
    )
}

const ReferenceList = ({ data = [] }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    return (
        <>
            <button disabled={!data.length} onClick={openModal} className={`${data.length ? 'bg-neutral-600' : 'bg-neutral-400'} flex justify-center w-full py-1 text-white transition rounded-full active:hover:scale-90`}>
                {isOpen ? (
                    <EyeIcon className="w-4 h-4" stroke={1.5} />
                ) : (
                    <EyeOffIcon className="w-4 h-4" stroke={1.5} />
                )}
            </button>
            <Modal isOpen={isOpen} size="4xl" onClose={closeModal} title="List Referensi" content={<Content data={data} closeModal={closeModal} />} />
        </>
    )
}

export default ReferenceList