import { useState } from 'react'
import { Modal } from 'components'
import axios from 'axios'
import nprogress from 'nprogress'
import toast from 'react-hot-toast'
import { lang } from 'config'
import { useEffect } from 'react'
import { useRef } from 'react'
import moment from 'moment'
import { EyeIcon, EyeOffIcon } from 'components/icons'

const Content = ({ data, close }) => {
    return (
        <div className="mt-8 space-y-8">
            <div className="space-y-2">
                <span className="text-xs text-neutral-500">List Data</span>
                <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                    <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                        <thead className="bg-neutral-50 rounded-t-3xl">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_number}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.subtotal}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_date}</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-neutral-200">
                            {data?.advance_payments.map((row, index) => (
                                <tr key={index}>
                                    <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                        {row.transaction_number}
                                    </td>
                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {Intl.NumberFormat('id-Id', { style: 'currency', currency: row.currency }).format(row.subtotal)}
                                    </td>
                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        <span>{`${moment(row.transaction_date).format('MMMM D, YYYY')}`}</span>
                                        <span className="px-2 py-1 ml-2 rounded-full bg-neutral-100">{`${moment(row.transaction_date).format('HH.mm')}`}</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="text-xs">
                <button onClick={close} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.close}</span>
                </button>
            </div>
        </div>
    )
}

const AdvancePayment = ({ data, onSuccess }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    return (
        <>
            <button onClick={openModal} className={`bg-neutral-100 flex justify-center min-w-min px-3 text-neutral-800 transition rounded-full active:hover:scale-90`}>
                {isOpen ? (
                    <EyeIcon className="w-4 h-4" stroke={1.5} />
                ) : (
                    <EyeOffIcon className="w-4 h-4" stroke={1.5} />
                )}
            </button>
            <Modal size="4xl" isOpen={isOpen} onClose={closeModal} title="Detail Uang Muka" content={<Content data={data} close={closeModal} />} />
        </>
    )
}

export default AdvancePayment