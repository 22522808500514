import { useEffect, useState } from "react"

import ErrorMessage from "components/forms/error-message"
import _ from "lodash"
import Label from "components/forms/label"
import Input from "components/forms/input"
import { lang } from "config"
import useLogicTypes from "repositories/logic-type"
import SelectDescription from "components/forms/select-description"
import Description from "components/forms/description"

const Form = ({ data = {}, errors, onSubmit }) => {
    const id = data.id ?? undefined
    const [key, setKey] = useState(data.key ?? null)
    const [model, setModel] = useState(data.model?.replace(/([a-z0–9])([A-Z])/g, "$1-$2").toLowerCase() ?? null)
    const [type, setType] = useState(data.type ?? null)

    useEffect(() => {
        if (Object.keys(data).length) {
            setModel(data.model.replace(/([a-z0–9])([A-Z])/g, "$1-$2").toLowerCase())
        }
    }, [data])

    const modelSelection = [
        {
            id: 1,
            label: "Petty Cash",
            code: "petty-cash",
            type: {
                keyValue: "number",
                title: "description",
                description: "number"
            }
        },
        {
            id: 2,
            label: "Bill",
            code: "bill",
            type: {
                keyValue: "code",
                title: "name",
                description: "code"
            }
        }
    ]

    const { data: typeSelection, isLoading: isLoadingType } = useLogicTypes(model, { flat: true })

    const handleSubmit = (e) => {
        e.preventDefault()

        const keys = key ? key.replaceAll('*', '//*//').split('//').filter((row) => row !== "") : null
        
        onSubmit({
            id,
            key: keys,
            model,
            type
        })
    }
    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                    <Label htmlFor="model" value={lang.model} />
                    <select onChange={(e) => setModel(e.target.value)} value={model} name="model" id="model" className={`${errors.model ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                        <option value={null} selected disabled>-- {lang.choose} {lang.model} --</option>
                        {modelSelection?.length !== 0 && modelSelection?.map((row) => (
                            <option value={row.code}>{row.label}</option>
                        ))}
                    </select>
                    <ErrorMessage error={errors.model} />
                </div>
                <div>
                    <Label htmlFor="type" value={lang.type} />
                    <SelectDescription onChange={(value) => setType(value)} placeholder={`${lang.choose} ${lang.type}`} selection={typeSelection} isLoading={isLoadingType} value={type} keyValue={modelSelection.find((row) => row.code === model)?.type.keyValue} title={modelSelection.find((row) => row.code === model)?.type.title} description={modelSelection.find((row) => row.code === model)?.type.description} error={errors.type} />
                    <ErrorMessage error={errors.type} />
                </div>
                <div className="col-span-2">
                    <Label htmlFor="key" value={lang.key} />
                    <Input onChange={(e) => setKey(e.target.value)} error={errors.key} value={key} id="key" />
                    <Description value="Use * for wildcard." error={errors.key} />
                </div>
            </div>
            <div className="text-xs">
                <button type="submit" onClick={handleSubmit} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </form>
    )
}

export default Form