import { createBrowserRouter, createHashRouter } from "react-router-dom"

import GuestRoute from "routes/middleware/guest"
import ProtectedRoute from "routes/middleware/protected"

import Dashboard from "pages/dashboard"
import PettyCash from "pages/petty-cash"
import PettyCashDetail from "pages/petty-cash/detail"
import Bill from "pages/bill"
import BillDetail from "pages/bill/detail"
import Invoice from "pages/invoice"
import CreateInvoice from "pages/invoice/create"
import InvoiceDetail from "pages/invoice/detail"
import EditInvoice from "pages/invoice/edit"
import Transaction from "pages/transaction"
import Pay from "pages/transaction/pay"
import User from "pages/user"
import UserDetail from "pages/user/detail"
import Role from "pages/role"
import RoleDetail from "pages/role/detail"
import CreateRole from "pages/role/create"
import EditRole from "pages/role/edit"
import Approve from "pages/approval/approve"
import Submitted from "pages/approval/submitted"
import Login from "pages/auth/login"
import Register from "pages/auth/register"
import Supplier from "pages/supplier"
import Customer from "pages/customer"
import NoRole from "pages/no-role"
import axios from "axios"
import CreateSupplier from "pages/supplier/create"
import EditSupplier from "pages/supplier/edit"
import { show as showSupplier } from "./loader/supplier"
import { show as showRole } from "./loader/role"
import { show as showAdvance } from "./loader/advance-payment"
import { show as showTransaction } from "./loader/transaction"
import { show as showBill } from "./loader/bill"
import { show as showInvoice } from "./loader/invoice"
import { show as showDashboard } from "./loader/dashboard"
import { show as showTaxPayment } from "./loader/tax-payment"
import { show as showPettyCash } from "./loader/petty-cash"
import AdvancePayment from "pages/advance-payment"
import CreateAdvancePayment from "pages/advance-payment/pay"
import AdvancePaymentDetail from "pages/advance-payment/detail"
import TransactionDetail from "pages/transaction/detail"
import Branch from "pages/branch"
import { load as loadMainData } from "./loader/main"
import { loadAutoRedirect as loadAutoRedirectApproval } from "./loader/approval"
import { load as loadApproval } from "./loader/approval"
import { getByUser as getBranchByUser } from "./loader/branch"
import SelectBranch from "pages/select-branch"
import EditAdvancePayment from "pages/advance-payment/edit"
import { lang } from "config"
import RoleUserBranch from "pages/role-user-branch"
import AlreadySubmitted from "pages/approval/already-submitted"
import TaxPayment from "pages/tax-payment"
import Error from "pages/error"
import VerifyPayment from "pages/verify-payment"
import Generate from "pages/tax-payment/generate"
import TaxPaymentDetail from "pages/tax-payment/detail"
import { Create as CreatePettyCash, Edit as EditPettyCash } from "pages/petty-cash/index"
import { PettyCash as PettyCashReport, Bill as BillReport, CashFlow as CashFlowReport, SupplierLedger } from "pages/report"
import { Detail as EStatementDetail, Match, List as EStatementList } from "pages/e-statement"
import { Create as CreateMerchant, Detail as MerchantDetail, Edit as EditMerchant, List as MerchantList } from "pages/merchant"
import { show as showMerchant } from "./loader/merchant"
import { List as MerchantFeeList } from "pages/merchant/fee"
import { showMatched as showMatchedEStatement, show as showEStatement } from "./loader/e-statement"

import { List as OutstandingSupplierReportList } from "pages/report/outstanding-supplier"
import LedgerSupplier from "pages/report/ledger-supplier"
import HutangOutstandingKaryawan from "pages/report/hutang-outstanding-karyawan"
import PiutangOutstandingKaryawan from "pages/report/piutang-outstanding-karyawan"
import LedgerKaryawan from "pages/report/ledger-karyawan"
import BiayaOutletRekap from "pages/report/biaya-outlet/rekap"
import BiayaOutletDetail from "pages/report/biaya-outlet/detail"
import BiayaGlobalRekap from "pages/report/biaya-global/rekap"
import BiayaGlobalDetail from "pages/report/biaya-global/detail"
import HutangLedgerKaryawan from "pages/report/ledger-karyawan/hutang"
import PiutangLedgerKaryawan from "pages/report/ledger-karyawan/piutang"
import OutstandingKonsumen from "pages/report/outstanding-konsumen"
import LedgerKonsumen from "pages/report/ledger-konsumen"
import Reset from "pages/auth/password/reset"
import Profile from "pages/profile"

const routeList = [
    // Root loader
    {
        loader: loadMainData,
        // errorElement: process.env.NODE_ENV === 'development' ? undefined : <Error />,
        children: [
            // Guest
            {
                path: "/register",
                element: (
                    <GuestRoute>
                        <Register title={lang.title.sign_up} />
                    </GuestRoute>
                )
            },
            {
                path: "/login",
                element: (
                    <GuestRoute>
                        <Login title={lang.title.sign_in} />
                    </GuestRoute>
                )
            },
            {
                path: "/password/reset",
                element: (
                    <GuestRoute>
                        <Reset title={lang.title.reset_password} />
                    </GuestRoute>
                )
            },

            // Protected
            {
                path: "/",
                element: (
                    <ProtectedRoute >
                        <Dashboard key="/" title={lang.title.dashboard} />
                    </ProtectedRoute>
                ),
                loader: showDashboard
            },
            {
                path: "/branch/select",
                element: (
                    <ProtectedRoute>
                        <SelectBranch title={lang.title.select_branch} />
                    </ProtectedRoute>
                ),
                loader: getBranchByUser
            },
            {
                path: "petty-cash",
                element: (
                    <ProtectedRoute>
                        <PettyCash key="/petty-cash" title={lang.title.petty_cash.list} />
                    </ProtectedRoute>
                )
            },
            {
                path: "/petty-cash/create",
                element: (
                    <ProtectedRoute>
                        <CreatePettyCash key="/petty-cash/create" title={lang.title.petty_cash.create} />
                    </ProtectedRoute>
                )
            },
            {
                path: "/petty-cash/report",
                element: (
                    <ProtectedRoute>
                        <PettyCashReport title="Petty Cash Report" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/petty-cash/:number",
                element: (
                    <ProtectedRoute>
                        <PettyCashDetail />
                    </ProtectedRoute>
                ),
                loader: showPettyCash
            },
            {
                path: "/petty-cash/:number/edit",
                element: (
                    <ProtectedRoute>
                        <EditPettyCash />
                    </ProtectedRoute>
                ),
                loader: showPettyCash
            },
            {
                path: "/bill",
                element: (
                    <ProtectedRoute>
                        <Bill title={lang.title.bills} />
                    </ProtectedRoute>
                )
            },
            {
                path: "/bill/report",
                element: (
                    <ProtectedRoute>
                        <BillReport title="Bill Report" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/bill/:number",
                element: (
                    <ProtectedRoute>
                        <BillDetail />
                    </ProtectedRoute>
                ),
                loader: showBill
            },
            {
                path: "/invoice",
                element: (
                    <ProtectedRoute>
                        <Invoice title={lang.title.invoice.list} />
                    </ProtectedRoute>
                )
            },
            {
                path: "/invoice/create",
                element: (
                    <ProtectedRoute>
                        <CreateInvoice title={lang.title.invoice.create} />
                    </ProtectedRoute>
                )
            },
            {
                path: "/invoice/:number",
                element: (
                    <ProtectedRoute>
                        <InvoiceDetail />
                    </ProtectedRoute>
                ),
                loader: showInvoice
            },
            {
                path: "/invoice/:number/edit",
                element: (
                    <ProtectedRoute>
                        <EditInvoice />
                    </ProtectedRoute>
                ),
                loader: showInvoice
            },
            {
                path: "/transaction",
                element: (
                    <ProtectedRoute>
                        <Transaction title={lang.title.transaction.list} />
                    </ProtectedRoute>
                )
            },
            {
                path: "/transaction/:number",
                element: (
                    <ProtectedRoute>
                        <TransactionDetail />
                    </ProtectedRoute>
                ),
                loader: showTransaction
            },
            {
                path: "/transaction/pay",
                element: (
                    <ProtectedRoute>
                        <Pay title={lang.title.transaction.pay} />
                    </ProtectedRoute>
                )
            },
            {
                path: "/advance-payment",
                element: (
                    <ProtectedRoute>
                        <AdvancePayment title={lang.title.advance.list} />
                    </ProtectedRoute>
                )
            },
            {
                path: "/advance-payment/:number",
                element: <ProtectedRoute>
                    <AdvancePaymentDetail />
                </ProtectedRoute>,
                loader: showAdvance
            },
            {
                path: "/advance-payment/:number/edit",
                element: (
                    <ProtectedRoute>
                        <EditAdvancePayment />
                    </ProtectedRoute>
                ),
                loader: showAdvance
            },
            {
                path: "/advance-payment/pay",
                element: (
                    <ProtectedRoute>
                        <CreateAdvancePayment title={lang.title.advance.pay} />
                    </ProtectedRoute>
                )
            },
            {
                path: "/tax-payment",
                element: (
                    <ProtectedRoute>
                        <TaxPayment title={lang.title.tax.list} />
                    </ProtectedRoute>
                )
            },
            {
                path: "/tax-payment/generate",
                element: (
                    <ProtectedRoute>
                        <Generate title={lang.title.tax.generate} />
                    </ProtectedRoute>
                )
            },
            {
                path: "/tax-payment/:number",
                element: (
                    <ProtectedRoute>
                        <TaxPaymentDetail />
                    </ProtectedRoute>
                ),
                loader: showTaxPayment
            },
            {
                path: "/verify-payment",
                element: (
                    <ProtectedRoute>
                        <VerifyPayment title={lang.title.verify} />
                    </ProtectedRoute>
                )
            },
            {
                path: "/e-statement",
                element: (
                    <ProtectedRoute>
                        <EStatementList title={lang.title.e_statement.list} />
                    </ProtectedRoute>
                )
            },
            {
                path: "/e-statement/:number",
                element: (
                    <ProtectedRoute>
                        <EStatementDetail />
                    </ProtectedRoute>
                ),
                loader: showEStatement
            },
            {
                path: "/e-statement/:number/match",
                element: (
                    <ProtectedRoute>
                        <Match />
                    </ProtectedRoute>
                ),
                loader: showEStatement
            },
            {
                path: "/report/cash-flow",
                element: (
                    <ProtectedRoute>
                        <CashFlowReport title={lang.title.report.cash_flow} />
                    </ProtectedRoute>
                )
            },
            {
                path: "/report/bill",
                element: (
                    <ProtectedRoute>
                        <BillReport title={lang.title.report.bill} />
                    </ProtectedRoute>
                )
            },
            {
                path: "/report/petty-cash",
                element: (
                    <ProtectedRoute>
                        <PettyCashReport title={lang.title.report.petty_cash} />
                    </ProtectedRoute>
                )
            },
            {
                path: "/report/outstanding-supplier",
                element: (
                    <ProtectedRoute>
                        <OutstandingSupplierReportList title="Outstanding Supplier Report" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/report/ledger-supplier",
                element: (
                    <ProtectedRoute>
                        <LedgerSupplier title="Ledger Supplier Report" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/report/outstanding-karyawan/hutang",
                element: (
                    <ProtectedRoute>
                        <HutangOutstandingKaryawan title="Hutang Outstanding Karyawan Report" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/report/outstanding-karyawan/piutang",
                element: (
                    <ProtectedRoute>
                        <PiutangOutstandingKaryawan title="Piutang Outstanding Karyawan Report" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/report/ledger-karyawan/hutang",
                element: (
                    <ProtectedRoute>
                        <HutangLedgerKaryawan title="Hutang Ledger Karyawan Report" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/report/ledger-karyawan/piutang",
                element: (
                    <ProtectedRoute>
                        <PiutangLedgerKaryawan title="Piutang Ledger Karyawan Report" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/report/biaya-outlet",
                element: (
                    <ProtectedRoute>
                        <BiayaOutletRekap title="Biaya Outlet Report - Rekap" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/report/biaya-outlet/detail",
                element: (
                    <ProtectedRoute>
                        <BiayaOutletDetail title="Biaya Outlet Report - Detail" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/report/biaya-global",
                element: (
                    <ProtectedRoute>
                        <BiayaGlobalRekap title="Biaya Global Report - Rekap" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/report/biaya-global/detail",
                element: (
                    <ProtectedRoute>
                        <BiayaGlobalDetail title="Biaya Global Report - Detail" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/report/outstanding-konsumen",
                element: (
                    <ProtectedRoute>
                        <OutstandingKonsumen title="Outstanding Konsumen Report" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/report/ledger-konsumen",
                element: (
                    <ProtectedRoute>
                        <LedgerKonsumen title="Ledger Konsumen Report" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/approve/:number",
                key: 'approve',
                element: (
                    <ProtectedRoute needToHaveRole={false} needToSelectBranch={false}>
                        <Approve />
                    </ProtectedRoute>
                ),
                loader: loadAutoRedirectApproval
            },
            {
                path: "/approve/:number/submitted",
                element: (
                    <ProtectedRoute needToHaveRole={false} needToSelectBranch={false}>
                        <Submitted title="Approval Submitted" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/approve/:number/already",
                element: (
                    <ProtectedRoute needToHaveRole={false} needToSelectBranch={false}>
                        <AlreadySubmitted title="Approval Already Submitted" />
                    </ProtectedRoute>
                ),
                loader: loadApproval
            },
            {
                path: "/branch",
                element: (
                    <ProtectedRoute>
                        <Branch title="Branches" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/supplier",
                element: (
                    <ProtectedRoute>
                        <Supplier title="Suppliers" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/supplier/:id/edit",
                element: (
                    <ProtectedRoute>
                        <EditSupplier />
                    </ProtectedRoute>
                ),
                loader: showSupplier
            },
            {
                path: "/supplier/create",
                element: (
                    <ProtectedRoute>
                        <CreateSupplier title="Create Supplier" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/customer",
                element: (
                    <ProtectedRoute>
                        <Customer title="Customer" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/user",
                element: (
                    <ProtectedRoute>
                        <User title="Users" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/user/:phone",
                element: (
                    <ProtectedRoute>
                        <UserDetail />
                    </ProtectedRoute>
                )
            },
            {
                path: "/role",
                element: (
                    <ProtectedRoute>
                        <Role title={lang.title.role.list} />
                    </ProtectedRoute>
                )
            },
            {
                path: "/role/create",
                element: (
                    <ProtectedRoute>
                        <CreateRole title={lang.title.role.create} />
                    </ProtectedRoute>
                )
            },
            {
                path: "/role/:id",
                element: (
                    <ProtectedRoute>
                        <RoleDetail />
                    </ProtectedRoute>
                ),
                loader: showRole
            },
            {
                path: "/role/:id/edit",
                element: (
                    <ProtectedRoute>
                        <EditRole />
                    </ProtectedRoute>
                ),
                loader: showRole
            },
            {
                path: "/role-user-branch",
                element: (
                    <ProtectedRoute>
                        <RoleUserBranch title="Map Role, User, dan Cabang" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/merchant",
                element: (
                    <ProtectedRoute>
                        <MerchantList title={lang.title.merchant.list} />
                    </ProtectedRoute>
                )
            },
            {
                path: "/merchant/create",
                element: (
                    <ProtectedRoute>
                        <CreateMerchant title={lang.title.merchant.create} />
                    </ProtectedRoute>
                )
            },
            {
                path: "/merchant/:id",
                element: (
                    <ProtectedRoute>
                        <MerchantDetail />
                    </ProtectedRoute>
                ),
                loader: showMerchant
            },
            {
                path: "/merchant/:id/edit",
                element: (
                    <ProtectedRoute>
                        <EditMerchant />
                    </ProtectedRoute>
                ),
                loader: showMerchant
            },
            {
                path: "/merchant/fee",
                element: (
                    <ProtectedRoute>
                        <MerchantFeeList title={lang.title.merchant.fee.list} />
                    </ProtectedRoute>
                )
            },
            {
                path: "/profile",
                element: (
                    <ProtectedRoute>
                        <Profile title={lang.title.profile} />
                    </ProtectedRoute>
                )
            },
            {
                path: "/no-role",
                element: (
                    <ProtectedRoute>
                        <NoRole title="No Role Assigned" />
                    </ProtectedRoute>
                )
            }
        ]
    }
]

const routes = process.env.NODE_ENV === 'development' ? createBrowserRouter(routeList) : createHashRouter(routeList)

export default routes