import { useState } from 'react'
import { Modal } from 'components'
import moment from 'moment/moment'
import SupplierIcon from 'components/icons/supplier'
import { UsersIcon } from 'components/icons'
import { lang } from "config"
import { Link } from 'react-router-dom'

const Content = ({ data, pageTitle, closeModal }) => {
    return (
        <div className="mt-8 space-y-8">
            <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                    <thead className="bg-neutral-50 rounded-t-3xl">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.bill}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.branch}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.customer_or_supplier}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.customer_or_supplier_code}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.type}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_type}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.bill_date}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.grand_total}</th>
                            <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-neutral-200">
                        {data.map(row => (
                            <tr key={row.bill_number}>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.bill_number}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.bill.branch?.name}
                                </td>
                                <td class="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    <div className="flex items-center space-x-2">
                                        <span>
                                            {(row.bill.type?.code === 'po' || row.bill.type?.code === 'rt-po' || row.bill.type?.code === 'rt-adv') ? (
                                                <SupplierIcon className="w-4 h-4" stroke={1.5} />
                                            ) : (
                                                <UsersIcon className="w-4 h-4" stroke={1.5} />
                                            )}
                                        </span>
                                        <span>
                                            {(row.bill.type?.code === 'po' || row.bill.type?.code === 'rt-po' || row.bill.type?.code === 'rt-adv') ? row.bill.supplier?.name : row.bill.customer?.name}
                                        </span>
                                    </div>
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.bill.payor_or_payee_code}
                                </td>
                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {row.bill.type?.name}
                                </td>
                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {row.bill.transaction_type === 0 ? lang.income : lang.expense}
                                </td>
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {moment(row.bill.bill_date).format('MMMM D, YYYY')}
                                </td>
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {row.bill.currency && Intl.NumberFormat('id-Id', { style: 'currency', currency: row.bill.currency }).format(row.bill.grand_total)}
                                </td>
                                <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                    <div class="inline-flex items-center space-x-2">
                                        <Link to={{ pathname: `/bill/${encodeURIComponent(row.bill.bill_number)}` }} state={{ back: pageTitle, transition: 'slide' }} class="inline-flex items-center p-1 text-white transition bg-neutral-800 rounded-full active:hover:scale-90">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <circle cx={12} cy={12} r={2}></circle>
                                                <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                                            </svg>
                                        </Link>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="text-xs">
                <button onClick={closeModal} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.close}</span>
                </button>
            </div>
        </div>
    )
}

const BillList = ({ data, pageTitle, largeButton = false }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    return (
        <>
            {largeButton ? (
                <button type="button" onClick={openModal} className="block w-full px-2 py-2 mt-1 text-sm transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200">
                    {lang.see_bills} {data?.length !== 0 && `(${data.length})`}
                </button>
            ) : (
                <button onClick={openModal} className="inline-flex items-center space-x-2 text-xs underline">
                    <div>
                        {lang.see_bills}
                    </div>
                </button>
            )}
            <Modal isOpen={isOpen} size="6xl" onClose={closeModal} title={lang.bill_list} content={<Content data={data} pageTitle={pageTitle} closeModal={closeModal} />} />
        </>
    )
}

export default BillList