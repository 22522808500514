import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { motion } from "framer-motion"

import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import moment from "moment/moment"
import { useAuth } from "contexts/auth-context"
import nProgress from "nprogress"
import Filter from "pages/tax-payment/filter"
import { CalculatorIcon } from "components/icons"
import { Pagination } from "components"
import { lang } from "config"
import useTaxPayments from "repositories/tax-payment"
import Cancel from "./tax-payment/cancel"
import { usePage } from "contexts/page-context"

const TaxPayment = (props) => {
    // Logged in user ability
    const { can, isSuper, currentBranch } = useAuth()
    // Page context
    const { configuration } = usePage()

    // React router navigate and location hook
    const navigate = useNavigate()
    const location = useLocation()

    // Page transition
    const [transition, setTransition] = useState(location.state?.transition)

    // Query parameters
    const [searchParams, setSearchParams] = useSearchParams()

    const page = searchParams.get('page')
    const search = searchParams.get('search')
    const transactionNumber = searchParams.get('transaction_number')
    const taxType = searchParams.get('tax_type')
    const taxIn = searchParams.get('in')
    const taxOut = searchParams.get('out')
    const prepopulated = searchParams.get('prepopulated')
    const total = searchParams.get('total')
    const from = searchParams.get('from')
    const to = searchParams.get('to')

    // Advance payment data
    const { data: taxPayments, isLoading, mutate } = useTaxPayments({
        page,
        search,
        transaction_number: transactionNumber,
        tax_type: taxType,
        in: taxIn,
        out: taxOut,
        prepopulated,
        total,
        from,
        to
    })

    // Watch changes on billy cash data and loading state
    useEffect(() => {
        if (search === "") {
            navigate(``, { replace: true })
        }

        if (isLoading) {
            nProgress.start()
        } else {
            nProgress.done()
        }
    }, [taxPayments, isLoading])

    // Data filter handlers
    const updateFilter = (value) => {
        setSearchParams(value)
    }

    const removeFilter = () => {
        setSearchParams({})
    }

    return (
        <AppLayout title={props.title}>
            <div className="p-4 space-y-16 lg:p-8">
                <div>
                    <button disabled className="pointer-events-none">
                        <Link to={location.pathname} className="cursor-default">
                            <motion.h1 layout transition={{ duration: .5, type: "tween" }} layoutId={props.title} className="text-3xl font-medium">
                                {props.title}
                            </motion.h1>
                        </Link>
                    </button>
                </div>
                <Transition>
                    <div className="space-y-6">
                        <div className="flex items-center justify-between text-xs">
                            <div className="flex items-center sm:space-x-2">
                                <div className="relative hidden sm:block">
                                    <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <circle cx={10} cy={10} r={7}></circle>
                                            <line x1={21} y1={21} x2={15} y2={15}></line>
                                        </svg>
                                    </div>
                                    <input onChange={(e) => setSearchParams({ search: e.target.value })} value={searchParams.get('search')} type="text" placeholder={`${lang.search} ${lang.tax_payment}`} autoComplete="off" className="w-64 py-3 pl-8 pr-4 text-xs transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200" />
                                </div>
                                <Filter onSubmit={updateFilter} onRemove={removeFilter} data={Object.fromEntries(Object.entries({ transactionNumber, taxType, taxIn, taxOut, total, from, to }).filter(([_, v]) => v != null))} />
                            </div>

                            {can('tax.generate') && (
                                <div>
                                    <Link to="generate" state={{ back: props.title, from: location?.pathname, transition: 'slide' }} className="inline-flex items-center px-4 py-3 space-x-2 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                                        <CalculatorIcon className="w-4 h-4" stroke={1.5} />
                                        <span>{lang.title.tax.generate}</span>
                                    </Link>
                                </div>
                            )}
                        </div>
                        <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                            <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                <thead className="bg-neutral-50 rounded-t-3xl">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.tax_payment}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.from}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.to}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.tax_type}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.in}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.out}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.total}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.status}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.created_at}</th>
                                        <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-neutral-200">
                                    {/* When loading */}
                                    {isLoading && (
                                        <tr className="text-center">
                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.loading_data}
                                            </td>
                                        </tr>
                                    )}

                                    {/* When there are no list available */}
                                    {taxPayments?.data.length === 0 && !search && !isLoading && (
                                        <tr className="text-center">
                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.no_data}
                                            </td>
                                        </tr>
                                    )}

                                    {/* When there are no list available on searching */}
                                    {taxPayments?.data.length === 0 && search && !isLoading && (
                                        <tr className="text-center">
                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.no_result}
                                            </td>
                                        </tr>
                                    )}

                                    {taxPayments?.data.length > 0 && taxPayments.data.map(row => (
                                        <tr key={row.transaction_number}>
                                            <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                {row.transaction_number}
                                            </td>
                                            <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {moment(row.from).format('MMMM D, YYYY')}
                                            </td>
                                            <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {moment(row.to).format('MMMM D, YYYY')}
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap uppercase">
                                                {row.tax_type.replace('-', ' ')}
                                            </td>
                                            <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(row.in)}
                                            </td>
                                            <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(row.out)}
                                            </td>
                                            <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(row.total)}
                                            </td>
                                            <td className="px-6 py-4 text-xs capitalize whitespace-nowrap">
                                                <span className={`${configuration('status').find((data) => data.code === row.status)?.class ?? configuration('status').find((data) => data.code === 'default')?.class} inline-flex px-2 text-xs font-semibold leading-5 rounded-full`}>
                                                    {configuration('status').find((data) => data.code === row.status)?.label ?? configuration('status').find((data) => data.code === 'default')?.label}
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {moment(row.transaction_date).format('MMMM D, YYYY')}
                                            </td>
                                            <td className="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                <div className="inline-flex items-center space-x-2">
                                                    <Link onClick={() => setTransition('slide')} to={row.transaction_number} state={{ back: props.title, from: location.pathname, transition: 'slide' }} className="inline-flex items-center p-1 text-white transition rounded-full bg-neutral-800 active:hover:scale-90">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <circle cx={12} cy={12} r={2}></circle>
                                                            <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                                                        </svg>
                                                    </Link>

                                                    {can('tax.drop') && (
                                                        <Cancel data={row} onSuccess={mutate} />
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination links={taxPayments?.links} from={taxPayments?.from} to={taxPayments?.to} total={taxPayments?.total} />
                    </div>
                </Transition>
            </div>
        </AppLayout>
    )
}

export default TaxPayment