import { useState } from 'react'
import { Modal } from 'components'
import axios from 'axios'
import nprogress from 'nprogress'
import toast from 'react-hot-toast'
import { lang } from 'config'
import { CalculatorIcon, CalendarIcon, DollarIcon, VerifyPaymentIcon, WalletIcon } from 'components/icons'
import moment from 'moment'
import { useEffect } from 'react'
import { useRef } from 'react'

const usePrevious = (value) => {
    const ref = useRef()

    useEffect(() => {
        ref.current = value
    }, [value])

    return ref.current
}

const Content = ({ data, close }) => {
    const [total, setTotal] = useState(null)

    const prevData = usePrevious(data)

    useEffect(() => {
        if (prevData !== data) {
            const order_total = data.order.grand_total

            var payment_total = 0

            Object.keys(data.payment).map((method) => {
                data.payment[method].map((row) => {
                    if (method === 'midtrans') {
                        payment_total += parseFloat(row.gross_amount)
                    } else if (method === 'bca') {
                        payment_total += parseFloat(row.virtualAccountData.paidAmount.value)
                    }
                })
            })

            setTotal(parseFloat(order_total) - parseFloat(payment_total))
        }
    }, [data])

    return (
        <div className="mt-8 space-y-8">
            <div className="space-y-2">
                <span className="text-xs text-neutral-500">{lang.order}</span>
                <div className="text-xs border divide-y rounded-xl">
                    <div className="flex items-center justify-between p-4">
                        <span className="font-medium">{lang.order_number}</span>
                        <span className="text-neutral-500">{data.order.order_id}</span>
                    </div>
                    <div className="flex items-center justify-between p-4">
                        <span className="font-medium">{lang.branch}</span>
                        <span className="text-neutral-500">{data.order.branch_name}</span>
                    </div>
                    <div className="flex items-center justify-between p-4">
                        <span className="font-medium">{lang.customer_number}</span>
                        <span className="text-neutral-500">{data.order.customer_number}</span>
                    </div>
                    <div className="flex items-center justify-between p-4">
                        <span className="font-medium">{lang.customer_name}</span>
                        <span className="text-neutral-500">{data.order.customer_name}</span>
                    </div>
                    <div className="flex items-center justify-between p-4">
                        <span className="font-medium">{lang.vehicle_number}</span>
                        <span className="text-neutral-500">{data.order.vehicle_number}</span>
                    </div>
                    <div className="flex items-center justify-between p-4">
                        <span className="font-medium">{lang.grand_total}</span>
                        <span className="text-neutral-500">{Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(data.order.grand_total)}</span>
                    </div>
                    <div className="flex items-center justify-between p-4">
                        <span className="font-medium">{lang.order_date}</span>
                        <span className="text-neutral-500">{moment(data.order.order_date).format('MMMM D, YYYY')}</span>
                    </div>
                    <div className="flex items-center justify-between p-4">
                        <span className="font-medium">{lang.payment_status}</span>
                        <span className={`${data.status ? "text-green-800 bg-green-100" : "text-red-800 bg-red-100"} inline-flex px-2 text-xs font-semibold leading-5 rounded-full`}>
                            {`${data.status ? "Lunas" : "Belum Lunas"}`}
                        </span>
                    </div>
                </div>
            </div>
            <div className="space-y-2">
                <span className="text-xs text-neutral-500">{lang.payment}</span>
                <div className="px-4 pt-2 pb-4 space-y-4 border rounded-xl">
                    {Object.keys(data.payment).map((method) => (
                        <div className="space-y-2">
                            <span className="text-xs text-neutral-500">{method === 'bca' ? 'BCA Virtual Account' : 'Midtrans'}</span>
                            <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                                <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                    <thead className="bg-neutral-50 rounded-t-3xl">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.payment_number}</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.payment_type}</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.amount}</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.payment_status}</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_date}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-neutral-200">
                                        {data.payment[method].map((row, index) => (
                                            <tr key={index}>
                                                {method === 'midtrans' && (
                                                    <>
                                                        <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                            {row.order_id}
                                                        </td>
                                                        <td className="px-6 py-4 text-xs font-medium capitalize text-neutral-900 whitespace-nowrap">
                                                            {row.payment_type === 'cstore' ? row.store : (row.payment_type === 'credit_card' ? row.bank : (row.payment_type === 'cimb_clicks' ? 'Octo Clicks' : (row.payment_type === 'bca_klikpay' ? 'BCA Klikpay' : (row.payment_type === 'bri_epay' ? 'BRImo' : (row.payment_type === 'echannel' ? 'Mandiri' : (row.payment_type === 'bank_transfer' && row.permata_va_number ? 'Permata' : (row.payment_type === 'bank_transfer' && row.va_numbers ? row.va_numbers[0].bank : (row.payment_type === 'danamon_online' ? 'Danamon' : (row.payment_type === 'akulaku' ? 'Akulaku' : (row.payment_type === 'qris' ? 'QRIS' : (row.payment_type === 'kredivo' ? 'Kredivo' : (row.payment_type === 'uob_ezpay' ? 'UOB EZ Pay' : row.payment_type))))))))))))}
                                                        </td>
                                                        <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                            {Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(row.gross_amount)}
                                                        </td>
                                                        <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                            <span className={`${(row.transaction_status === "capture" || row.transaction_status === "settlement") ? "text-green-800 bg-green-100" : "text-red-800 bg-red-100"} inline-flex px-2 text-xs font-semibold leading-5 rounded-full`}>
                                                                {`${(row.transaction_status === "capture" || row.transaction_status === "settlement") ? "Lunas" : "Belum Lunas"}`}
                                                            </span>
                                                        </td>
                                                        <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                            <span>{`${moment(row.transaction_time).format('MMMM D, YYYY')}`}</span>
                                                            <span className="px-2 py-1 ml-2 rounded-full bg-neutral-100">{`${moment(row.transaction_time).format('HH.mm')}`}</span>
                                                        </td>
                                                    </>
                                                )}
                                                {method === 'bca' && (
                                                    <>
                                                        <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                            {row.virtualAccountData.paymentRequestId}
                                                        </td>
                                                        <td className="px-6 py-4 text-xs font-medium uppercase text-neutral-900 whitespace-nowrap">
                                                            {method}
                                                        </td>
                                                        <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                            {Intl.NumberFormat('id-Id', { style: 'currency', currency: row.virtualAccountData.paidAmount.currency }).format(row.virtualAccountData.paidAmount.value)}
                                                        </td>
                                                        <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                            <span className={`${row.virtualAccountData.paymentFlagStatus === "00" ? "text-green-800 bg-green-100" : (row.virtualAccountData.paymentFlagStatus === "01" ? "text-red-800 bg-red-100" : "text-neutral-800 bg-neutral-100")} inline-flex px-2 text-xs font-semibold leading-5 rounded-full`}>
                                                                {`${row.virtualAccountData.paymentFlagStatus === "00" ? "Lunas" : (row.virtualAccountData.paymentFlagStatus === "01" ? "Belum Lunas" : "Tidak diketahui")}`}
                                                            </span>
                                                        </td>
                                                        <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                            <span>{`${moment(row.virtualAccountData.transactionDate).format('MMMM D, YYYY')}`}</span>
                                                            <span className="px-2 py-1 ml-2 rounded-full bg-neutral-100">{`${moment(row.virtualAccountData.transactionDate).format('HH.mm')}`}</span>
                                                        </td>
                                                    </>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="text-xs">
                <button onClick={close} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.close}</span>
                </button>
            </div>
        </div>
    )
}

const PaymentDetail = ({ data, onSuccess }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const [paymentDetail, setPaymentDetail] = useState({})

    const verify = (e) => {
        e.preventDefault()
        nprogress.start()

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/payment/verify`, {
            bill_number: data.order_id
        }).then((response) => {
            nprogress.done()
            toast.success(response.data.message)
            setPaymentDetail({
                order: data,
                payment: response.data.data,
                status: response.data.payment_status
            })
            openModal()
        }).catch((error) => {
            nprogress.done()
            toast.error(error.response.data.message)
            console.log(error.response)
        })
    }

    return (
        <>
            <button disabled={false} onClick={verify} class={`${false ? 'opacity-50 bg-neutral-900' : 'bg-neutral-800'} inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90`}>
                <VerifyPaymentIcon className="w-6 h-6" />
            </button>
            <Modal size="4xl" isOpen={isOpen} onClose={closeModal} title={`${lang.payment_detail}`} content={<Content data={paymentDetail} close={closeModal} />} />
        </>
    )
}

export default PaymentDetail