import { useEffect, useLayoutEffect, useState } from "react"
import { Link, useLoaderData, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { motion } from "framer-motion"
import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import moment from "moment/moment"
import { useAuth } from "contexts/auth-context"
import { lang } from "config"
import { List as LogicList } from "pages/e-statement/logic"
import { PuzzleIcon, XIcon } from "components/icons"
import { SelectEStatement, SelectReference } from "pages/e-statement/select"
import axios from "axios"
import { toast } from "react-hot-toast"
import InformationTooltip from "./information-tooltip"
import ErrorMessage from "components/forms/error-message"
import { random } from "lodash"

const Match = ({ title }) => {
    // Logged in user ability
    const { can, isSuper, currentBranch } = useAuth()

    // React router params hook
    const number = useParams().number
    // React router navigate hook
    const navigate = useNavigate()
    // React router location hook
    const location = useLocation()
    // React router loader hook
    const data = useLoaderData()

    const [prevRouteState, setPrevRouteState] = useState({})

    useLayoutEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }
        getPrevRouteState()
    }, [])

    // Query parameters
    const [searchParams, setSearchParams] = useSearchParams()

    const page = searchParams.get('page')
    const search = searchParams.get('search')
    const from = searchParams.get('from')
    const to = searchParams.get('to')

    const [referenceData, setReferenceData] = useState([])
    const [eStatementData, setEStatementData] = useState([])
    const [resultData, setResultData] = useState([])

    const [referenceSubtotal, setReferenceSubtotal] = useState(0)
    const [eStatementSubtotal, setEStatementSubtotal] = useState(0)

    const [filteredReferenceData, setFilteredReferenceData] = useState([])
    const [filteredEStatementData, setFilteredEStatementData] = useState([])
    const [filteredResultData, setFilteredResultData] = useState([])

    const [searchReference, setSearchReference] = useState()
    const [searchEStatement, setSearchEStatement] = useState()
    const [searchResult, setSearchResult] = useState()

    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (searchReference) {
            setFilteredReferenceData(referenceData.filter((row) => row.transaction_number.toLowerCase().includes(searchReference.toLowerCase())))
        } else {
            setFilteredReferenceData(referenceData)
        }
    }, [searchReference])

    useEffect(() => {
        if (searchEStatement) {
            setFilteredEStatementData(eStatementData.filter((row) => row.information.toLowerCase().includes(searchEStatement.toLowerCase()) || row.number.toLowerCase().includes(searchEStatement.toLowerCase())))
        } else {
            setFilteredEStatementData(eStatementData)
        }
    }, [searchEStatement])

    useEffect(() => {
        setFilteredReferenceData(referenceData)
        setSearchReference()

        var total = 0

        referenceData.forEach((row) => {
            if (parseInt(row.transaction_type) === 0) {
                total += parseFloat(row.grand_total)
            } else if (parseInt(row.transaction_type) === 1) {
                total -= parseFloat(row.grand_total)
            }
        })

        setReferenceSubtotal(total)
    }, [referenceData])

    useEffect(() => {
        setFilteredEStatementData(eStatementData)
        setSearchEStatement()

        var total = 0

        eStatementData.forEach((row) => {
            if (row.transaction_type === 'cr') {
                total += parseFloat(row.amount)
            } else if (row.transaction_type === 'db') {
                total -= parseFloat(row.amount)
            }
        })

        setEStatementSubtotal(total)
    }, [eStatementData])

    useEffect(() => {
        setFilteredResultData(resultData)
        setSearchResult()
    }, [resultData])

    // Watch changes on petty cash data and loading state
    // useEffect(() => {
    //     if (search === "") {
    //         navigate(``, { replace: true })
    //     }

    //     if (isLoading) {
    //         nProgress.start()
    //     } else {
    //         nProgress.done()
    //     }
    // }, [pettyCashes, isLoading])

    // Auto match handler
    const autoMatch = () => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/match/auto`, {
            number
        }).then((response) => {
            if (response.status === 200) {
                var matchedResult = resultData
                var results = []

                if (response.data.results.length) {
                    response.data.results.map((row) => {
                        const result = {
                            e_statement: row.e_statement,
                            reference: row.reference,
                            method: 'auto'
                        }

                        results.push(result)
                    })
                }

                if (!matchedResult.find((row) => row.method === 'auto')) {
                    matchedResult.push(...results)
                } else {
                    return toast.error("Automated data already exists!")
                }

                setReferenceData([])
                setEStatementData([])
                setResultData(matchedResult)

                toast.success(response.data.message)
            } else if (response.status === 204) {
                toast.error(lang.no_data, {
                    ariaProps: {
                        superscript: response.status
                    }
                })
            }
        }).catch((error) => {
            toast.error(error.response.data.message, {
                ariaProps: {
                    superscript: error.response.status
                }
            })
        })
    }

    // Match handler
    const match = () => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/match/validate`, {
            e_statement: {
                subtotal: eStatementSubtotal,
                data: eStatementData
            },
            reference: {
                subtotal: referenceSubtotal,
                data: referenceData
            }
        }).then((response) => {
            var matchedResult = resultData

            const result = {
                e_statement: eStatementData,
                reference: referenceData,
                method: 'manual'
            }

            matchedResult.push(result)

            setReferenceData([])
            setEStatementData([])
            setResultData(matchedResult)

            toast.success(response.data.message)
        }).catch((error) => {
            toast.error(error.response.data.message, {
                ariaProps: {
                    superscript: error.response.status
                }
            })

            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/match`, {
            data: resultData.filter((row) => !row.is_saved)
        }).then((response) => {
            navigate('/e-statement')

            toast.success(response.data.message)
        }).catch((error) => {
            toast.error(error.response.data.message !== '' ? error.response.data.message : error.response.status)

            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
        })
    }

    // Data removal handlers
    const removeReference = (data) => {
        setReferenceData(referenceData.filter((row) => row.transaction_number !== data.transaction_number))
    }

    const removeEStatement = (data) => {
        setEStatementData(eStatementData.filter((row) => row.number !== data.number))
    }

    return (
        <AppLayout title={`Match ${number}`}>
            <div className="p-4 space-y-16 lg:p-8">
                <div>
                    {prevRouteState?.back && (
                        <button>
                            <Link to={location.state?.from} state={{ back: null, from: location.pathname, transition: 'slide' }} className="transition hover:opacity-50">
                                <motion.h3 layout transition={{ type: "spring", damping: 20 }} layoutId={prevRouteState?.back} className="text-sm">{prevRouteState?.back}</motion.h3>
                            </Link>
                        </button>
                    )}
                    <Transition type="slide" reversed>
                        <h1 className="text-3xl font-medium">
                            {`Match ${number}`}
                        </h1>
                    </Transition>
                </div>
                <Transition type="slide" reversed>
                    <div className="space-y-6">
                        <div className="flex items-center justify-end text-xs">
                            <div className="flex items-center space-x-4">
                                <div>
                                    <LogicList errors={errors.logic} onSubmit={() => { }} onRemove={() => { }} />
                                </div>
                                <div>
                                    <button onClick={() => autoMatch()} className="inline-flex items-center px-4 py-3 space-x-2 transition border border-neutral-200 bg-neutral-50 rounded-xl active:hover:scale-90">
                                        <PuzzleIcon className="w-4 h-4" />
                                        <span>Auto</span>
                                    </button>
                                </div>
                                <div>
                                    <button onClick={() => match()} className="inline-flex items-center px-4 py-3 space-x-2 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                                        <PuzzleIcon className="w-4 h-4" />
                                        <span>Match</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                            <div className={`${errors['reference.data'] || errors['reference.subtotal'] ? 'border-red-200' : 'border-neutral-200'} p-4 pt-2 space-y-6 border rounded-xl h-max`}>
                                <span className="text-xs text-neutral-500">{lang.references}</span>
                                <div className="flex items-center justify-between text-xs">
                                    <div className="flex items-center space-x-2">
                                        <div className="relative">
                                            <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <circle cx={10} cy={10} r={7}></circle>
                                                    <line x1={21} y1={21} x2={15} y2={15}></line>
                                                </svg>
                                            </div>
                                            <input onChange={(e) => setSearchReference(e.target.value)} value={searchReference} type="text" placeholder={`${lang.search} ${lang.references}`} autoComplete="off" className="w-64 py-3 pl-8 pr-4 text-xs transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200" />
                                        </div>
                                        {/* <Filter onSubmit={updateFilter} onRemove={removeFilter} data={Object.fromEntries(Object.entries({ transactionNumber, transactionType, currency, amount, from, to }).filter(([_, v]) => v != null))} /> */}
                                    </div>
                                    <SelectReference accountNumber={data.number} selected={referenceData} onSelect={(value) => setReferenceData(value)} error={false} />
                                </div>
                                <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                                    <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                        <thead className="bg-neutral-50 rounded-t-3xl">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction}</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.reference_type}</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_type}</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.amount}</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.date}</th>
                                                <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-neutral-200">
                                            {/* When there are no list available */}
                                            {filteredReferenceData.length === 0 && !searchReference && (
                                                <tr className="text-center">
                                                    <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {lang.no_data}
                                                    </td>
                                                </tr>
                                            )}

                                            {/* When there are no list available on searching */}
                                            {filteredReferenceData.length === 0 && searchReference && (
                                                <tr className="text-center">
                                                    <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {lang.no_result}
                                                    </td>
                                                </tr>
                                            )}

                                            {filteredReferenceData.length > 0 && filteredReferenceData.map(row => (
                                                <tr>
                                                    <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                        {row.transaction_number}
                                                    </td>
                                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap capitalize">
                                                        {row.data_type.replace('_', ' ')}
                                                    </td>
                                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {row.transaction_type === 2 ? lang.internal_transfer : (row.transaction_type === 1 ? lang.expense : lang.income)}
                                                    </td>
                                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {Intl.NumberFormat('id-Id', { style: 'currency', currency: row.currency }).format(row.grand_total)}
                                                    </td>
                                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {moment(row.created_at).format('MMMM D, YYYY')}
                                                    </td>
                                                    <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                        <button onClick={() => removeReference(row)} class="bg-red-800 inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90">
                                                            <XIcon className="w-6 h-6" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <span className="text-xs text-neutral-500">{`Total Amount: ${Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(referenceSubtotal)}`}</span>
                                </div>
                                <ErrorMessage error={errors['reference.data'] ?? errors['reference.subtotal']} />
                            </div>

                            <div className={`${errors['e_statement.data'] || errors['e_statement.subtotal'] ? 'border-red-200' : 'border-neutral-200'} p-4 pt-2 space-y-6 border rounded-xl h-max`}>
                                <span className="text-xs text-neutral-500">{lang.e_statement}</span>
                                <div className="flex items-center justify-between text-xs">
                                    <div className="flex items-center space-x-2">
                                        <div className="relative">
                                            <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <circle cx={10} cy={10} r={7}></circle>
                                                    <line x1={21} y1={21} x2={15} y2={15}></line>
                                                </svg>
                                            </div>
                                            <input onChange={(e) => setSearchEStatement(e.target.value)} value={searchEStatement} type="text" placeholder={`${lang.search} ${lang.e_statement}`} autoComplete="off" className="w-64 py-3 pl-8 pr-4 text-xs transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200" />
                                        </div>
                                    </div>
                                    <SelectEStatement batch={number} selected={eStatementData} onSelect={(value) => setEStatementData(value)} error={false} />
                                </div>
                                <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                                    <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                        <thead className="bg-neutral-50 rounded-t-3xl">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.number}</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.description}</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_type}</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.amount}</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.date}</th>
                                                <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-neutral-200">
                                            {/* When there are no list available */}
                                            {filteredEStatementData.length === 0 && !searchEStatement && (
                                                <tr className="text-center">
                                                    <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {lang.no_data}
                                                    </td>
                                                </tr>
                                            )}

                                            {/* When there are no list available on searching */}
                                            {filteredEStatementData.length === 0 && searchEStatement && (
                                                <tr className="text-center">
                                                    <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {lang.no_result}
                                                    </td>
                                                </tr>
                                            )}
                                            {filteredEStatementData.length > 0 && filteredEStatementData.map((row) => (
                                                <tr>
                                                    <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                        {row.number}
                                                    </td>
                                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        <InformationTooltip data={row.information} position="left" />
                                                    </td>
                                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {row.transaction_type === 'cr' ? lang.credit : lang.debit}
                                                    </td>
                                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {`${row.e_statement.currency} ${Intl.NumberFormat('id-Id').format(row.amount)}`}
                                                    </td>
                                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {moment(row.transaction_date).format('MMMM D, YYYY')}
                                                    </td>
                                                    <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                        <button onClick={() => removeEStatement(row)} class="bg-red-800 inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90">
                                                            <XIcon className="w-6 h-6" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <span className="text-xs text-neutral-500">{`Total Amount: ${Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(eStatementSubtotal)}`}</span>
                                </div>
                                <ErrorMessage error={errors['e_statement.data'] ?? errors['e_statement.subtotal']} />
                            </div>
                        </div>

                        <div className={`${errors.data ? 'border-red-200' : 'border-neutral-200'} p-4 pt-2 space-y-6 border rounded-xl h-max`}>
                            <span className="text-xs text-neutral-500">{lang.result}</span>
                            <div className="flex items-center justify-between text-xs">
                                <div className="flex items-center space-x-2">
                                    <div className="relative">
                                        <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <circle cx={10} cy={10} r={7}></circle>
                                                <line x1={21} y1={21} x2={15} y2={15}></line>
                                            </svg>
                                        </div>
                                        <input onChange={(e) => setSearchResult(e.target.value)} value={searchResult} type="text" placeholder={`${lang.search} ${lang.result}`} autoComplete="off" className="w-64 py-3 pl-8 pr-4 text-xs transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200" />
                                    </div>
                                    {/* <Filter onSubmit={updateFilter} onRemove={removeFilter} data={Object.fromEntries(Object.entries({ transactionNumber, transactionType, currency, amount, from, to }).filter(([_, v]) => v != null))} /> */}
                                </div>
                            </div>
                            <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                                <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                    <thead className="bg-neutral-50 rounded-t-3xl">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.references}</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.e_statement}</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.method}</th>
                                            <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-neutral-200">
                                        {/* When there are no list available */}
                                        {filteredResultData.length === 0 && !searchResult && (
                                            <tr className="text-center">
                                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    {lang.no_data}
                                                </td>
                                            </tr>
                                        )}

                                        {/* When there are no list available on searching */}
                                        {filteredResultData.length === 0 && searchResult && (
                                            <tr className="text-center">
                                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    {lang.no_result}
                                                </td>
                                            </tr>
                                        )}
                                        {filteredResultData.length > 0 && filteredResultData.map((row) => (
                                            <tr>
                                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                    {row.reference.map((data, index) => <>{`${data.transaction_number} ${index === (row.reference.length - 1) ? '' : ', '}`}</>)}
                                                </td>
                                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                    {row.e_statement.map((data, index) => (<>{`${data.number} ${index === (row.e_statement.length - 1) ? '' : ', '}`}</>))}
                                                </td>
                                                <td class="capitalize px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    {row.method}
                                                </td>
                                                <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                    {/* <button class="bg-red-800 inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90">
                                                        <XIcon className="w-6 h-6" />
                                                    </button> */}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <ErrorMessage error={errors.data} />
                            <div className="flex justify-end text-xs">
                                <button type="button" onClick={handleSubmit} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                                    <span>{lang.submit}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </AppLayout>
    )
}

export default Match