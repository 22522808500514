import { useState } from 'react'
import { Modal } from 'components'
import axios from 'axios'
import nProgress from 'nprogress'
import toast from 'react-hot-toast'
import { checkDue } from 'helpers/date-helper'
import { lang } from 'config'
import { TrashIcon } from 'components/icons'

const Content = ({ data, success }) => {
    const handleSubmit = (e) => {
        e.preventDefault()
        nProgress.start()

        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/e-statement/destroy`, {
            data: {
                batch: data?.batch
            }
        }).then((response) => {
            success()
            toast.success(response.data.message)
        }).catch(error => {
            toast.error(error.response.data.message)
            console.log(error)
            nProgress.done()
        })
    }

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            {lang.are_you_sure_you_want_to_delete} <span className="inline-flex px-3 py-1 text-sm font-medium rounded-xl bg-neutral-200 text-neutral-600">{data?.batch}</span> ?
            <div className="text-xs">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.delete}</span>
                </button>
            </div>
        </form>
    )
}

const Delete = ({ data, onSuccess }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSuccess = () => {
        closeModal()
        onSuccess()
    }

    return (
        <>
            <button disabled={false} onClick={openModal} class={`${false ? 'opacity-50 bg-red-900' : 'bg-red-800'} inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90`}>
                <TrashIcon className="w-6 h-6" />
            </button>
            <Modal isOpen={isOpen} onClose={closeModal} title={`${lang.delete} ${lang.data}`} content={<Content data={data} success={handleSuccess} />} />
        </>
    )
}

export default Delete