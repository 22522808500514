import { useEffect } from "react"
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { motion } from "framer-motion"
import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import moment from "moment/moment"
import nProgress from "nprogress"
import { useAuth } from "contexts/auth-context"
import { Pagination } from "components"
import { lang } from "config"
import Upload from "pages/e-statement/upload"
import useEStatements from "repositories/e-statement"
import { PuzzleIcon, RefreshIcon } from "components/icons"
import { Delete } from "."
import axios from "axios"
import { toast } from "react-hot-toast"

const List = ({ title }) => {
    // Logged in user ability
    const { can, isSuper, currentBranch } = useAuth()

    // React router navigate hook
    const navigate = useNavigate()
    // React router location hook
    const location = useLocation()

    // Query parameters
    const [searchParams, setSearchParams] = useSearchParams()

    const page = searchParams.get('page')
    const search = searchParams.get('search')
    const from = searchParams.get('from')
    const to = searchParams.get('to')

    // E-Statement data
    const { data: eStatements, isLoading: isLoadingEStatement, mutate: mutateEStatement } = useEStatements({
        page,
        search,
        from,
        to
    })

    // Watch changes on petty cash data and loading state
    useEffect(() => {
        if (search === "") {
            navigate(``, { replace: true })
        }

        if (isLoadingEStatement) {
            nProgress.start()
        } else {
            nProgress.done()
        }
    }, [eStatements])

    // Data filter handlers
    const updateEStatement = () => {
        const call = axios.post(`${process.env.REACT_APP_BACKEND_URL}/e-statement/update`)
            .then((response) => {
                mutateEStatement()

                return response
            })

        toast.promise(call, {
            loading: lang.updating_data,
            success: (response) => response.data.message,
            error: (error) => error.response.data.message
        })
    }

    return (
        <AppLayout title={title}>
            <div className="p-4 space-y-16 lg:p-8">
                <div>
                    <button className="cursor-default">
                        <motion.h1 layout transition={{ type: "spring", damping: 20 }} layoutId={title} className="text-3xl font-medium">
                            {title}
                        </motion.h1>
                    </button>
                </div>
                <Transition>
                    <div className="space-y-6">
                        <div className="flex items-center justify-between text-xs">
                            <div className="flex items-center sm:space-x-2">
                                <div className="relative hidden sm:block">
                                    <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <circle cx={10} cy={10} r={7}></circle>
                                            <line x1={21} y1={21} x2={15} y2={15}></line>
                                        </svg>
                                    </div>
                                    <input onChange={(e) => setSearchParams({ search: e.target.value })} value={searchParams.get('search')} type="text" placeholder={`${lang.search} ${lang.e_statement}`} autoComplete="off" className="w-64 py-3 pl-8 pr-4 text-xs transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200" />
                                </div>
                            </div>

                            <div className="flex items-center space-x-4">
                                <button onClick={() => updateEStatement()} className="inline-flex items-center px-4 py-3 space-x-2 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                                    <RefreshIcon className="w-4 h-4" stroke={1.5} />
                                    <span>Update Data</span>
                                </button>
                                {can('e-statement.upload') && (
                                    <Upload success={mutateEStatement} />
                                )}
                            </div>
                        </div>
                        <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                            <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                <thead className="bg-neutral-50 rounded-t-3xl">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.batch}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.bank}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.account_number}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.name}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.from}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.to}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.uploaded_at}</th>
                                        <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-neutral-200">
                                    {/* When loading */}
                                    {isLoadingEStatement && (
                                        <tr className="text-center">
                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.loading_data}
                                            </td>
                                        </tr>
                                    )}

                                    {/* When there are no list available */}
                                    {eStatements?.data.length === 0 && !search && !isLoadingEStatement && (
                                        <tr className="text-center">
                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.no_data}
                                            </td>
                                        </tr>
                                    )}

                                    {/* When there are no list available on searching */}
                                    {eStatements?.data.length === 0 && search && !isLoadingEStatement && (
                                        <tr className="text-center">
                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.no_result}
                                            </td>
                                        </tr>
                                    )}
                                    {eStatements?.data.length > 0 && eStatements.data.map((row) => (
                                        <tr>
                                            <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                {row.batch}
                                            </td>
                                            <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {row.bank ?? "-"}
                                            </td>
                                            <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {row.number}
                                            </td>
                                            <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {row.name ?? "-"}
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {moment(row.from).format('MMMM D, YYYY')}
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {moment(row.to).format('MMMM D, YYYY')}
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {moment(row.created_at).format('MMMM D, YYYY')}
                                            </td>
                                            <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                <div class="inline-flex items-center space-x-2">
                                                    <Link to={`${row.batch}`} state={{ back: title, from: location.pathname, transition: 'slide' }} class="inline-flex items-center p-1 text-white transition bg-neutral-800 rounded-full active:hover:scale-90">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <circle cx={12} cy={12} r={2}></circle>
                                                            <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                                                        </svg>
                                                    </Link>

                                                    {can('e-statement.match') && (
                                                        <Link to={`${row.batch}/match`} state={{ back: title, from: location.pathname, transition: 'slide' }} class="inline-flex items-center p-1 text-white transition bg-neutral-800 rounded-full active:hover:scale-90">
                                                            <PuzzleIcon className="w-6 h-6" />
                                                        </Link>
                                                    )}

                                                    <Delete data={row} onSuccess={mutateEStatement} />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination links={eStatements?.links} from={eStatements?.from} to={eStatements?.to} total={eStatements?.total} />
                    </div>
                </Transition>
            </div>
        </AppLayout>
    )
}

export default List