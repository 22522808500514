const Divider = ({ content, className, action = <></> }) => {
    return (
        <div className="flex items-center space-x-4">
            <div className={`text-xs ${className}`}>
                {content}
            </div>
            <div className="flex-grow border-t border-neutral-900"></div>
            {action}
        </div>
    )
}

export default Divider