import { useEffect, useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { motion } from "framer-motion"
import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import axios from 'axios'
import moment from "moment/moment"
import Edit from "pages/role-user-branch/edit"
import Delete from "pages/role-user-branch/delete"
import Filter from "pages/role-user-branch/filter"
import nProgress from "nprogress"
import { Pagination } from "components"
import { lang } from "config"
import Create from "./role-user-branch/create"
import useRoleUserBranchMaps from "repositories/role-user-branch"

const RoleUserBranch = (props) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()

    const page = searchParams.get('page')
    const search = searchParams.get('search')
    const user = searchParams.get('user')
    const role = searchParams.get('role')
    const branch = searchParams.get('branch')

    const { data: dataMap, isLoading, mutate } = useRoleUserBranchMaps({
        page,
        search,
        user,
        role,
        branch
    })

    // Watch changes on data map data and loading state
    useEffect(() => {
        if (search === "") {
            navigate(``, { replace: true })
        }

        if (isLoading) {
            nProgress.start()
        } else {
            nProgress.done()
        }
    }, [dataMap, isLoading])

    // Data filter handlers
    const updateFilter = (value) => {
        setSearchParams(value)
    }

    const removeFilter = () => {
        setSearchParams({})
    }

    return (
        <AppLayout title={props.title}>
            <div className="p-4 space-y-16 lg:p-8">
                <div>
                    <button className="cursor-default">
                        <motion.h1 layout transition={{ duration: .5, type: "tween" }} layoutId={props.title} className="text-3xl font-medium text-left">
                            {props.title}
                        </motion.h1>
                    </button>
                </div>
                <Transition>
                    <div className="space-y-6">
                        <div className="flex items-center justify-between text-xs">
                            <div className="flex items-center sm:space-x-2">
                                <div className="relative hidden sm:block">
                                    <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <circle cx={10} cy={10} r={7}></circle>
                                            <line x1={21} y1={21} x2={15} y2={15}></line>
                                        </svg>
                                    </div>
                                    <input onChange={(e) => setSearchParams({ search: e.target.value })} value={searchParams.get('search')} type="text" placeholder={`${lang.search} ${lang.role_user_branch}`} autoComplete="off" className="w-64 py-3 pl-8 pr-4 text-xs transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200" />
                                </div>
                                <Filter onSubmit={updateFilter} onReset={removeFilter} data={Object.fromEntries(Object.entries({ user, role, branch }).filter(([_, v]) => v != null))} />
                            </div>
                            <div>
                                <Create onSuccess={mutate} />
                            </div>
                        </div>
                        <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                            <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                <thead className="bg-neutral-50 rounded-t-3xl">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.id}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.user}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.role}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.branch}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.created_at}</th>
                                        <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-neutral-200">
                                    {/* When loading */}
                                    {isLoading && (
                                        <tr className="text-center">
                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.loading_data}
                                            </td>
                                        </tr>
                                    )}

                                    {/* When there are no list available */}
                                    {dataMap?.data.length === 0 && !search && !isLoading && (
                                        <tr className="text-center">
                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.no_data}
                                            </td>
                                        </tr>
                                    )}

                                    {/* When there are no list available on searching */}
                                    {dataMap?.data.length === 0 && search && !isLoading && (
                                        <tr className="text-center">
                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.no_result}
                                            </td>
                                        </tr>
                                    )}
                                    {dataMap?.data.length > 0 && dataMap.data.map(row => (
                                        <tr key={row.id}>
                                            <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                {row.id}
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {row.user.name}
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {row.role.name}
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {row.branch ? row.branch.name : '-'}
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {moment(row.created_at).format('MMMM D, YYYY')}
                                            </td>
                                            <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                <div class="inline-flex items-center space-x-2">
                                                    <Edit data={row} onSuccess={mutate} />

                                                    <Delete data={row} onSuccess={mutate} />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination links={dataMap?.links} from={dataMap?.from} to={dataMap?.to} total={dataMap?.total} />
                    </div>
                </Transition>
            </div>
        </AppLayout>
    )
}

export default RoleUserBranch