import { useEffect, useLayoutEffect, useState } from "react"
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { motion } from "framer-motion"
import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import moment from "moment/moment"
import nProgress from "nprogress"
import { useAuth } from "contexts/auth-context"
import usePettyCashes from "repositories/petty-cash"
import { Pagination } from "components"
import { lang } from "config"
import Label from "components/forms/label"
import InputDate from "components/forms/input-date"
import axios from "axios"
import { upperFirst } from "lodash"
import reactElementToJSXString from "react-element-to-jsx-string"
import useReports from "repositories/report"
import { LoaderIcon } from "components/icons"
import { usePage } from "contexts/page-context"
import toast from "react-hot-toast"

const CashFlow = ({ title }) => {
    // Logged in user ability
    const { user, can, isSuper, currentBranch } = useAuth()
    // Page context
    const { configuration } = usePage()

    // React router navigate hook
    const navigate = useNavigate()
    // React router location hook
    const location = useLocation()
    // React router loader hook
    // const data = useLoaderData()

    const [prevRouteState, setPrevRouteState] = useState({})

    // useLayoutEffect(() => {
    //     const getPrevRouteState = async () => {
    //         setPrevRouteState({
    //             back: location.state?.back,
    //             from: location.state?.from,
    //             transition: location.state?.transition
    //         })
    //     }
    //     getPrevRouteState()
    // }, [])

    // Query parameters
    const [searchParams, setSearchParams] = useSearchParams()

    const [from, setFrom] = useState(searchParams.get('from'))
    const [to, setTo] = useState(searchParams.get('to'))

    const page = searchParams.get('page')
    const search = searchParams.get('search')

    // Petty cash data
    const { data: cashFlows, isLoading } = useReports('cash-flow', {
        page,
        search,
        from,
        to,
    })
    const { data: notPaginatedCashFlows, isLoading: isLoadingNotPaginatedCashFlow, isValidating: isValidatingNotPaginatedCashFlow } = useReports('cash-flow', {
        page,
        search,
        from,
        to,
        status: 'settled',
        paginate: false
    })

    // Watch changes on petty cash data and loading state
    useEffect(() => {
        if (search === "") {
            navigate(``, { replace: true })
        }
    }, [cashFlows])

    const calculateXLSX = () => {
        return [
            [lang.date, lang.transaction, lang.type, lang.transaction_type, lang.debit, lang.credit, lang.balance],
            ...notPaginatedCashFlows.map((row) => {
                return {
                    date: moment(row.updated_at).format('YYYY-MM-DD HH.mm'),
                    transaction: row.transaction_number,
                    model: row.model,
                    transaction_type: row.detail?.transaction_type === 2 ? lang.internal_transfer : (row.detail?.transaction_type === 1 ? lang.expense : lang.income),
                    debit: Intl.NumberFormat('id-Id', { style: 'currency', currency: row.detail?.currency ?? "IDR" }).format(row.detail?.transaction_type === 1 ? row.detail?.grand_total : 0),
                    credit: Intl.NumberFormat('id-Id', { style: 'currency', currency: row.detail?.currency ?? "IDR" }).format(row.detail?.transaction_type === 0 ? row.detail?.grand_total : 0),
                    balance: Intl.NumberFormat('id-Id', { style: 'currency', currency: row.detail?.currency ?? "IDR" }).format(row.balance)
                }
            })
        ]
    }

    const downloadData = (type) => {
        const PDFData = () => {
            const data = []

            notPaginatedCashFlows.map((row, index) => {
                data.push(reactElementToJSXString(
                    <tr>
                        <td>{moment(row.updated_at).format('YYYY-MM-DD HH.mm')}</td>
                        <td>{row.transaction_number}</td>
                        <td>{row.model}</td>
                        <td>{row.detail?.transaction_type === 2 ? lang.internal_transfer : (row.detail?.transaction_type === 1 ? lang.expense : lang.income)}</td>
                        <td>{Intl.NumberFormat('id-Id', { style: 'currency', currency: row.detail?.currency ?? "IDR" }).format(row.detail?.transaction_type === 1 ? row.detail?.grand_total : 0)}</td>
                        <td>{Intl.NumberFormat('id-Id', { style: 'currency', currency: row.detail?.currency ?? "IDR" }).format(row.detail?.transaction_type === 0 ? row.detail?.grand_total : 0)}</td>
                        <td>{Intl.NumberFormat('id-Id', { style: 'currency', currency: row.detail?.currency ?? "IDR" }).format(row.balance)}</td>
                    </tr>
                ))
            })

            return ''.concat(data)
        }

        const payload = {
            XLSX: {
                tipe: type,
                nama_folder: title,
                data: calculateXLSX()
            },
            PDF: {
                tipe: type,
                nama_laporan: title,
                nama_folder: title,
                header: reactElementToJSXString(
                    <tr>
                        <td>{lang.date}</td>
                        <td>{lang.transaction}</td>
                        <td>{lang.type}</td>
                        <td>{lang.transaction_type}</td>
                        <td>{lang.debit}</td>
                        <td>{lang.credit}</td>
                        <td>{lang.balance}</td>
                    </tr>
                ),
                data: PDFData(),
                footer: reactElementToJSXString(<></>)
            }
        }

        axios.post(`https://guguskarangmekar.com/ApiLaporan/print-laporan/generic`, payload[type]).then((response) => {
            window.open(response.data.link)
        })
    }

    const sendData = (type) => {
        axios.post(`${process.env.REACT_APP_REPORT_API_URL}/cash-flow/download`, {
            phone: user?.phone,
            type,
            from,
            to
        }).then((response) => {
            toast.success(response.data.message)
        }).catch((error) => {
            toast.error(error.response.data.message)
        })
    }

    return (
        <AppLayout title={title}>
            <div className="p-4 space-y-16 lg:p-8">
                <div>
                    {prevRouteState?.back && (
                        <button>
                            <Link to={location.state?.from} state={{ back: null, from: location.pathname, transition: 'slide' }} className="transition hover:opacity-50">
                                <motion.h3 layout transition={{ type: "spring", damping: 20 }} layoutId={prevRouteState?.back} className="text-sm">{prevRouteState?.back}</motion.h3>
                            </Link>
                        </button>
                    )}
                    <Transition>
                        <h1 className="text-3xl font-medium">
                            {title}
                        </h1>
                    </Transition>
                </div>
                <Transition>
                    <div className="space-y-6">
                        <div className="items-center justify-between space-y-4 text-xs sm:space-y-0 sm:flex">
                            <div className="items-center space-y-2 sm:space-x-2 sm:space-y-0 sm:flex">
                                <div>
                                    <Label htmlFor="from" value={lang.from} />
                                    <InputDate id="from" maxDate={Date.parse(to)} onChange={(value) => setFrom(moment(value).format('Y-M-DD'))} value={Date.parse(from)} selected={Date.parse(from)} />
                                </div>
                                <div>
                                    <Label htmlFor="to" value={lang.to} />
                                    <InputDate id="to" minDate={Date.parse(from)} onChange={(value) => setTo(moment(value).format('Y-M-DD'))} value={Date.parse(to)} selected={Date.parse(to)} />
                                </div>
                            </div>
                            <div className="flex items-center justify-between gap-2 sm:justify-normal">
                                <button onClick={() => sendData("xlsx")} className={`bg-neutral-800 inline-flex items-center px-4 py-3 space-x-2 text-white transition rounded-xl active:hover:scale-90`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4"></path>
                                        <path d="M12 13l0 9"></path>
                                        <path d="M9 19l3 3l3 -3"></path>
                                    </svg>
                                    <span>Kirim XLSX</span>
                                </button>
                                {/* <button disabled={isLoadingNotPaginatedCashFlow || isValidatingNotPaginatedCashFlow} onClick={() => downloadData("XLSX")} className={`${(isLoadingNotPaginatedCashFlow || isValidatingNotPaginatedCashFlow) ? 'opacity-75' : ''} bg-neutral-800 inline-flex items-center px-4 py-3 space-x-2 text-white transition rounded-xl active:hover:scale-90`}>
                                    {isLoadingNotPaginatedCashFlow ? (
                                        <>
                                            <LoaderIcon className="w-4 h-4 animate-spin" stroke={2} />
                                            <span>Loading</span>
                                        </>
                                    ) : (isValidatingNotPaginatedCashFlow ? (
                                        <>
                                            <LoaderIcon className="w-4 h-4 animate-spin" stroke={2} />
                                            <span>Updating</span>
                                        </>
                                    ) : (
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4"></path>
                                                <path d="M12 13l0 9"></path>
                                                <path d="M9 19l3 3l3 -3"></path>
                                            </svg>
                                            <span>Download XLSX</span>
                                        </>
                                    ))}
                                </button>
                                <button disabled={isLoadingNotPaginatedCashFlow || isValidatingNotPaginatedCashFlow} onClick={() => downloadData("PDF")} className={`${(isLoadingNotPaginatedCashFlow || isValidatingNotPaginatedCashFlow) ? 'opacity-75' : ''} bg-neutral-800 inline-flex items-center px-4 py-3 space-x-2 text-white transition rounded-xl active:hover:scale-90`}>
                                    {isLoadingNotPaginatedCashFlow ? (
                                        <>
                                            <LoaderIcon className="w-4 h-4 animate-spin" stroke={2} />
                                            <span>Loading</span>
                                        </>
                                    ) : (isValidatingNotPaginatedCashFlow ? (
                                        <>
                                            <LoaderIcon className="w-4 h-4 animate-spin" stroke={2} />
                                            <span>Updating</span>
                                        </>
                                    ) : (
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4"></path>
                                                <path d="M12 13l0 9"></path>
                                                <path d="M9 19l3 3l3 -3"></path>
                                            </svg>
                                            <span>Download PDF</span>
                                        </>
                                    ))}
                                </button> */}
                            </div>
                        </div>
                        <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                            <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                <thead className="bg-neutral-50 rounded-t-3xl">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.date}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.type}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_type}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.grand_total}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-center uppercase text-neutral-500 whitespace-nowrap">{lang.status}</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-neutral-200">
                                    {/* When loading */}
                                    {isLoading && (
                                        <tr className="text-center">
                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.loading_data}
                                            </td>
                                        </tr>
                                    )}

                                    {/* When there are no list available */}
                                    {cashFlows?.data.length === 0 && !search && !isLoading && (
                                        <tr className="text-center">
                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.no_data}
                                            </td>
                                        </tr>
                                    )}

                                    {/* When there are no list available on searching */}
                                    {cashFlows?.data.length === 0 && search && !isLoading && (
                                        <tr className="text-center">
                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.no_result}
                                            </td>
                                        </tr>
                                    )}

                                    {cashFlows?.data.length > 0 && cashFlows.data.map(row => (
                                        <tr>
                                            <td class="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                <span>{`${moment(row.updated_at).format('YYYY-MM-DD')}`}</span>
                                                <span className="px-2 py-1 ml-2 rounded-full bg-neutral-100">{`${moment(row.updated_at).format('HH.mm')}`}</span>
                                            </td>
                                            <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                {row.transaction_number}
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {row.type}
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {row.transaction_type === 2 ? lang.internal_transfer : (row.transaction_type === 1 ? lang.expense : lang.income)}
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(row.grand_total)}
                                            </td>
                                            <td className="px-6 py-4 text-xs whitespace-nowrap">
                                                <span className={`${configuration('status').find((data) => data.code === row.status)?.class ?? configuration('status').find((data) => data.code === 'default')?.class} w-full justify-center inline-flex px-2 text-xs font-semibold leading-5 rounded-full`}>
                                                    {configuration('status').find((data) => data.code === row.status)?.label ?? configuration('status').find((data) => data.code === 'default')?.label}
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination links={cashFlows?.links} from={cashFlows?.from} to={cashFlows?.to} total={cashFlows?.total} />
                    </div>
                </Transition>
            </div>
        </AppLayout>
    )
}

export default CashFlow