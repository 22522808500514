import { useLayoutEffect, useState } from "react"
import { Link, useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom"
import { motion } from "framer-motion"
import {
    BranchIcon,
    TrendingUpIcon,
    ArrowsDoubleNeSwIcon,
    ArrowBarToDownIcon,
    ArrowBarUpIcon,
    DollarIcon,
    CalculatorIcon,
    CalendarPlusIcon,
    CalendarIcon,
    NotesIcon,
    EyeCheckIcon,
    HistoryIcon,
    WalletIcon
} from "components/icons"

import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import Divider from "components/divider"
import axios from 'axios'
import moment from "moment/moment"
import { lang } from "config"
import ApprovalHistory from "components/approval-history"
import DestinationList from "./destination-list"
import { usePage } from "contexts/page-context"

const PettyCashDetail = () => {
    const number = useParams().number
    const navigate = useNavigate()
    const location = useLocation()

    const { configuration } = usePage()

    const [prevRouteState, setPrevRouteState] = useState({})

    const data = useLoaderData()

    useLayoutEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                transition: location.state?.transition
            })
        }

        getPrevRouteState()
    }, [])

    return (
        <AppLayout title={number}>
            <div className="p-4 space-y-16 lg:p-8">
                <div>
                    {prevRouteState?.back && (
                        <button>
                            <Link to={location.state?.from} state={{ back: null, from: location.pathname, transition: 'slide' }} className="transition hover:opacity-50">
                                <motion.h3 layout transition={{ type: "spring", damping: 20 }} layoutId={prevRouteState?.back} className="text-sm">{prevRouteState?.back}</motion.h3>
                            </Link>
                        </button>
                    )}
                    <Transition type="slide" reversed>
                        <h1 className="text-3xl font-medium">
                            {number}
                        </h1>
                    </Transition>
                </div>
                <Transition type="slide" reversed>
                    <div className="space-y-6">
                        <div className="flex items-center space-x-4">
                            <div className="p-2 rounded-xl bg-neutral-100">
                                <BranchIcon className="w-6 h-6" />
                            </div>
                            <div>
                                <div className="font-medium">{data.branch?.name}</div>
                                <div className="flex items-center space-x-2 text-xs">
                                    <div>{data.branch?.contact}</div>
                                    <div className="font-light text-neutral-200">|</div>
                                    <div>{data.branch?.address}</div>
                                </div>
                            </div>
                        </div>

                        <div className="p-6 space-y-6 text-xs border rounded-xl border-neutral-200">
                            {/* Currency and Amount */}
                            <Divider content={`${lang.currency_and_amount_detail}`} className="font-medium" />
                            <div className="flex space-x-2">
                                <div>
                                    <DollarIcon className="w-4 h-4" stroke={1.5} />
                                </div>
                                <div className="space-y-2">
                                    <div className="font-medium">{lang.currency}</div>
                                    <div>{data.currency}</div>
                                </div>
                            </div>
                            <div className="flex space-x-2">
                                <div>
                                    <TrendingUpIcon className="w-4 h-4" stroke={1.5} />
                                </div>
                                <div className="space-y-2">
                                    <div className="font-medium">{lang.exchange_rate}</div>
                                    <div>{data.rate}</div>
                                </div>
                            </div>
                            <div className="flex space-x-2">
                                <div>
                                    <CalculatorIcon className="w-4 h-4" stroke={1.5} />
                                </div>
                                <div className="space-y-2">
                                    <div className="font-medium">{lang.grand_total}</div>
                                    <div>{Intl.NumberFormat('id-Id', { style: 'currency', currency: data.currency }).format(data.grand_total)}</div>
                                </div>
                            </div>

                            {/* Other */}
                            <Divider content={lang.other} className="font-medium" />
                            <div className="flex space-x-2">
                                <div>
                                    <ArrowsDoubleNeSwIcon className="w-4 h-4" stroke={1.5} />
                                </div>
                                <div className="space-y-2">
                                    <div className="font-medium">{lang.transaction_type}</div>
                                    <div>{data.transaction_type === 2 ? lang.internal_transfer : (data.transaction_type === 1 ? lang.expense : lang.income)}</div>
                                </div>
                            </div>
                            <div className="flex space-x-2">
                                <div>
                                    <ArrowBarToDownIcon className="w-4 h-4" stroke={1.5} />
                                </div>
                                <div className="space-y-2">
                                    <div className="font-medium">{lang.destination}</div>
                                    <div><DestinationList onChange={() => { }} data={data.details} enableAction={false} /></div>
                                </div>
                            </div>
                            {data.source !== null && (
                                <div className="flex space-x-2">
                                    <div>
                                        <ArrowBarUpIcon className="w-4 h-4" stroke={1.5} />
                                    </div>
                                    <div className="space-y-2">
                                        <div className="font-medium">{lang.source}</div>
                                        <div>{data.source_coa?.description}</div>
                                        <div>{data.source_coa?.number}</div>
                                    </div>
                                </div>
                            )}
                            <div className="flex space-x-2">
                                <div>
                                    <EyeCheckIcon className="w-4 h-4" stroke={1.5} />
                                </div>
                                <div className="space-y-2">
                                    <div className="font-medium">{lang.status}</div>
                                    <div className="capitalize">{configuration('status').find((row) => row.code === data.status)?.label ?? configuration('status').find((row) => row.code === 'default')?.label}</div>
                                </div>
                            </div>
                            <div className="flex space-x-2">
                                <div>
                                    <HistoryIcon className="w-4 h-4" stroke={1.5} />
                                </div>
                                <div className="space-y-2">
                                    <div className="font-medium">{lang.approval_histories}</div>
                                    <div>
                                        <ApprovalHistory data={data.approval_histories} />
                                    </div>
                                </div>
                            </div>

                            {/* Timestamp */}
                            <Divider content="Timestamp" className="font-medium" />
                            <div className="flex space-x-2">
                                <div>
                                    <CalendarPlusIcon className="w-4 h-4" stroke={1.5} />
                                </div>
                                <div className="space-y-2">
                                    <div className="font-medium">{lang.created_at}</div>
                                    <div>{`${moment(data.created_at).format('MMMM DD, YYYY')} ${lang.at} ${moment(data.created_at).format('HH.mm')}`}</div>
                                </div>
                            </div>
                            <div className="flex space-x-2">
                                <div>
                                    <CalendarPlusIcon className="w-4 h-4" stroke={1.5} />
                                </div>
                                <div className="space-y-2">
                                    <div className="font-medium">{lang.created_by}</div>
                                    <div>{data.created_by}</div>
                                </div>
                            </div>
                            {data.updated_by && (
                                <>
                                    <div className="flex space-x-2">
                                        <div>
                                            <CalendarIcon className="w-4 h-4" stroke={1.5} />
                                        </div>
                                        <div className="space-y-2">
                                            <div className="font-medium">{lang.updated_at}</div>
                                            <div>{moment(data.updated_at).format('MMMM DD, YYYY')}</div>
                                        </div>
                                    </div>
                                    <div className="flex space-x-2">
                                        <div>
                                            <CalendarIcon className="w-4 h-4" stroke={1.5} />
                                        </div>
                                        <div className="space-y-2">
                                            <div className="font-medium">{lang.updated_by}</div>
                                            <div>{data.updated_by}</div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {/* Note */}
                            {data.note && (
                                <>
                                    <Divider content={lang.note} className="font-medium" />
                                    <div className="flex space-x-2">
                                        <div>
                                            <NotesIcon className="w-4 h-4" stroke={1.5} />
                                        </div>
                                        <div className="space-y-2">
                                            <div className="font-medium">{lang.note}</div>
                                            <div>
                                                {data.note}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </Transition>
            </div>
        </AppLayout>
    )
}

export default PettyCashDetail