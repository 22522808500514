import { useLayoutEffect, useState } from "react"
import { useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom"
import { motion } from "framer-motion"

import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import axios from "axios"
import Form from "./form"
import { useAuth } from "contexts/auth-context"
import { pluck } from "helpers/array-helper"
import { lang } from "config"
import nProgress from "nprogress"
import { toast } from "react-hot-toast"

const EditRole = () => {
    const { user } = useAuth()

    const location = useLocation()
    const navigate = useNavigate()

    const roleData = useLoaderData()

    const [prevRouteState, setPrevRouteState] = useState({})

    const [errors, setErrors] = useState({})

    useLayoutEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }

        getPrevRouteState()
    }, [])

    const handleSubmit = (data) => {
        nProgress.start()

        axios.patch(`${process.env.REACT_APP_GLOBAL_API_URL}/role/update`, {
            id: roleData.id,
            application_id: 2,
            name: data.name,
            permission: data.permission,
            updated_by: user?.name
        }).then((response) => {
            navigate({ pathname: '/role' })
            toast.success(response.data.message)
            nProgress.done()
        }).catch(error => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            toast.error(error.response.data.message)
            nProgress.done()
            console.log(error.response)
        })
    }

    return (
        <AppLayout title={`Edit ${roleData?.name}`}>
            <div className="p-4 lg:p-8 space-y-16">
                <div>
                    {prevRouteState?.back && (
                        <button className="transition hover:opacity-50" onClick={() => navigate(-1, { state: { transition: "slide" } })}>
                            <motion.h3 layout transition={{ duration: .5, type: "tween" }} layoutId={prevRouteState?.back} className="text-sm">{prevRouteState?.back}</motion.h3>
                        </button>
                    )}
                    <Transition reversed>
                        <h1 className="text-3xl font-medium">
                            {lang.edit} {roleData?.name}
                        </h1>
                    </Transition>
                </div>
                <Transition type="slide" reversed>
                    <Form errors={errors} data={{ name: roleData?.name, permission: pluck(roleData?.permissions, 'id') }} onSubmit={handleSubmit} />
                </Transition>
            </div>
        </AppLayout >
    )
}

export default EditRole