import { useState } from "react"
import { useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom"
import { motion } from "framer-motion"
import {
    BranchIcon,
    TrendingUpIcon,
    ArrowsDoubleNeSwIcon,
    ArrowBarToDownIcon,
    ArrowBarUpIcon,
    DollarIcon,
    CalculatorIcon,
    CalendarPlusIcon,
    CalendarIcon,
    NotesIcon,
    LayoutIcon
} from "components/icons"

import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import axios from 'axios'
import moment from "moment/moment"
import { useLayoutEffect } from "react"
import { Divider } from "components"
import { BillIcon, InvoiceIcon, PettyCashIcon, TransactionIcon, UserCircleIcon } from "components/icons"
import { lang } from "config"

const RoleDetail = () => {
    useLayoutEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                transition: location.state?.transition
            })
        }
        getPrevRouteState()
    }, [])

    const navigate = useNavigate()
    const location = useLocation()

    const [prevRouteState, setPrevRouteState] = useState({})
    const data = useLoaderData()

    return (
        <AppLayout title={data.name}>
            <div className="p-4 lg:p-8 space-y-16">
                <div>
                    {prevRouteState?.back && (
                        <button className="transition hover:opacity-50" onClick={() => navigate(-1, { state: { transition: "slide" } })}>
                            <motion.h3 layout transition={{ duration: .5, type: "tween" }} layoutId={prevRouteState?.back} className="text-sm">{prevRouteState?.back}</motion.h3>
                        </button>
                    )}
                    <Transition reversed>
                        <h1 className="text-3xl font-medium">
                            {data.name}
                        </h1>
                    </Transition>
                </div>
                <Transition reversed>
                    <div className="space-y-6">
                        <div className="p-6 space-y-6 text-xs border rounded-xl border-neutral-200">
                            {/* Permissions */}
                            {data.grouped_permissions.length > 0 && (
                                <>
                                    <Divider content={lang.assigned_permission} className="font-medium" />
                                    {data.grouped_permissions.map((permission, index) => (
                                        <>
                                            <div className="flex space-x-2">
                                                <div>
                                                    <LayoutIcon className="w-4 h-4" stroke={1.5} />
                                                </div>
                                                <div className="space-y-2">
                                                    <div className="font-medium">{permission.page}</div>
                                                    <div>
                                                        {data.permissions.filter(row => row.page === permission.page)?.map((row, index) => (
                                                            <span>{`${row.name}${data.permissions.filter(row => row.page === permission.page).length - 1 === index ? '' : ', '}`}</span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </>
                            )}

                            {/* Assigned Branches */}
                            <Divider content={lang.assigned_branch} className="font-medium" />
                            {data.branches?.length > 0 ? data.branches?.map(row => (
                                <div className="flex space-x-2">
                                    <div>
                                        <BranchIcon className="w-4 h-4" stroke={1.5} />
                                    </div>
                                    <div className="space-y-2">
                                        <div className="font-medium">{row.name}</div>
                                        <div>{row.code}</div>
                                    </div>
                                </div>
                            )) : (
                                <div>{lang.no_branch}</div>
                            )}

                            {/* Assigned Users */}
                            <Divider content={lang.assigned_users} className="font-medium" />
                            {data.users?.length > 0 ? data.users?.map(row => (
                                <div className="flex space-x-2">
                                    <div>
                                        <UserCircleIcon className="w-4 h-4" stroke={1.5} />
                                    </div>
                                    <div className="space-y-2">
                                        <div className="font-medium">{row.name}</div>
                                        <div>{row.phone}</div>
                                    </div>
                                </div>
                            )) : (
                                <div>{lang.no_user}</div>
                            )}

                            {/* Timestamps */}
                            <Divider content="Timestamps" className="font-medium" />
                            <div className="flex space-x-2">
                                <div>
                                    <CalendarPlusIcon className="w-4 h-4" stroke={1.5} />
                                </div>
                                <div className="space-y-2">
                                    <div className="font-medium">{lang.created_at}</div>
                                    <div>{moment(data.created_at).format('MMMM DD, YYYY')}</div>
                                </div>
                            </div>
                            {data.updated_at !== data.created_at && (
                                <>
                                    <div className="flex space-x-2">
                                        <div>
                                            <CalendarIcon className="w-4 h-4" stroke={1.5} />
                                        </div>
                                        <div className="space-y-2">
                                            <div className="font-medium">Updated At</div>
                                            <div>{moment(data.updated_at).format('MMMM DD, YYYY')}</div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </Transition>
            </div>
        </AppLayout>
    )
}

export default RoleDetail