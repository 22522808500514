import { Fragment, useEffect, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from 'components/icons'
import lodash from 'lodash'
import deepdash from 'deepdash'
import { lang } from 'config'

// const selection = [
//     { number: '100.110.101', name: 'Kas Kecil (IDR)' },
//     { number: '100.110.102', name: 'Kas Kecil (EUR)' },
//     { number: '100.110.103', name: 'Kas Kecil (USD)' },
// ]

export default function ChartOfAccountCombobox({ disabled, isLoading, selection, value, onChange, error }) {
    const _ = deepdash(lodash)

    const [selected, setSelected] = useState({})
    const [query, setQuery] = useState('')

    const filteredSelection =
        query === ''
            ? selection
            // : _.filter(selection, (row) => {
            //     return _.filter(row.children, (child) => {
            //         return _.filter(child.children, { number: query })
            //     })
            // })
            : _.filterDeep(selection, (data) => ((data.number.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')) || data.description.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))) && parseInt(data.level) !== 1), {
                childrenPath: 'children'
            })

    useEffect(() => {
        if (value && !isLoading) {
            const selected = _.findDeep(selection, { number: value }, { childrenPath: 'children' }).value

            setSelected(selected)
        }
    }, [isLoading])

    useEffect(() => {
        if (Object.keys(selected).length !== 0) {
            onChange(selected)
        }
    }, [selected])

    return (
        <Combobox disabled={disabled} value={selected} onChange={setSelected}>
            {({ open }) => (
                <div className="relative mt-1">
                    <div className={`${error ? 'border-red-200' : ''} ${open ? 'ring ring-neutral-200 border-neutral-400' : ''} transition relative w-full overflow-hidden text-left bg-white border cursor-default rounded-xl focus:outline-none focus-visible:ring focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-neutral-300 sm:text-sm`}>
                        <Combobox.Input className={`${disabled ? 'bg-neutral-100 opacity-70' : ''} w-full py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 border-none focus:ring-0`} displayValue={(data) => data.description} onChange={(event) => setQuery(event.target.value)} />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon stroke={1.5} className="w-5 h-5 text-gray-400" />
                        </Combobox.Button>
                    </div>
                    <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" afterLeave={() => setQuery('')}>
                        <Combobox.Options className="absolute z-50 w-full pb-2 mt-4 overflow-auto text-base bg-white border shadow-lg shadow-neutral-300 rounded-xl max-h-72 ring border-neutral-600 ring-neutral-400 focus:outline-none sm:text-sm">
                            {(filteredSelection?.length === 0 || filteredSelection === null) && query === '' && isLoading && (
                                <div className="relative px-4 pt-2 text-gray-700 cursor-default select-none">
                                    {lang.loading_data}
                                </div>
                            )}

                            {(filteredSelection?.length === 0 || filteredSelection === null) && query !== '' ? (
                                <div className="relative px-4 pt-2 text-gray-700 cursor-default select-none">
                                    {lang.no_result}
                                </div>
                            ) : (
                                filteredSelection?.map((row, index) => (
                                    <div className="pl-2">
                                        <>
                                            {row.children.length !== 0 ? (
                                                <>
                                                    {parseInt(row.level) === 1 ? (
                                                        <div className="flex items-center justify-between px-4 py-2 m-1 text-xs rounded-full cursor-default text-neutral-600">
                                                            <span>{row.description}</span>
                                                            <span>{row.number}</span>
                                                        </div>
                                                    ) : (
                                                        <Combobox.Option key={row.number} className={({ selected, active }) => `rounded-full m-1 flex items-center justify-between px-4 cursor-default py-2 text-xs ${selected && active ? 'bg-neutral-700 text-white' : ''} ${selected && !active ? 'bg-neutral-200' : ''} ${active && !selected ? 'bg-neutral-600 text-white' : ''}`} value={row}>
                                                            {({ selected, active }) => (
                                                                <>
                                                                    <span>{row.description}</span>
                                                                    <span className="flex items-center space-x-2">
                                                                        <span>{row.number}</span>
                                                                        {selected ? (
                                                                            <span className={`flex items-center justify-center p-1 ring-1 rounded-full ${active ? 'ring-white text-white' : 'ring-neutral-700 text-neutral-700'}`}>
                                                                                <CheckIcon className="w-2 h-2" stroke={1.5} />
                                                                            </span>
                                                                        ) : null}
                                                                    </span>
                                                                </>
                                                            )}
                                                        </Combobox.Option>
                                                    )}
                                                    <div className="pl-2 mb-2 ml-2 border-t border-b border-l rounded-bl-2xl rounded-tl-2xl">
                                                        {
                                                            row.children.map((child, index) => (
                                                                <>
                                                                    {child.children.length !== 0 ? (
                                                                        <>
                                                                            <Combobox.Option key={child.number} className={({ selected, active }) => `rounded-full m-1 flex items-center justify-between px-4 cursor-default py-2 text-xs ${selected && active ? 'bg-neutral-700 text-white' : ''} ${selected && !active ? 'bg-neutral-200' : ''} ${active && !selected ? 'bg-neutral-600 text-white' : ''}`} value={child}>
                                                                                {({ selected, active }) => (
                                                                                    <>
                                                                                        <span>{child.description}</span>
                                                                                        <span className="flex items-center space-x-2">
                                                                                            <span>{child.number}</span>
                                                                                            {selected ? (
                                                                                                <span className={`flex items-center justify-center p-1 ring-1 rounded-full ${active ? 'ring-white text-white' : 'ring-neutral-700 text-neutral-700'}`}>
                                                                                                    <CheckIcon className="w-2 h-2" stroke={1.5} />
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </span>
                                                                                    </>
                                                                                )}
                                                                            </Combobox.Option>
                                                                            <div className="p-2 ml-2 border-t border-b border-l rounded-bl-2xl rounded-tl-2xl">
                                                                                {
                                                                                    child.children.map((data) => (
                                                                                        <Combobox.Option key={data.number} className={({ selected, active }) => `rounded-xl relative cursor-default inline-flex items-center justify-between w-full select-none py-2 px-4 ${selected && active ? 'bg-neutral-700 text-white' : ''} ${selected && !active ? 'bg-neutral-200' : ''} ${active && !selected ? 'bg-neutral-600 text-white' : ''}`} value={data}>
                                                                                            {({ selected, active }) => (
                                                                                                <>
                                                                                                    <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                                                                                        <div>{data.description}</div>
                                                                                                        <div className="text-xs opacity-70">{data.number}</div>
                                                                                                    </span>
                                                                                                    {selected ? (
                                                                                                        <span className={`flex items-center justify-center p-1 ring-1 rounded-full ${active ? 'ring-white text-white' : 'ring-neutral-700 text-neutral-700'}`}>
                                                                                                            <CheckIcon className="w-4 h-4" stroke={1.5} />
                                                                                                        </span>
                                                                                                    ) : null}
                                                                                                </>
                                                                                            )}
                                                                                        </Combobox.Option>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <div className={`pr-2 ${(row.children.length - 1) === index && 'pb-2'} ${index === 0 && 'pt-2'}`}>
                                                                            <Combobox.Option key={child.number} className={({ selected, active }) => `rounded-xl relative cursor-default inline-flex items-center justify-between w-full select-none py-2 px-4 ${selected && active ? 'bg-neutral-700 text-white' : ''} ${selected && !active ? 'bg-neutral-200' : ''} ${active && !selected ? 'bg-neutral-600 text-white' : ''}`} value={child}>
                                                                                {({ selected, active }) => (
                                                                                    <>
                                                                                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                                                                            <div>{child.description}</div>
                                                                                            <div className="text-xs opacity-70">{child.number}</div>
                                                                                        </span>
                                                                                        {selected ? (
                                                                                            <span className={`flex items-center justify-center p-1 ring-1 rounded-full ${active ? 'ring-white text-white' : 'ring-neutral-700 text-neutral-700'}`}>
                                                                                                <CheckIcon className="w-4 h-4" stroke={1.5} />
                                                                                            </span>
                                                                                        ) : null}
                                                                                    </>
                                                                                )}
                                                                            </Combobox.Option>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ))
                                                        }
                                                    </div>
                                                </>
                                            ) : (
                                                <div className={`pr-2 ${(row.children.length - 1) === index && 'pb-2'} ${index === 0 && 'pt-2'}`}>
                                                    <Combobox.Option key={row.number} className={({ active }) => `rounded-xl relative cursor-default inline-flex items-center justify-between w-full select-none py-2 px-4 ${active ? 'bg-neutral-600 text-white' : ''}`} value={row}>
                                                        {({ selected, active }) => (
                                                            <>
                                                                <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                                                    <div>{row.description}</div>
                                                                    <div className="text-xs opacity-70">{row.number}</div>
                                                                </span>
                                                                {selected ? (
                                                                    <span className={`flex items-center justify-center p-1 ring-1 rounded-full ${active ? 'ring-white text-white' : 'ring-neutral-700 text-neutral-700'}`}>
                                                                        <CheckIcon className="w-4 h-4" stroke={1.5} />
                                                                    </span>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </Combobox.Option>
                                                </div>
                                            )}
                                        </>
                                    </div>
                                ))
                            )}
                        </Combobox.Options>
                    </Transition>
                </div>
            )}
        </Combobox >
    )
}