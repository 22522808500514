import { useEffect, useLayoutEffect, useState } from "react"
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { motion } from "framer-motion"
import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import moment from "moment/moment"
import nProgress from "nprogress"
import { useAuth } from "contexts/auth-context"
import usePettyCashes from "repositories/petty-cash"
import { Pagination } from "components"
import { lang } from "config"
import Label from "components/forms/label"
import InputDate from "components/forms/input-date"
import axios from "axios"
import { upperFirst } from "lodash"
import reactElementToJSXString from "react-element-to-jsx-string"
import useReports from "repositories/report"
import { LoaderIcon, UsersIcon } from "components/icons"
import { usePage } from "contexts/page-context"
import SupplierIcon from "components/icons/supplier"
import Filter from "pages/report/bill/filter"
import { PDFData, XLSXData } from "./bill/calculate"
import toast from "react-hot-toast"

const Bill = ({ title }) => {
    // Logged in user ability
    const { user } = useAuth()
    // Page context
    const { configuration } = usePage()

    // React router navigate hook
    const navigate = useNavigate()
    // React router location hook
    const location = useLocation()
    // React router loader hook
    // const data = useLoaderData()

    const [prevRouteState, setPrevRouteState] = useState({})

    useLayoutEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }
        getPrevRouteState()
    }, [])

    // Query parameters
    const [searchParams, setSearchParams] = useSearchParams()

    const type = searchParams.get('type')
    const transactionType = searchParams.get('transaction_type') ?? 'income'
    const history = searchParams.get('history')
    const from = searchParams.get('from')
    const to = searchParams.get('to')

    const page = searchParams.get('page')
    const search = searchParams.get('search')

    // Petty cash data
    const { data: bills, isLoading } = useReports('bill', {
        page,
        search,
        type,
        transaction_type: transactionType,
        history,
        from,
        to,
    })
    const { data: notPaginatedBills, isLoading: isLoadingNotPaginatedBill, isValidating: isValidatingNotPaginatedBill } = useReports('bill', {
        page,
        search,
        type,
        transaction_type: transactionType,
        history,
        from,
        to,
        paginate: false
    })

    // Watch changes on petty cash data and loading state
    useEffect(() => {
        if (search === "") {
            navigate(``, { replace: true })
        }
    }, [bills])

    const downloadData = (type) => {
        const payload = {
            XLSX: {
                tipe: type,
                nama_folder: title,
                data: XLSXData(notPaginatedBills, history, transactionType, configuration)
            },
            PDF: {
                tipe: type,
                nama_laporan: title,
                nama_folder: title,
                header: reactElementToJSXString(
                    <tr>
                        <td>{lang.created_date}</td>
                        <td>{lang.updated_date}</td>
                        <td>{lang.bill_date}</td>
                        {transactionType === 'income' && (<td>{lang.reference_number}</td>)}
                        <td>{lang.bill_number}</td>
                        <td>{lang.invoice_number}</td>
                        <td>{lang.customer_or_supplier}</td>
                        {transactionType === 'income' && (<td>Jenis Kendaraan</td>)}
                        <td>{lang.type}</td>
                        <td>{lang.transaction_type}</td>
                        <td>{lang.dpp}</td>
                        <td>{lang.ppn}</td>
                        <td>{lang.grand_total}</td>
                        <td>{lang.status}</td>
                    </tr>
                ),
                data: PDFData(notPaginatedBills, history, transactionType, configuration),
                footer: reactElementToJSXString(<></>)
            }
        }

        axios.post(`${process.env.REACT_APP_PRINT_API_URL}/print-laporan/generic`, payload[type]).then((response) => {
            window.open(response.data.link)
        })
    }

    const sendData = (type) => {
        axios.post(`${process.env.REACT_APP_REPORT_API_URL}/bill/download`, {
            phone: user?.phone,
            history,
            transaction_type: transactionType,
            type,
            from: from ?? null,
            to: to ?? null
        }).then((response) => {
            toast.success(response.data.message)
        }).catch((error) => {
            toast.error(error.response.data.message)
        })
    }

    // Data filter handlers
    const updateFilter = (value) => {
        setSearchParams(value)
    }

    const removeFilter = () => {
        setSearchParams({})
    }

    return (
        <AppLayout title={title}>
            <div className="p-4 space-y-16 lg:p-8">
                <div>
                    {prevRouteState?.back && (
                        <button>
                            <Link to={location.state?.from} state={{ back: null, from: location.pathname, transition: 'slide' }} className="transition hover:opacity-50">
                                <motion.h3 layout transition={{ type: "spring", damping: 20 }} layoutId={prevRouteState?.back} className="text-sm">{prevRouteState?.back}</motion.h3>
                            </Link>
                        </button>
                    )}
                    <Transition>
                        <h1 className="text-3xl font-medium">
                            {title}
                        </h1>
                    </Transition>
                </div>
                <Transition>
                    <div className="space-y-6">
                        <div className="items-center justify-between space-y-4 text-xs sm:space-y-0 sm:flex">
                            <div className="items-center space-y-2 sm:space-x-2 sm:space-y-0 sm:flex">
                                <Filter onSubmit={updateFilter} onRemove={removeFilter} data={Object.fromEntries(Object.entries({ type, transactionType, history, from, to }).filter(([_, v]) => v != null))} />
                            </div>
                            <div className="flex items-center justify-between gap-2 sm:justify-normal">
                                <button onClick={() => sendData("xlsx")} className={`bg-neutral-800 inline-flex items-center px-4 py-3 space-x-2 text-white transition rounded-xl active:hover:scale-90`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4"></path>
                                        <path d="M12 13l0 9"></path>
                                        <path d="M9 19l3 3l3 -3"></path>
                                    </svg>
                                    <span>Kirim XLSX</span>
                                </button>
                            </div>
                        </div>
                        <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                            <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                <thead className="bg-neutral-50 rounded-t-3xl">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.date}</th>
                                        {transactionType === 'income' && (<th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.reference_number}</th>)}
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.bill_number}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.invoice_number}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.customer_or_supplier}</th>
                                        {transactionType === 'income' && (<th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Jenis Kendaraan</th>)}
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.type}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_type}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.dpp}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.ppn}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.grand_total}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-center uppercase text-neutral-500 whitespace-nowrap">{lang.status}</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-neutral-200">
                                    {/* When loading */}
                                    {isLoading && (
                                        <tr className="text-center">
                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.loading_data}
                                            </td>
                                        </tr>
                                    )}

                                    {/* When there are no list available */}
                                    {bills?.data.length === 0 && !search && !isLoading && (
                                        <tr className="text-center">
                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.no_data}
                                            </td>
                                        </tr>
                                    )}

                                    {/* When there are no list available on searching */}
                                    {bills?.data.length === 0 && search && !isLoading && (
                                        <tr className="text-center">
                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.no_result}
                                            </td>
                                        </tr>
                                    )}
                                    {bills?.data.length > 0 && bills.data.map(row => (
                                        <tr>
                                            <td class="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                <span>{`${moment(row.transaction_date).format('YYYY-MM-DD')}`}</span>
                                                <span className="px-2 py-1 ml-2 rounded-full bg-neutral-100">{`${moment(row.transaction_date).format('HH.mm')}`}</span>
                                            </td>
                                            {transactionType === 'income' && (
                                                <td className="px-6 py-4 space-y-2 text-xs whitespace-nowrap">
                                                    {row.reference_number?.find((data) => data.code === 'booking_number') ? (
                                                        <>
                                                            <div className="text-neutral-500">
                                                                {row.reference_number?.find((data) => data.code === 'booking_number').label}
                                                            </div>
                                                            <div className="mt-1 font-medium text-neutral-900">
                                                                {row.reference_number?.find((data) => data.code === 'booking_number').value}
                                                            </div>
                                                        </>
                                                    ) : row.reference_number?.find((data) => data.code === 'order_number') ? (
                                                        <>
                                                            <div className="text-neutral-500">
                                                                {row.reference_number?.find((data) => data.code === 'order_number').label}
                                                            </div>
                                                            <div className="mt-1 font-medium text-neutral-900">
                                                                {row.reference_number?.find((data) => data.code === 'order_number').value}
                                                            </div>
                                                        </>
                                                    ) : row.reference_number?.map((data) => (
                                                        <>
                                                            <div className="text-neutral-500">
                                                                {data.label}
                                                            </div>
                                                            <div className="mt-1 font-medium text-neutral-900">
                                                                {data.value}
                                                            </div>
                                                        </>
                                                    ))}
                                                </td>
                                            )}
                                            <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                {row.number}
                                            </td>
                                            <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                {row.invoice_number}
                                            </td>
                                            <td class="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                <div className="flex items-center space-x-2">
                                                    <span>
                                                        {(row.type === 'po' || row.type === 'rt-po' || row.type === 'rt-adv') ? (
                                                            <SupplierIcon className="w-4 h-4" stroke={1.5} />
                                                        ) : (
                                                            <UsersIcon className="w-4 h-4" stroke={1.5} />
                                                        )}
                                                    </span>
                                                    <span>
                                                        {row.payor_or_payee_name}
                                                    </span>
                                                </div>
                                            </td>
                                            {transactionType === 'income' && (
                                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    {row.vehicle_model}
                                                </td>
                                            )}
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {row.type_name}
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {row.transaction_type === 2 ? lang.internal_transfer : (row.transaction_type === 1 ? lang.expense : lang.income)}
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {Intl.NumberFormat('id-Id', { style: 'currency', currency: row.currency }).format(row.dpp)}
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {Intl.NumberFormat('id-Id', { style: 'currency', currency: row.currency }).format(row.ppn)}
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {Intl.NumberFormat('id-Id', { style: 'currency', currency: row.currency }).format(row.grand_total)}
                                            </td>
                                            <td className="px-6 py-4 text-xs whitespace-nowrap">
                                                <span className={`${configuration('status').find((data) => data.code === row.status)?.class ?? configuration('status').find((data) => data.code === 'default')?.class} w-full justify-center inline-flex px-2 text-xs font-semibold leading-5 rounded-full`}>
                                                    {configuration('status').find((data) => data.code === row.status)?.label ?? configuration('status').find((data) => data.code === 'default')?.label}
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination links={bills?.links} from={bills?.from} to={bills?.to} total={bills?.total} />
                    </div>
                </Transition>
            </div>
        </AppLayout>
    )
}

export default Bill