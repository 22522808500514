import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { motion } from "framer-motion"

import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import ErrorMessage from "components/forms/error-message"
import axios from "axios"
import toast from "react-hot-toast"
import { useSWRConfig } from "swr"
import nProgress from "nprogress"
import { lang } from "config"
import Input from "components/forms/input"
import InputDate from "components/forms/input-date"
import useAccounts from "repositories/account"
import useBranches from "repositories/branch"
import OrderList from "./verify-payment/order-list"
import moment from "moment"

const VerifyPayment = ({ title }) => {
    const { cache } = useSWRConfig()

    const location = useLocation()

    const [bill, setBill] = useState(null)
    const [branch, setBranch] = useState(null)
    const [customer, setCustomer] = useState(null)
    const [vehicle, setVehicle] = useState(null)
    const [date, setDate] = useState(null)
    const [method, setMethod] = useState(null)

    const [isOpenOrderListModal, setIsOpenOrderListModal] = useState(false)

    // Selections
    const { data: branchSelection, isLoading: isLoadingBranch } = useBranches({
        paginate: false
    })

    const [errors, setErrors] = useState({})

    // Data
    const [orders, setOrders] = useState([])

    const handleSubmit = (e) => {
        e.preventDefault()
        nProgress.start()

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/payment`, {
            bill_number: bill,
            branch_id: branch,
            customer_number: customer,
            vehicle_number: vehicle,
            order_date: date
        }).then((response) => {
            setOrders(response.data.data)
            setIsOpenOrderListModal(true)
            nProgress.done()
            // toast.success(response.data.message)
        }).catch((error) => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            nProgress.done()
            toast.error(error.response.data.message)
            console.log(error.response)
        })
    }

    return (
        <AppLayout title={title}>
            <div className="p-4 lg:p-8 space-y-16">
                <div>
                    <button disabled className="pointer-events-none">
                        <Link to={location.pathname} className="cursor-default">
                            <motion.h1 layout transition={{ duration: .5, type: "tween" }} layoutId={title} className="text-3xl font-medium">
                                {title}
                            </motion.h1>
                        </Link>
                    </button>
                </div>
                <Transition type="fade">
                    <div className="space-y-16">
                        <form onSubmit={handleSubmit}>
                            <div className="mt-10 lg:mt-0">
                                <div className="lg:grid lg:grid-cols-3 lg:gap-6">
                                    <div className="lg:col-span-1">
                                        <div>
                                            <h3 className="text-lg font-medium leading-6 text-gray-900">{`${lang.search} ${lang.data}`}</h3>
                                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                                        </div>
                                    </div>
                                    <div className="mt-5 lg:col-span-2 lg:mt-0">
                                        <div className="overflow-hidden border rounded-xl">
                                            <div className="px-4 py-5 bg-white lg:p-6">
                                                <div className="grid grid-cols-6 gap-4">
                                                    <div className="col-span-6 sm:col-span-3">
                                                        <label for="bill_number" className="block text-xs text-neutral-700">{lang.bill_number}</label>
                                                        <Input placeholder="Ex: ORD_202301011111" onChange={(e) => setBill(e.target.value)} value={null} error={errors.bill_number} id="bill_number" />
                                                        <ErrorMessage error={errors.bill_number} />
                                                    </div>

                                                    <div className="col-span-6 sm:col-span-3">
                                                        <label for="branch" className="block text-xs text-neutral-700">{lang.branch}</label>
                                                        <select onChange={(e) => setBranch(e.target.value)} value={branch} name="branch" id="branch" className={`${errors.branch_id ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                                            {isLoadingBranch && (
                                                                <option value={null} disabled selected={branch === null}>{lang.loading}</option>
                                                            )}
                                                            {!isLoadingBranch && (
                                                                <option value={null} disabled selected={branch === null}>-- {lang.choose} {lang.branch} --</option>
                                                            )}
                                                            {!isLoadingBranch && branchSelection?.map(row => (
                                                                <option value={row.id}>{row.name}</option>
                                                            ))}
                                                        </select>
                                                        <ErrorMessage error={errors.branch_id} />
                                                    </div>

                                                    <div className="col-span-6 sm:col-span-3">
                                                        <label for="customer" className="block text-xs text-neutral-700">{lang.customer_number}</label>
                                                        <Input placeholder="Ex: 6285173075151" onChange={(e) => setCustomer(e.target.value)} value={customer} error={errors.customer_number} id="customer" />
                                                        <ErrorMessage error={errors.customer_number} />
                                                    </div>

                                                    <div className="col-span-6 sm:col-span-3">
                                                        <label for="vehicle_number" className="block text-xs text-neutral-700">{lang.vehicle_number}</label>
                                                        <Input placeholder="Ex: F 1412 KID" onChange={(e) => setVehicle(e.target.value)} value={vehicle} error={errors.vehicle_number} id="vehicle_number" />
                                                        <ErrorMessage error={errors.vehicle_number} />
                                                    </div>

                                                    <div className="col-span-6 sm:col-span-3">
                                                        <label for="date" className="block text-xs text-neutral-700">{lang.date}</label>
                                                        <InputDate id="date" onChange={(value) => setDate(moment(value).format('Y-MM-DD'))} value={Date.parse(date)} selected={Date.parse(date)} error={errors.date} />
                                                        <ErrorMessage error={errors.order_date} />
                                                    </div>

                                                    {/* <div className="col-span-6 lg:col-span-3">
                                                        <label for="method" className="block text-xs text-neutral-700">{lang.payment_method}</label>
                                                        <select onChange={(e) => { }} value={null} name="method" id="method" className={`${errors.method ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                                            {isLoadingMethod && (
                                                                <option value={null} disabled selected={method === null}>{lang.loading}</option>
                                                            )}
                                                            {!isLoadingMethod && (
                                                                <option value={null} disabled selected={method === null}>-- {lang.choose} {lang.payment_method} --</option>
                                                            )}
                                                            {!isLoadingMethod && methodSelection?.map(row => (
                                                                <option value={row.coa_number}>{row.name}</option>
                                                            ))}
                                                        </select>
                                                        <ErrorMessage error={errors['transaction_payment_account.coa_number']} />
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-8 text-xs text-right">
                                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                                    <span>{lang.search}</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </Transition>
            </div>
            <OrderList isOpen={isOpenOrderListModal} setIsOpen={(value) => setIsOpenOrderListModal(value)} data={orders ?? [{
                order_id: "ORD_20221223153411",
                customer_number: "089525693526",
                order_date: "2022-12-23",
                status_order: 8,
                branch_id: 1,
                vehicle_number: "Gfdsd",
                payment_type: "credit_card"
            }]} />
        </AppLayout>
    )
}

export default VerifyPayment