import { useEffect, useRef, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { motion } from "framer-motion"

import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import moment from "moment"
import { UsersIcon } from "components/icons"
import { lang } from "config"
import InputDate from "components/forms/input-date"
import ErrorMessage from "components/forms/error-message"
import InputAmount from "components/forms/input-amount"
import Label from "components/forms/label"
import axios from "axios"
import nProgress from "nprogress"
import { toast } from "react-hot-toast"
import NoRedirectPagination from "components/no-redirect-pagination"
import useBills from "repositories/bill"
import { useAuth } from "contexts/auth-context"
import useTaxes from "repositories/tax"
import useSWR from "swr"
import _ from "lodash"

const usePrevious = (value) => {
    const ref = useRef()

    useEffect(() => {
        ref.current = value
    }, [value])

    return ref.current
}

const Generate = ({ title }) => {
    const { user } = useAuth()

    const location = useLocation()
    const navigate = useNavigate()

    const [prevRouteState, setPrevRouteState] = useState({})

    const [from, setFrom] = useState()
    const [to, setTo] = useState()
    const [taxType, setTaxType] = useState({})

    const [transactionType, setTransactionType] = useState()

    const [prepopulated, setPrepopulated] = useState([])
    const [prepopulatedAmount, setPrepopulatedAmount] = useState(0)

    const [taxIn, setTaxIn] = useState(0)
    const [taxOut, setTaxOut] = useState(0)
    const [total, setTotal] = useState(0)

    const [search, setSearch] = useState()

    const [page, setPage] = useState(1)

    const [errors, setErrors] = useState({})

    const [paginatedReferences, setPaginatedReferences] = useState()
    const [references, setReferences] = useState()

    const { data: taxes, mutate: mutateTaxes, isLoading: isLoadingTaxes } = useTaxes({})

    const prevSearch = usePrevious(search)
    const prevPage = usePrevious(page)

    useEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }
        getPrevRouteState()
    }, [])

    useEffect(() => {
        if (from && to && !_.isEmpty(taxType)) {
            nProgress.start()

            setPaginatedReferences({ data: [] })

            // Get references (paginated)
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/tax-payment/create`, {
                params: {
                    search,
                    from,
                    to,
                    page,
                    tax: taxType.type,
                    tax_payment: 'without',
                    status: 'settled'
                }
            }).then((response) => {
                setPaginatedReferences(response.data.references)
                nProgress.done()
            }).catch((error) => {
                toast.error(error.response.data.message)
            })

            if (prevPage === page) {
                setPage(1)
            }

            if (prevSearch === search) {
                setReferences([])

                // Get references (not paginated)
                axios.get(`${process.env.REACT_APP_BACKEND_URL}/tax-payment/create`, {
                    params: {
                        paginate: false,
                        from,
                        to,
                        tax: taxType.type,
                        tax_payment: 'without',
                        status: 'settled'
                    }
                }).then((response) => {
                    setReferences(response.data.references)

                    if (response.data.references?.length !== 0) {
                        var taxIn = 0
                        var taxOut = 0
                        var total = 0

                        response.data.references?.map((row) => {
                            if (row.data_type === 'petty_cash') {
                                if (taxType.type === 'ppn') {
                                    if (row.ppn !== null && row.ppn !== 0 && row.ppn) {
                                        if (row.ppn_type === 1) {
                                            taxOut += parseFloat(row.ppn)
                                        } else {
                                            taxIn += parseFloat(row.ppn)
                                        }
                                    }
                                } else {
                                    if (row.pph !== null && row.pph !== 0 && row.pph) {
                                        if (row.pph_type === 1) {
                                            taxOut += parseFloat(row.pph)
                                        } else {
                                            taxIn += parseFloat(row.pph)
                                        }
                                    }
                                }
                            } else {
                                if (taxType.type === 'ppn') {
                                    if (row.ppn !== null && row.ppn !== 0 && row.ppn) {
                                        if (row.transaction_type === 1) {
                                            taxIn += parseFloat(row.ppn)
                                        } else {
                                            taxOut += parseFloat(row.ppn)
                                        }
                                    }
                                } else {
                                    if (row.pph !== null && row.pph !== 0 && row.pph) {
                                        if (row.transaction_type === 1) {
                                            taxIn += parseFloat(row.pph)
                                        } else {
                                            taxOut += parseFloat(row.pph)
                                        }
                                    }
                                }
                            }
                        })

                        total = taxOut - taxIn

                        if (response.data.prepopulated?.length !== 0) {
                            var prepopulatedAmount = 0

                            response.data.prepopulated?.map((row) => {
                                prepopulatedAmount += parseFloat(row.amount)
                            })

                            setPrepopulated(response.data.prepopulated)
                            setPrepopulatedAmount(prepopulatedAmount)

                            total = taxOut - taxIn - prepopulatedAmount
                        } else {
                            setPrepopulated([])
                            setPrepopulatedAmount(0)
                        }

                        if (total < 0) {
                            setTransactionType(0)
                        } else {
                            setTransactionType(1)
                        }

                        setTaxIn(taxIn)
                        setTaxOut(taxOut)
                        setTotal(Math.abs(total))
                    } else {
                        setTaxIn(0)
                        setTaxOut(0)
                        setTotal(0)
                        setPrepopulated([])
                        setPrepopulatedAmount(0)
                    }

                    nProgress.done()
                })
            }
        }
    }, [search, page, from, to, taxType])

    const handleSubmit = (e) => {
        e.preventDefault()

        nProgress.start()

        var details = []

        if (from && to && !_.isEmpty(taxType)) {
            references?.map((row) => {
                if (row.data_type === 'bill') {
                    details.push({
                        bill_number: row.bill_number
                    })
                } else if (row.data_type === 'invoice') {
                    details.push({
                        invoice_number: row.invoice_number
                    })
                } else if (row.data_type === 'petty_cash') {
                    details.push({
                        petty_cash_detail_id: row.id
                    })
                }
            })
        }

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/tax-payment/generate`, {
            from,
            to,

            tax_type: taxType.code,

            transaction_type: transactionType,

            in: taxIn,
            out: taxOut,
            total,

            references: details,

            prepopulated,

            created_by: user?.name
        }).then((response) => {
            navigate({ pathname: "/tax-payment" })
            toast.success(response.data.message)
        }).catch((error) => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            nProgress.done()
            toast.error(error.response.data.message)
            console.log(error.response)
        })
    }

    return (
        <AppLayout title={title}>
            <div className="p-4 space-y-16 lg:p-8">
                <div>
                    {prevRouteState?.back && (
                        <button>
                            <Link to={location.state?.from} state={{ back: null, from: location.pathname, transition: 'slide' }} className="transition hover:opacity-50">
                                <motion.h3 layout transition={{ duration: .5, type: "tween" }} layoutId={prevRouteState?.back} className="text-sm">{prevRouteState?.back}</motion.h3>
                            </Link>
                        </button>
                    )}
                    <Transition type="slide" reversed>
                        <h1 className="text-3xl font-medium">
                            {title}
                        </h1>
                    </Transition>
                </div>
                <Transition type="slide" reversed>
                    <div className="space-y-6">
                        {/* Filter */}
                        <div className="space-y-8">
                            <div className="lg:grid lg:grid-cols-6 lg:gap-6">
                                <div className="mt-5 lg:col-span-4 lg:mt-0">
                                    <div className="overflow-hidden border rounded-xl">
                                        <div className="px-4 py-5 bg-white lg:p-6">
                                            <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                                                <div>
                                                    <Label htmlFor="from" value={lang.from} />
                                                    <InputDate error={errors.from} id="from" maxDate={Date.parse(to)} onChange={(value) => setFrom(moment(value).format('Y-M-DD'))} value={Date.parse(from)} selected={Date.parse(from)} />
                                                    <ErrorMessage error={errors.from} />
                                                </div>
                                                <div>
                                                    <Label htmlFor="to" value={lang.to} />
                                                    <InputDate error={errors.to} id="to" minDate={Date.parse(from)} onChange={(value) => setTo(moment(value).format('Y-M-DD'))} value={Date.parse(to)} selected={Date.parse(to)} />
                                                    <ErrorMessage error={errors.to} />
                                                </div>
                                                <div>
                                                    <Label htmlFor="tax_type" value={lang.tax_type} />
                                                    <select onChange={(e) => setTaxType(JSON.parse(e.target.value))} value={JSON.stringify(taxType)} name="tax_type" id="tax_type" className={`${errors.tax_type ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                                        {isLoadingTaxes && (
                                                            <option value={null} disabled selected>{lang.loading}</option>
                                                        )}
                                                        {!isLoadingTaxes && (
                                                            <option value={JSON.stringify({})} disabled selected>{`-- ${lang.choose} ${lang.tax_type} --`}</option>
                                                        )}
                                                        {!isLoadingTaxes && taxes.map(row => (
                                                            <option value={JSON.stringify(row)}>{row.label}</option>
                                                        ))}
                                                    </select>
                                                    <ErrorMessage error={errors.tax_type} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex items-center text-xs">
                                <div className="flex items-center space-x-2">
                                    <div className="relative">
                                        <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <circle cx={10} cy={10} r={7}></circle>
                                                <line x1={21} y1={21} x2={15} y2={15}></line>
                                            </svg>
                                        </div>
                                        <input onChange={(e) => setSearch(e.target.value)} value={search} type="text" placeholder={`${lang.search} ${lang.reference_number}`} autoComplete="off" className="w-64 py-3 pl-8 pr-4 text-xs transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <form onSubmit={handleSubmit}>
                            <div className="mt-10 lg:mt-0">
                                <div className="lg:grid lg:grid-cols-6 lg:gap-6">
                                    <div className="col-span-4 space-y-4">
                                        <div className={`${errors.references ? 'border-red-200' : 'border-neutral-200'} overflow-x-auto border rounded-xl`}>
                                            <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                                <thead className="bg-neutral-50 rounded-t-3xl">
                                                    <tr>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.reference_number}</th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.reference_type}</th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_type}</th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_date}</th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.tax_amount}</th>
                                                        {/* <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th> */}
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-neutral-200">
                                                    {/* When there are no result */}
                                                    {(!from || !to || _.isEmpty(taxType) || paginatedReferences?.data.length === 0) && search && (
                                                        <tr className="text-center">
                                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                {lang.no_result}
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {/* When there are no list available */}
                                                    {(!from || !to || _.isEmpty(taxType) || paginatedReferences?.data.length === 0) && !search && (
                                                        <tr className="text-center">
                                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                {lang.no_data}
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {(from && to && !_.isEmpty(taxType)) && paginatedReferences?.data.length > 0 && paginatedReferences?.data.map((row) => (
                                                        <>
                                                            {
                                                                row.data_type === 'bill' && (
                                                                    <tr key={row.bill_number}>
                                                                        <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                                            {row.bill_number}
                                                                        </td>
                                                                        <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap capitalize">
                                                                            {row.data_type}
                                                                        </td>
                                                                        <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                            {row.transaction_type === 0 ? lang.tax_out : lang.tax_in}
                                                                        </td>
                                                                        <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                            {moment(row.bill_date).format('MMMM D, YYYY')}
                                                                        </td>
                                                                        <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                            <span>{row.currency && Intl.NumberFormat('id-Id', { style: 'currency', currency: row.currency }).format(row.ppn)}</span>
                                                                            <span className="px-2 py-1 ml-2 rounded-full bg-neutral-100">{`${row.ppn_percentage}%`}</span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            {
                                                                row.data_type === 'invoice' && (
                                                                    <tr key={row.invoice_number}>
                                                                        <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                                            {row.invoice_number}
                                                                        </td>
                                                                        <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap capitalize">
                                                                            {row.data_type}
                                                                        </td>
                                                                        <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                            {row.transaction_type === 0 ? lang.tax_out : lang.tax_in}
                                                                        </td>
                                                                        <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                            {moment(row.invoice_date).format('MMMM D, YYYY')}
                                                                        </td>
                                                                        <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                            <span>{row.currency && Intl.NumberFormat('id-Id', { style: 'currency', currency: row.currency }).format(taxType.type === 'ppn' ? row.ppn : row.pph)}</span>
                                                                            <span className="px-2 py-1 ml-2 rounded-full bg-neutral-100">{`${taxType.type === 'ppn' ? row.ppn_percentage : row.pph_percentage}%`}</span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            {
                                                                row.data_type === 'petty_cash' && (
                                                                    <tr key={row.id}>
                                                                        <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                            <div className="flex flex-col">
                                                                                <span className="font-medium text-neutral-900">{row.transaction_number}</span>
                                                                                <div className="space-x-1">
                                                                                    <span>{`${row.destination_coa.description}`}</span>
                                                                                    <span>–</span>
                                                                                    <span>{`${row.destination}`}</span>
                                                                                    {/* <span className="font-medium underline text-neutral-700 decoration-skip-ink decoration-4 decoration-neutral-500/20 underline-offset-[-2px]">{`${row.petty_cash.destination}`}</span> */}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap capitalize">
                                                                            {row.data_type.replace('_', ' ')}
                                                                        </td>
                                                                        <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                            {taxType.type === 'ppn' ? (row.ppn_type === 0 ? lang.tax_in : lang.tax_out) : (row.pph_type === 0 ? lang.tax_in : lang.tax_out)}
                                                                        </td>
                                                                        <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                            <span>{`${moment(row.transaction_date).format('MMMM D, YYYY')}`}</span>
                                                                            <span className="px-2 py-1 ml-2 rounded-full bg-neutral-100">{`${moment(row.transaction_date).format('HH.mm')}`}</span>
                                                                        </td>
                                                                        <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                            <span>{Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(taxType.type === 'ppn' ? row.ppn : row.pph)}</span>
                                                                            <span className="px-2 py-1 ml-2 rounded-full bg-neutral-100">{`${taxType.type === 'ppn' ? row.ppn_percentage : row.pph_percentage}%`}</span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <ErrorMessage error={errors.references} />
                                        {(from && to && !_.isEmpty(taxType)) && (
                                            <NoRedirectPagination current={paginatedReferences?.current_page} links={paginatedReferences?.links} from={paginatedReferences?.from} to={paginatedReferences?.to} total={paginatedReferences?.total} update={(value) => setPage(value)} />
                                        )}
                                    </div>
                                    <div className="mt-5 lg:col-span-2 lg:mt-0">
                                        <div className="overflow-hidden border rounded-xl">
                                            <div className="px-4 py-5 space-y-6 bg-white lg:p-6">
                                                <fieldset className="space-y-4">
                                                    <legend className="text-base font-medium text-gray-900 contents">{lang.amounts}</legend>
                                                    <div>
                                                        <p className="text-sm text-gray-800">{lang.tax_out}</p>
                                                        <p className="text-xs text-gray-500">{Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(taxOut)}</p>
                                                    </div>
                                                    <div>
                                                        <p className="text-sm text-gray-800">{lang.tax_in}</p>
                                                        <p className="text-xs text-gray-500">{Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(taxIn)}</p>
                                                    </div>
                                                    <div>
                                                        <p className="text-sm text-gray-800">{lang.prepopulated_tax}</p>
                                                        <p className="text-xs text-gray-500">{Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(prepopulatedAmount)}</p>
                                                    </div>
                                                    <hr />
                                                    <div>
                                                        <p className="text-sm text-gray-800">{lang.total}</p>
                                                        <p className="text-xs text-gray-500">{`${Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(total)} ${transactionType === 0 ? '(' + lang.remains + ')' : ''}`}</p>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-8 text-xs text-right">
                                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                                    <span>{lang.generate}</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </Transition>
            </div>
        </AppLayout>
    )
}

export default Generate