import axios from "axios"
import { json } from "react-router-dom"

export const show = async ({ request, params }) => {
    const url = new URL(request.url)
    const page = url.searchParams.get("page")
    const status = url.searchParams.get("status")

    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/e-statement/${params.number}?page=${page ?? 1}&status=${status ?? ""}`).then(res => res.data).catch((error) => {
        throw new json(error, error.response.status)
    })
}

export const showMatched = async ({ params }) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/e-statement/${params.number}/matched`).then(res => res.data).catch((error) => {
        throw new json(error, error.response.status)
    })
}