import { getConfig } from "helpers/config-helper"
import { useState, useContext, createContext } from "react"

// Initial state
const initialState = {
    transition: "fade",
    setTransition: () => { },
    back: null,
    setBack: () => { },
    prevLocation: "",
    setPrevLocation: () => { },
    configuration: (key) => {
        const config = getConfig()

        if (config) {
            return config.find((row) => row.key === key).value
        } else {
            return null
        }
    }
}

// Page context
export const PageContext = createContext(initialState)

// Provider
export const PageProvider = ({ children }) => {
    const [transition, setTransition] = useState("fade")
    const [back, setBack] = useState(null)

    const [prevLocation, setPrevLocation] = useState("")

    const configuration = (key) => {
        const config = getConfig()

        if (config) {
            return config.find((row) => row.key === key).value
        } else {
            return null
        }
    }

    const value = {
        transition,
        setTransition,
        back,
        setBack,
        prevLocation,
        setPrevLocation,
        configuration
    }

    return (
        <PageContext.Provider value={value}>
            {children}
        </PageContext.Provider>
    )
}

export const usePage = () => {
    return useContext(PageContext)
}