import { useState } from 'react'
import { Modal } from 'components'
import Label from 'components/forms/label'
import Input from 'components/forms/input'
import InputDate from 'components/forms/input-date'
import moment from 'moment'
import { lang } from 'config'
import useUsers from 'repositories/user'
import useRoles from 'repositories/role'
import useBranches from 'repositories/branch'
import SelectDescription from 'components/forms/select-description'
import { useEffect } from 'react'

const Content = ({ data, onSubmit, onReset }) => {
    const { data: userSelection, isLoading: isLoadingUser } = useUsers({
        paginate: false,
        application_id: 2
    })
    const { data: roleSelection, isLoading: isLoadingRole } = useRoles({
        paginate: false,
        application_id: 2
    })
    const { data: branchSelection, isLoading: isLoadingBranch } = useBranches({
        paginate: false
    })

    const [user, setUser] = useState(data.user ?? null)
    const [role, setRole] = useState(data.role ?? null)
    const [branch, setBranch] = useState(data.branch ?? null)

    const handleSubmit = (e) => {
        e.preventDefault()

        const data = {
            user,
            role,
            branch
        }

        // Filter before passing data to parent
        onSubmit(Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null && v != '')))
    }

    useEffect(() => {
        setUser(data?.user ?? null)
        setRole(data?.role ?? null)
        setBranch(data?.branch ?? null)
    }, [data])

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
                <div>
                    <label for="branch" className="block text-xs text-neutral-700">{lang.branch}</label>
                    <select onChange={(e) => setBranch(e.target.value)} value={branch} name="branch" id="branch" className={`border-neutral-200 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                        {isLoadingBranch && (
                            <option value={null} disabled selected>{lang.loading}</option>
                        )}
                        {!isLoadingBranch && (
                            <option value={null} disabled selected>{`-- ${lang.choose} ${lang.branch} --`}</option>
                        )}
                        {!isLoadingBranch && branchSelection.map(row => (
                            <option value={row.id}>{row.name}</option>
                        ))}
                    </select>
                </div>

                <div>
                    <label for="user" className="block text-xs text-neutral-700">{lang.user}</label>
                    {/* <select onChange={(e) => setUser(e.target.value)} value={user} name="user" id="user" className={`border-neutral-200 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                        {isLoadingUser && (
                            <option value={null} disabled selected>{lang.loading}</option>
                        )}
                        {!isLoadingUser && (
                            <option value={null} disabled selected>{`-- ${lang.choose} ${lang.user} --`}</option>
                        )}
                        {!isLoadingUser && userSelection.map(row => (
                            <option value={row.id}>{row.name}</option>
                        ))}
                    </select> */}
                    <SelectDescription isLoading={isLoadingUser} onChange={(value) => setUser(value)} title="name" description="phone" value={user} keyValue="id" selection={userSelection} placeholder={`-- ${lang.choose} ${lang.user} --`} />
                </div>

                <div>
                    <label for="role" className="block text-xs text-neutral-700">{lang.role}</label>
                    <select onChange={(e) => setRole(e.target.value)} value={role} name="role" id="role" className={`border-neutral-200 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                        {isLoadingRole && (
                            <option value={null} disabled selected>{lang.loading}</option>
                        )}
                        {!isLoadingRole && (
                            <option value={null} disabled selected>{`-- ${lang.choose} ${lang.role} --`}</option>
                        )}
                        {!isLoadingRole && roleSelection.map(row => (
                            <option value={row.id}>{row.name}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="flex items-center space-x-2 text-xs">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.filter}</span>
                </button>
                <button type="button" onClick={onReset} className="items-center px-6 py-3 transition border border-neutral-200 bg-neutral-50 rounded-xl active:hover:scale-90">
                    <span>{lang.clear}</span>
                </button>
            </div>
        </form>
    )
}

const Filter = ({ data, onSubmit, onReset }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSubmit = (value) => {
        closeModal()
        onSubmit(value)
    }

    const handleReset = () => {
        closeModal()
        onReset()
    }

    return (
        <>
            <button onClick={openModal} className="inline-flex items-center px-4 py-3 space-x-2 transition border border-neutral-200 bg-neutral-50 rounded-xl active:hover:scale-90">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5"></path>
                </svg>
                <span>{lang.filter}</span>
                {Object.keys(data).length > 0 && (
                    <span className="inline-flex w-4 h-4 font-semibold rounded-full text-[0.5rem] justify-center text-neutral-200 bg-neutral-800">
                        {`${Object.keys(data).length}`}
                    </span>
                )}
            </button>
            <Modal size="4xl" isOpen={isOpen} onClose={closeModal} title={`${lang.filter} ${lang.data}`} content={<Content onSubmit={handleSubmit} onReset={handleReset} data={data} />} />
        </>
    )
}

export default Filter