import { useState } from 'react'
import { Modal } from 'components'
import moment from 'moment/moment'
import SupplierIcon from 'components/icons/supplier'
import { UsersIcon } from 'components/icons'
import { lang } from "config"
import { usePage } from 'contexts/page-context'

const Content = ({ data, closeModal }) => {
    const { configuration } = usePage()

    return (
        <div className="mt-8 space-y-8">
            <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                    <thead className="bg-neutral-50 rounded-t-3xl">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.invoice}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.branch}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.customer_or_supplier}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.customer_or_supplier_code}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.type}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_type}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.grand_total}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.status}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.invoice_date}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.due_date}</th>
                            <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-neutral-200">
                        {data.map(row => (
                            <tr key={row.invoice_number}>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.invoice_number}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.invoice.branch?.name}
                                </td>
                                <td class="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    <div className="flex items-center space-x-2">
                                        <span>
                                            {(row.invoice.type?.code === 'po' || row.invoice.type?.code === 'rt-po' || row.invoice.type?.code === 'rt-adv') ? (
                                                <SupplierIcon className="w-4 h-4" stroke={1.5} />
                                            ) : (
                                                <UsersIcon className="w-4 h-4" stroke={1.5} />
                                            )}
                                        </span>
                                        <span>
                                            {(row.invoice.type?.code === 'po' || row.invoice.type?.code === 'rt-po' || row.invoice.type?.code === 'rt-adv') ? row.invoice.supplier?.name : row.invoice.customer?.name}
                                        </span>
                                    </div>
                                </td>
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {row.invoice.payor_or_payee_code}
                                </td>
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {row.invoice.type?.name}
                                </td>
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {row.invoice.transaction_type === 1 ? lang.expense : lang.income}
                                </td>
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {Intl.NumberFormat('id-Id', { style: 'currency', currency: row.invoice.currency }).format(row.invoice.grand_total)}
                                </td>
                                <td className="px-6 py-4 text-xs capitalize whitespace-nowrap">
                                    <span className={`${configuration('status').find((data) => data.code === row.invoice.status)?.class ?? configuration('status').find((data) => data.code === 'default')?.class} inline-flex px-2 text-xs font-semibold leading-5 rounded-full`}>
                                        {configuration('status').find((data) => data.code === row.invoice.status)?.label ?? configuration('status').find((data) => data.code === 'default')?.label}
                                    </span>
                                </td>
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {moment(row.invoice.invoice_date).format('MMMM D, YYYY')}
                                </td>
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {moment(row.invoice.due_date).format('MMMM D, YYYY')}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="text-xs">
                <button onClick={closeModal} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.close}</span>
                </button>
            </div>
        </div>
    )
}

const InvoiceList = ({ data }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    return (
        <>
            <button onClick={openModal} className="inline-flex items-center space-x-2 text-xs underline">
                <div>
                    {lang.see_invoices}
                </div>
            </button>
            <Modal isOpen={isOpen} size="6xl" onClose={closeModal} title={lang.invoice_list} content={<Content data={data} closeModal={closeModal} />} />
        </>
    )
}

export default InvoiceList