import axios from "axios"
import CreatableInput from "components/forms/creatable-input"
import ErrorMessage from "components/forms/error-message"
import Input from "components/forms/input"
import Label from "components/forms/label"
import SelectMultiple from "components/forms/select-multiple"
import { phoneRegexValidation } from "helpers/phone-helper"
import { useState } from "react"
import { useLayoutEffect } from "react"

const Form = ({ data, errors, onSubmit }) => {
    const [name, setName] = useState(data?.name ?? null)

    const [npwp, setNpwp] = useState(data?.npwp ?? null)
    const [note, setNote] = useState(data?.note ?? null)

    const [email, setEmail] = useState(data?.email ?? null)
    const [fax, setFax] = useState(data?.fax ?? null)
    const [phone, setPhone] = useState(data?.phone ?? [])
    const [inputPhone, setInputPhone] = useState('')
    const [mobile, setMobile] = useState(data?.mobile ?? [])
    const [contactPerson, setContactPerson] = useState(data?.contact_person ?? [])
    const [contactNumber, setContactNumber] = useState(data?.contact_number ?? [])
    const [address, setAddress] = useState(data?.address ?? [])
    const [billAddress, setBillAddress] = useState(data?.bill_address ?? [])

    useLayoutEffect(() => {
        const fetchData = async () => {
        }

        fetchData()
    }, [])

    const handleInputPhone = (value) => {
        value = value.replace(/[^0-9]/g, '')

        setInputPhone(value)
    }

    const handleInputPhoneKeyDown = (e) => {
        if (!inputPhone) return

        if (e.nativeEvent.code === "KeyE" || e.nativeEvent.code === "Minus") {
            e.preventDefault()
        }

        switch (e.key) {
            case 'Enter':
            case 'Tab':
                setPhone((prev) => [...prev, inputPhone])
                setInputPhone('')
                e.preventDefault()
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        onSubmit({
            name,
            npwp,
            note,

            email,
            fax,
            phone,
            mobile,
            contact_person: contactPerson,
            contact_number: contactNumber,
            address,
            bill_address: billAddress
        })
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Main Forms</h3>
                            <p className="mt-1 text-sm text-gray-600">Please fill the provided form input.</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-3xl">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-6 gap-4">
                                    {/* Name */}
                                    <div className="col-span-6 sm:col-span-3">
                                        <Label htmlFor="name" value="Name" />
                                        <Input onChange={e => setName(e.target.value)} value={name} error={errors?.name} id="name" />
                                        <ErrorMessage error={errors?.name} />
                                    </div>

                                    {/* NPWP */}
                                    <div className="col-span-6 sm:col-span-3">
                                        <Label htmlFor="npwp" value="NPWP" />
                                        <Input onChange={e => setNpwp(e.target.value)} value={npwp} error={errors?.npwp} id="npwp" />
                                        <ErrorMessage error={errors?.npwp} />
                                    </div>

                                    {/* Note */}
                                    <div className="col-span-6">
                                        <Label htmlFor="note" value="Note" />
                                        <textarea onChange={e => setNote(e.target.value)} value={note} name="note" id="note" rows="5" className={`${errors?.note ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}></textarea>
                                        <ErrorMessage error={errors?.note} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200" />
                </div>
            </div>

            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Contact Forms</h3>
                            <p className="mt-1 text-sm text-gray-600">Contact information such as email, phone, fax, etc.</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-3xl">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-6 gap-4">
                                    {/* Email */}
                                    <div className="col-span-6 sm:col-span-3">
                                        <Label htmlFor="email" value="Email" />
                                        <Input onChange={e => setEmail(e.target.value)} type="email" value={email} error={errors?.email} id="email" />
                                        <ErrorMessage error={errors?.email} />
                                    </div>

                                    {/* Fax */}
                                    <div className="col-span-6 sm:col-span-3">
                                        <Label htmlFor="fax" value="Fax" />
                                        <Input onChange={e => setFax(e.target.value)} value={fax} error={errors?.fax} id="fax" />
                                        <ErrorMessage error={errors?.fax} />
                                    </div>

                                    {/* Phone */}
                                    <div className="col-span-6 sm:col-span-3">
                                        <Label htmlFor="phone" value="Phone" />
                                        <CreatableInput inputValue={inputPhone} value={phone.map(row => ({ value: row, label: row }))} error={errors?.phone} onChange={(value) => setPhone(value)} onInputChange={handleInputPhone} onKeyDown={handleInputPhoneKeyDown} />
                                        <ErrorMessage error={errors?.phone} />
                                    </div>

                                    {/* Mobile */}
                                    <div className="col-span-6 sm:col-span-3">
                                        <Label htmlFor="mobile" value="Mobile" />
                                        <Input onChange={e => setMobile(e.target.value)} value={mobile} error={errors?.mobile} id="mobile" type="number" />
                                        <ErrorMessage error={errors?.mobile} />
                                    </div>

                                    {/* Contact Person */}
                                    <div className="col-span-6 sm:col-span-3">
                                        <Label htmlFor="contact_person" value="Contact Person" />
                                        <Input onChange={e => setContactPerson(e.target.value)} value={contactPerson} error={errors?.contact_person} id="contact_person" />
                                        <ErrorMessage error={errors?.contact_person} />
                                    </div>

                                    {/* Contact Number */}
                                    <div className="col-span-6 sm:col-span-3">
                                        <Label htmlFor="contact_number" value="Contact Number" />
                                        <Input onChange={e => setContactNumber(e.target.value)} value={contactNumber} error={errors?.contact_number} id="contact_number" type="number" />
                                        <ErrorMessage error={errors?.contact_number} />
                                    </div>

                                    {/* Address */}
                                    <div className="col-span-6">
                                        <Label htmlFor="address" value="Address" />
                                        <textarea onChange={e => setAddress(e.target.value)} value={address} name="address" id="address" rows="5" className={`${errors?.address ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}></textarea>
                                        <ErrorMessage error={errors?.address} />
                                    </div>

                                    {/* Bill Address */}
                                    <div className="col-span-6">
                                        <Label htmlFor="bill_address" value="Bill Address" />
                                        <textarea onChange={e => setBillAddress(e.target.value)} value={billAddress} name="bill_address" id="bill_address" rows="5" className={`${errors?.bill_address ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}></textarea>
                                        <ErrorMessage error={errors?.bill_address} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>Save</span>
                </button>
            </div>
        </form>
    )
}

export default Form