import { useEffect, useState } from "react"
import { Link, useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom"
import { motion } from "framer-motion"
import {
    BranchIcon,
    TrendingUpIcon,
    ArrowsDoubleNeSwIcon,
    DollarIcon,
    CalendarPlusIcon,
    CalendarIcon,
    ReceiptIcon,
    NotesIcon,
    MailIcon,
    PhoneIcon,
    UserCircleIcon,
    TaxPaymentIcon,
    ArrowBarToDownIcon,
    ArrowBarUpIcon,
    ArrowBackUpIcon
} from "components/icons"

import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import Divider from "components/divider"

import axios from 'axios'
import moment from "moment/moment"
import { ArrowRightIcon } from "components/icons"
import { lang } from "config"

const TaxPaymentDetail = () => {
    const number = useParams().number
    const navigate = useNavigate()
    const location = useLocation()

    const [prevRouteState, setPrevRouteState] = useState({})

    const data = useLoaderData()

    useEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }
        getPrevRouteState()
    }, [])

    return (
        <AppLayout title={number}>
            <div className="p-4 lg:p-8 space-y-16">
                <div>
                    {prevRouteState.back && (
                        <button className="transition hover:opacity-50" onClick={() => navigate(-1)}>
                            <motion.h3 layout transition={{ duration: .5, type: "tween" }} layoutId={prevRouteState.back} className="text-sm">{prevRouteState.back}</motion.h3>
                        </button>
                    )}
                    <Transition reversed>
                        <h1 className="text-3xl font-medium">
                            {number}
                        </h1>
                    </Transition>
                </div>
                <Transition type="slide" reversed>
                    <div className="space-y-6">
                        <div className="flex items-center justify-between p-6 border border-neutral-200 rounded-xl">
                            <div className="flex space-x-2">
                                <div>
                                    <TaxPaymentIcon className="w-4 h-4 text-neutral-500" stroke={1.5} />
                                </div>
                                <div>
                                    <div className="text-xs text-neutral-500">
                                        {lang.tax_type}
                                    </div>
                                    <div className="text-2xl uppercase">
                                        {data.tax_type.replace('-', ' ')}
                                    </div>
                                </div>
                            </div>

                            <div className="flex space-x-2">
                                <div>
                                    <CalendarIcon className="w-4 h-4 text-neutral-500" stroke={1.5} />
                                </div>
                                <div>
                                    <div className="text-xs text-neutral-500">
                                        {lang.transaction_date}
                                    </div>
                                    <div className="text-2xl">
                                        {moment(data.transaction_date).format('MMMM DD, YYYY')}
                                    </div>
                                </div>
                            </div>

                            <div className="flex space-x-2">
                                <div>
                                    <CalendarIcon className="w-4 h-4 text-neutral-500" stroke={1.5} />
                                </div>
                                <div>
                                    <div className="text-xs text-neutral-500">
                                        {lang.from}
                                    </div>
                                    <div className="text-2xl">
                                        {moment(data.from).format('MMMM DD, YYYY')}
                                    </div>
                                </div>
                            </div>

                            <div className="flex space-x-2">
                                <div>
                                    <CalendarIcon className="w-4 h-4 text-neutral-500" stroke={1.5} />
                                </div>
                                <div>
                                    <div className="text-xs text-neutral-500">
                                        {lang.to}
                                    </div>
                                    <div className="text-2xl">
                                        {moment(data.to).format('MMMM DD, YYYY')}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-4 space-y-4">
                                <div className={`${false ? 'border-red-200' : 'border-neutral-200'} overflow-x-auto border rounded-xl`}>
                                    <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                        <thead className="bg-neutral-50 rounded-t-3xl">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.reference_number}</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.reference_type}</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_type}</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_date}</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.tax_amount}</th>
                                                {/* <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th> */}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-neutral-200">
                                            {data.details.map((row) => (
                                                <>
                                                    {
                                                        row.bill && (
                                                            <tr key={row.bill_number}>
                                                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                                    {row.bill_number}
                                                                </td>
                                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    {lang.bill}
                                                                </td>
                                                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    {row.transaction_type === 0 ? lang.tax_out : lang.tax_in}
                                                                </td>
                                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    {moment(row.bill?.bill_date).format('MMMM D, YYYY')}
                                                                </td>
                                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    <span>{row.bill?.currency && Intl.NumberFormat('id-Id', { style: 'currency', currency: row.bill?.currency }).format(data.tax_type === 'ppn' ? row.bill.ppn : row.bill.pph)}</span>
                                                                    <span className="px-2 py-1 ml-2 rounded-full bg-neutral-100">{`${data.tax_type === 'ppn' ? row.bill.ppn_percentage : row.bill.pph_percentage}%`}</span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                    {
                                                        row.invoice && (
                                                            <tr key={row.invoice_number}>
                                                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                                    {row.invoice_number}
                                                                </td>
                                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    {lang.invoice}
                                                                </td>
                                                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    {row.transaction_type === 0 ? lang.tax_out : lang.tax_in}
                                                                </td>
                                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    {moment(row.invoice.invoice_date).format('MMMM D, YYYY')}
                                                                </td>
                                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    <span>{row.invoice.currency && Intl.NumberFormat('id-Id', { style: 'currency', currency: row.invoice.currency }).format(data.tax_type === 'ppn' ? row.invoice.ppn : row.invoice.pph)}</span>
                                                                    <span className="px-2 py-1 ml-2 rounded-full bg-neutral-100">{`${data.tax_type === 'ppn' ? row.invoice.ppn_percentage : row.invoice.pph_percentage}%`}</span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                    {
                                                        row.petty_cash && (
                                                            <tr key={row.transaction_number}>
                                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    <div className="flex flex-col">
                                                                        <span className="font-medium text-neutral-900">{row.petty_cash.transaction_number}</span>
                                                                        <div className="space-x-1">
                                                                            <span>{`${row.petty_cash.destination_coa.description}`}</span>
                                                                            <span>–</span>
                                                                            <span>{`${row.petty_cash.destination}`}</span>
                                                                            {/* <span className="font-medium underline text-neutral-700 decoration-skip-ink decoration-4 decoration-neutral-500/20 underline-offset-[-2px]">{`${row.petty_cash.destination}`}</span> */}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    {lang.petty_cash}
                                                                </td>
                                                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    {data.tax_type === 'ppn' ? (row.petty_cash.ppn_type === 0 ? lang.tax_in : lang.tax_out) : (row.petty_cash.pph_type === 0 ? lang.tax_in : lang.tax_out)}
                                                                </td>
                                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    <span>{`${moment(row.petty_cash.transaction_date).format('MMMM D, YYYY')}`}</span>
                                                                    <span className="px-2 py-1 ml-2 rounded-full bg-neutral-100">{`${moment(row.petty_cash.transaction_date).format('HH.mm')}`}</span>
                                                                </td>
                                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    <span>{row.petty_cash.currency && Intl.NumberFormat('id-Id', { style: 'currency', currency: row.petty_cash.currency }).format(data.tax_type === 'ppn' ? row.petty_cash.ppn : row.petty_cash.pph)}</span>
                                                                    <span className="px-2 py-1 ml-2 rounded-full bg-neutral-100">{`${data.tax_type === 'ppn' ? row.petty_cash.ppn_percentage : row.petty_cash.pph_percentage}%`}</span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="col-span-2 p-6 space-y-6 text-xs border rounded-xl border-neutral-200">
                                {/* Amount */}
                                <Divider content={`${lang.amount}`} className="font-medium" />
                                <div className="flex space-x-2">
                                    <div>
                                        <ArrowBarUpIcon className="w-4 h-4" stroke={1.5} />
                                    </div>
                                    <div className="space-y-2">
                                        <div className="font-medium">{lang.tax_out}</div>
                                        <div>{Intl.NumberFormat('id-Id', { style: 'currency', currency: 'IDR' }).format(data.out)}</div>
                                    </div>
                                </div>
                                <div className="flex space-x-2">
                                    <div>
                                        <ArrowBarToDownIcon className="w-4 h-4" stroke={1.5} />
                                    </div>
                                    <div className="space-y-2">
                                        <div className="font-medium">{lang.tax_in}</div>
                                        <div>{Intl.NumberFormat('id-Id', { style: 'currency', currency: 'IDR' }).format(data.in)}</div>
                                    </div>
                                </div>
                                <div className="flex space-x-2">
                                    <div>
                                        <ArrowBackUpIcon className="w-4 h-4" stroke={1.5} />
                                    </div>
                                    <div className="space-y-2">
                                        <div className="font-medium">{lang.prepopulated_tax}</div>
                                        <div>{Intl.NumberFormat('id-Id', { style: 'currency', currency: 'IDR' }).format(data.prepopulated)}</div>
                                    </div>
                                </div>
                                <hr />
                                <div className="flex space-x-2">
                                    <div>
                                        <DollarIcon className="w-4 h-4" stroke={1.5} />
                                    </div>
                                    <div className="space-y-2">
                                        <div className="font-medium">{lang.total}</div>
                                        <div>{Intl.NumberFormat('id-Id', { style: 'currency', currency: 'IDR' }).format(data.total)}</div>
                                    </div>
                                </div>

                                {/* Timestamp */}
                                <Divider content="Timestamp" className="font-medium" />
                                <div className="flex space-x-2">
                                    <div>
                                        <CalendarPlusIcon className="w-4 h-4" stroke={1.5} />
                                    </div>
                                    <div className="space-y-2">
                                        <div className="font-medium">{lang.created_at}</div>
                                        <div>{moment(data.created_at).format('MMMM DD, YYYY')}</div>
                                    </div>
                                </div>
                                <div className="flex space-x-2">
                                    <div>
                                        <CalendarPlusIcon className="w-4 h-4" stroke={1.5} />
                                    </div>
                                    <div className="space-y-2">
                                        <div className="font-medium">{lang.created_by}</div>
                                        <div>{data.created_by}</div>
                                    </div>
                                </div>
                                {data.updated_by && (
                                    <>
                                        <div className="flex space-x-2">
                                            <div>
                                                <CalendarIcon className="w-4 h-4" stroke={1.5} />
                                            </div>
                                            <div className="space-y-2">
                                                <div className="font-medium">{lang.updated_at}</div>
                                                <div>{moment(data.updated_at).format('MMMM DD, YYYY')}</div>
                                            </div>
                                        </div>
                                        <div className="flex space-x-2">
                                            <div>
                                                <CalendarIcon className="w-4 h-4" stroke={1.5} />
                                            </div>
                                            <div className="space-y-2">
                                                <div className="font-medium">{lang.updated_by}</div>
                                                <div>{data.updated_by}</div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </AppLayout>
    )
}

export default TaxPaymentDetail