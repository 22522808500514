import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { motion } from "framer-motion"

import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import axios from "axios"
import { useAuth } from "contexts/auth-context"
import { useLayoutEffect } from "react"
import Form from "./form"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import { useSWRConfig } from "swr"

const Create = ({ title }) => {
    const { user, isSuper } = useAuth()
    const { cache } = useSWRConfig()

    const location = useLocation()
    const navigate = useNavigate()

    const [prevRouteState, setPrevRouteState] = useState({})

    const [errors, setErrors] = useState({})

    useLayoutEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }

        getPrevRouteState()
    }, [])

    const handleSubmit = (data) => {
        nProgress.start()

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/petty-cash/store`, {
            branch_id: data.branch_id,
            transaction_type: data.transaction_type,
            currency: data.currency,
            rate: data.rate,
            grand_total: data.grand_total,
            source: data.source,
            note: data.note,
            details: data.details,
            // payment_flag: data.payment_flag ? 1 : 0,
            created_by: user?.name,
            no_approve: isSuper() ? 1 : 0
        }).then((response) => {
            cache.clear()
            navigate({ pathname: '/petty-cash' })
            toast.success(response.data.message)
        }).catch((error) => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            nProgress.done()
            toast.error(error.response.data.message, {
                ariaProps: {
                    superscript: error.response.status
                }
            })
            console.log(error.response)
        })
    }

    return (
        <AppLayout title={title}>
            <div className="p-4 space-y-16 lg:p-8">
                <div>
                    {prevRouteState?.back && (
                        <button>
                            <Link onClick={() => navigate(-1)} to={location.state?.from} state={{ back: null, from: location.pathname, transition: 'slide' }} className="transition hover:opacity-50">
                                <motion.h3 layout transition={{ type: "spring", damping: 20 }} layoutId={prevRouteState?.back} className="text-sm">{prevRouteState?.back}</motion.h3>
                            </Link>
                        </button>
                    )}
                    <Transition type="slide" reversed>
                        <h1 className="text-3xl font-medium">
                            {title}
                        </h1>
                    </Transition>
                </div>
                <Transition type="slide" reversed>
                    <Form errors={errors} onSubmit={handleSubmit} />
                </Transition>
            </div>
        </AppLayout>
    )
}

export default Create